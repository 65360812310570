import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import CutomizedEditTemplate from "./CustomizedEditTemplate"; // Import the edit template component
import CutomizedAddTemplate from "./CustomizedAddTemplate"; // Import the add template component
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid";
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer";

const FeaturesSetupBody = ({ onSearch }) => {
  // Get socket and data from Redux store
  const socket = useSelector((state) => state.auth.socket);
  const featuresSetupData = useSelector(
    (state) => state?.projectsSetup?.featuresSetupData
  );
  const dispatch = useDispatch();

  // Local state to keep track of updates to changes
  const [updateChanges, setUpdateChanges] = useState([]);

  // Column configuration for the DataGrid
  const ColumnDirective = [
    {
      field: "featureId",
      headerText: "Feature ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
      allowEditing: false,
    },
    { field: "featureName", headerText: "Name", width: "100" },
    { field: "featureDesc", headerText: "Description", width: "100" },
    { field: "featureType", headerText: "Type", width: "100" },
    { field: "featureUnit", headerText: "Unit", width: "100" },
    { field: "dataType", headerText: "Data Type", width: "100" },
  ];

  // Function to send data to the server via socket connection
  const sendData = (data, action) => {
    // Construct the data object to be sent
    const sendedData = {};
    // (action === "add" && {
    //   action: "add",
    //   desc: data?.menuDesc ? data?.menuDesc : "",
    // }) ||
    // (action === "update" && {
    //   action: "edit",
    //   menuId: data?.menuId,
    //   desc: data?.menuDesc ? data?.menuDesc : "",
    //   menuPages: updatedPages?.map((obj) => {
    //     return {
    //       action: obj?.action,
    //       pageId: obj?.pageId,
    //     };
    //   }),
    // }) ||
    // (action === "delete" && {
    //   action: "delete",
    //   menuId: data?.menuId,
    // });

    // Send the data using socket event
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "menuUpdate", // Specifying the type of action (menuUpdate)
          menuUpdate: sendedData,
        },
      },
      (response) => {
        // Dispatch notification after receiving the response from the server
        dispatch(
          setNotificationSnackBar({
            open: true, // Open the notification snackbar
            message: response?.message?.text, // Show the response message
            type: response?.message?.messageType, // Notification type (success, error, etc.)
          })
        );
        onSearch(); // Trigger the search callback
      }
    );
  };

  // Function to handle changes in the DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      sendData(args?.promise?.[0], "delete"); // Handle delete action
    } else if (args.action === "edit") {
      const updatedData = { ...args.data }; // Clone the data for editing
      delete updatedData.sections; // Exclude read-only field
      sendData(updatedData, "update"); // Handle update action
    } else if (args.action === "add") {
      sendData(args.data, "add"); // Handle add action
    }
  };

  // Conditional dialog template based on whether it's add or edit
  // const dialogTemplate = (props, grid) => {
  //   const a = [props, grid];

  //   if (!props?.isAdd) {
  //     return (
  //       <CutomizedEditTemplate
  //         {...a}
  //         setUpdateChanges={setUpdateChanges}
  //         updateChanges={updateChanges}
  //       />
  //     );
  //   } else {
  //     return <CutomizedAddTemplate {...a} />; // Return add template for new entries
  //   }
  // };

  return (
    <Grid>
      <SingleHierarchy
        columns={ColumnDirective}
        data={JSON.parse(JSON.stringify(featuresSetupData || []))} // Make sure data is properly parsed and passed
        primaryKey={"featureId"}
        selection={"Single"}
        onChanges={onChanges} // Attach onChanges handler
        readOnly={false} // Allow editing
        // template={(props, grid) => dialogTemplate(props, grid)} // Custom template for the dialog
        style={"usermenu"} // Custom styling class
      />
    </Grid>
  );
};

export default FeaturesSetupBody;
