// Importing necessary modules from Redux Toolkit and universal-cookie
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

// Initialize cookies to manage persistent user data in the browser
const cookies = new Cookies();

// Define the initial state of the authentication slice
const authSliceInitialState = {
  // Retrieve userAll from cookies if available, otherwise initialize as an empty object
  userAll: cookies.get("_userAll")
    ? cookies.get("_userAll")
    : {
        username: "",
        token: null,
        userid: null,
      },

  // Retrieve userData from cookies if available, otherwise initialize as an empty array
  userData: cookies.get("_userData") ? cookies.get("_userData") : [],
  userMenu: cookies.get("_userMenu") ? cookies.get("_userMenu") : [],
  // Check if the user is logged in, retrieve from cookies if available, otherwise set to false
  user: cookies.get("_user") ? cookies.get("_user") : false,

  // Retrieve selectedMenu from cookies if available, otherwise initialize as an empty string
  selectedMenu: cookies.get("_selectedMenu")
    ? cookies.get("_selectedMenu")
    : {},

  socket: cookies.get("_socket") ? cookies.get("_socket") : null,

  // Initialize the notification snackbar state (for alerts and notifications)
  notificationSnackBar: {
    open: false, // Snackbar is initially closed
    severity: "", // Severity level (e.g., success, error) will be set later
    message: "", // Message to display in the snackbar
  },
};

// Create a slice for authentication using Redux Toolkit's createSlice function
const authSlice = createSlice({
  name: "auth", // Name of the slice
  initialState: authSliceInitialState, // Initial state defined above

  // Define the reducers (functions to update the state)
  reducers: {
    // Set user credentials in the state and cookies
    setCredentials: (state, action) => {
      const { userAll, userData, userMenu, user } = action.payload; // Destructure the payload

      // Update the state with the new user information
      state.userAll = userAll;
      state.userData = userData;
      state.userMenu = userMenu;
      state.user = user;
    },

    // Log out the user by clearing the state and removing cookies
    logOut: (state, action) => {
      if (action.payload === false) {
        // Clear the user-related state
        state.user = action.payload;
        state.userAll = {
          username: "",
          token: null,
          userid: null,
        };
        state.userData = [];
        state.selectedMenu = {};
        state.socket = null;

        // Disconnect from the socket if active
        if (state.socket) {
          state.socket.disconnect();
        }

        // Remove all cookies related to the user's session
        cookies.remove("_user");
        cookies.remove("_userAll");
        cookies.remove("_userData");
        cookies.remove("_selectedMenu");
        cookies.remove("_socket");
        cookies.set("_location", "/", {
          path: "/",
          expires: new Date(Date.now() + 2592000),
        });
      }
    },

    // Set the selected menu in the state (used for sidebar or navigation)
    setSelectedMenu: (state, action) => {
      // Update the state with the new selected menu
      state.selectedMenu = action.payload;
    },

    setSocket: (state, action) => {
      state.socket = action.payload;
    },
    setNotificationSnackBar: (state, action) => {
      state.notificationSnackBar = action.payload;
    },
  },
});

// Export the actions to be used in other parts of the application
export const {
  setCredentials,
  logOut,
  setSelectedMenu,
  setSocket,
  setNotificationSnackBar,
  // setSelectedMenu,
} = authSlice.actions;

// Export the reducer to be used in the store configuration
export default authSlice.reducer;
