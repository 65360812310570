// DialogCompanyTemplate.js
import React, { useState } from "react"; // Import React and useState for state management
import { useSelector } from "react-redux"; // Import useSelector hook to access Redux store state

// DialogCompanyTemplate component to display and handle company ID input
const DialogCompanyTemplate = ({
  companyIdRowSelected, // Selected company row with company ID
  openCompanyIDPopUp, // Function to open the company ID pop-up
}) => {
  // Set initial state for companyID using companyIdRowSelected or defaulting to empty string
  const [companyID, setCompanyID] = useState(
    companyIdRowSelected.companyId || ""
  );

  // Access companyIDSelected from Redux store to use as the selected company ID
  const companyIDSelected = useSelector(
    (state) => state?.addressBook?.companyIDSelected // Accessing companyIDSelected from Redux store
  );

  return (
    <div>
      <div className="e-field">
        {/* Label for the input field */}
        <label>Company ID</label>
        <div style={{ position: "relative" }}>
          {/* Input field for the company ID */}
          <input
            type="text"
            name="companyId"
            // Use the companyID from Redux if available, otherwise use the local state
            value={companyIDSelected ? companyIDSelected : companyID}
            style={{
              paddingRight: "30px", // Add space for the icon inside the input
              width: "100%", // Full width of the input field
              boxSizing: "border-box", // Ensure padding is included in width
            }}
            className="e-input" // Apply custom input class
            disabled // Disable the input so the user can't edit it
          />
          {/* Search icon */}
          <i
            className="fa fa-search" // FontAwesome search icon
            aria-hidden="true" // Accessibility feature for screen readers
            style={{
              position: "absolute", // Positioning the icon inside the input
              top: "50%", // Vertically center the icon
              right: "10px", // Place the icon on the right side
              transform: "translateY(-50%)", // Center the icon by adjusting its vertical position
              color: "#666", // Set icon color to grey
              cursor: "pointer", // Change cursor to pointer to indicate clickable icon
            }}
            onClick={openCompanyIDPopUp} // Trigger the function to open the company ID pop-up when clicked
          ></i>
        </div>
      </div>
    </div>
  );
};

export default DialogCompanyTemplate;
