import { Box } from "@mui/material"; // Import MUI Box component for layout and styling
import React from "react"; // Import React

/**
 * FilterComponent
 * A reusable container component styled as a filter box, using Material-UI's Box component.
 * It provides a consistent UI for filter-related content and accepts children elements to be rendered inside.
 *
 * Props:
 * - children: React nodes (e.g., filter controls, inputs, or other elements) to be rendered inside the component.
 */
const FilterComponent = ({ children }) => {
  return (
    <Box
      // Styling for the container
      sx={{
        padding: "1%", // Padding around the content
        paddingX: "4%", // Horizontal padding
        backgroundColor: "rgb(239 238 238)", // Light gray background color
      }}
      // Box shadow for a slight elevation effect
      boxShadow="0 2px 2px 0 rgba(0, 0, 0, 0.2),0 2px 10px 0 rgba(0, 0, 0, 0.19)"
    >
      {children} {/* Render any child elements passed to the component */}
    </Box>
  );
};

export default FilterComponent; // Export the component for reuse
