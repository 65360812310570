import { Box, CircularProgress, Grid } from "@mui/material"; // Importing Material-UI components for layout and loading indicator
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterComponent from "../../../Common/Filter";
import UsersFilterReturn from "./FilterReturn";
import {
  setUsersData,
  setUsersMenusData,
} from "../../../../Services/Redux/Reducers/addressBookReducer";
import Cookies from "universal-cookie";
import UsersBody from "./Body";
import { setNotificationSnackBar } from "../../../../Services/Redux/Reducers/authSliceReducer";

const UsersComponent = () => {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const socket = useSelector((state) => state.auth.socket); // Accessing socket from the Redux store
  const usersFilter = useSelector((state) => state?.addressBook?.usersFilter);
  const dispatch = useDispatch(); // Initializing Redux dispatch
  const cookies = new Cookies(); // Cookies instance for managing cookies

  // Function to handle search logic by sending a socket event
  const getUsersMenus = () => {
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "menus", // Specifying the search type
          menus: {
            searchTxt: "",
          },
        },
      },
      (response) => {
        dispatch(setUsersMenusData(response?.data));
        cookies.set("_usersMenusData", response?.data, {
          path: "/",
          expires: new Date(Date.now() + 2592000), // Set cookie expiration (30 days)
        });
      }
    );
  };

  // Function to handle search logic by sending a socket event
  const onSearch = () => {
    setIsLoading(true); // Set loading state to true when the search starts
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "addressBook",
        addressBook: {
          type: "searchUsers", // Specifying the search type
          searchUsers: {
            fullName: usersFilter?.fullName, // Pass full name filter
            username: usersFilter?.username, // Pass address number filter
            addressNb: usersFilter?.addressNb, // Pass address number filter
            userMenus: usersFilter?.userMenus, // Pass address number filter
            searchTxt: usersFilter?.searchTxt, // Pass address number filter
          },
        },
      },
      (response) => {
        if (response?.responseStatus === "success") {
          dispatch(
            setUsersData(
              response?.data?.map((obj) => {
                return {
                  ...obj, // Spread the original object
                  // Map and transform specific fields for better readability
                  userType:
                    (obj?.userType === "C" && "Customer") ||
                    (obj?.userType === "E" && "Employee"),
                  userDefaultLanguage:
                    (obj?.userDefaultLanguage === "en" && "English") ||
                    (obj?.userDefaultLanguage === "fr" && "French"),
                  userStatus:
                    (obj?.userStatus === "A" && "Active") ||
                    (obj?.userStatus === "N" && "Disabled"),
                };
              })
            )
          ); // Update users data with response
        } else {
          // If the server response indicates failure, show a notification
          dispatch(
            setNotificationSnackBar({
              open: true, // Open the notification snackbar
              message: response?.message?.text, // Display the response message
              type: response?.message?.messageType, // Set notification type
            })
          );
        }
        setIsLoading(false); // Set loading state to false when the search completes
      }
    );
  };

  useEffect(() => {
    if (socket) {
      getUsersMenus();
    }
  }, [socket]);

  return (
    <Box sx={{ color: "black" }}>
      {" "}
      <Grid sx={{ backgroundColor: "rgb(226, 226, 226)" }}>
        {/* Main container with text color set to black */}
        <FilterComponent
          children={<UsersFilterReturn onSearch={onSearch} />} // Rendering filter component with loading state handler
        />
        {/* Conditionally rendering UsersBody if not loading and data is available */}
        {!isLoading && <UsersBody onSearch={onSearch} />}
      </Grid>
      {/* Displaying a loading spinner when isLoading is true */}
      {isLoading && (
        <div
          style={{
            width: "100%", // Full width
            display: "flex", // Flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <CircularProgress
            sx={{
              color: "#1976d2", // Spinner color
              width: "100px !important", // Setting width
              height: "100px !important", // Setting height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default UsersComponent; // Exporting the component for use in other parts of the application
