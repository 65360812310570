// Import the base API slice from the apiSlice file (base API logic is defined here)
import { apiSlice } from "../apiSlice";

// Inject new endpoints into the existing API slice for authentication-related functionality
export const authApiSlice = apiSlice.injectEndpoints({
  // Define the new endpoints and their queries/mutations
  endpoints: (builder) => ({
    // Define a mutation for user login
    login: builder.mutation({
      query: (credentials) => ({
        // API endpoint for login request
        url: "/session/admin/v2/loginrequest",
        method: "POST", // HTTP method
        body: {
          // Request body sent with the login request
          username: credentials?.username, // Username provided by the user
          password: credentials?.password, // Password provided by the user
          platform: "web", // Platform identifier (always set to 'web' here)
        },
      }),
    }),

    // Define a mutation for password reset request
    passwordReset: builder.mutation({
      query: (credentials) => ({
        // API endpoint for requesting a password reset
        url: "/session/v1/passreset",
        method: "POST", // HTTP method
        body: {
          username: credentials?.username, // Username for which the password reset is requested
        },
      }),
    }),

    // Define a mutation for confirming the password reset (changing the password)
    changingPassword: builder.mutation({
      query: (credentials) => ({
        // API endpoint for confirming and setting a new password
        url: "/session/v1/passresetconfirm",
        method: "POST", // HTTP method
        body: {
          passCode: credentials?.code, // Code sent to the user for password confirmation
          requestId: credentials?.requestId, // Request ID for the password reset
          password: credentials?.password, // New password to be set
        },
      }),
    }),
  }),
});

// Export custom hooks for using the mutations in React components
export const {
  useLoginMutation, // Custom hook to use the login mutation
  usePasswordResetMutation, // Custom hook to use the password reset mutation
  useChangingPasswordMutation, // Custom hook to use the change password mutation
} = authApiSlice;
