import { Box } from "@mui/material";
import React from "react";
import ResetPasswordTemplate from "./ResetPasswordTemplate";
import DisableUserTemplate from "./DisableUserTemplate";
import AssignMenusTemplate from "./AssignMenusTemplate";

const UserContextMenu = ({
  menu,
  resetPassword,
  setResetPassword,
  assignedMenus,
  setAssignedMenus,
  type,
}) => {
  const onChange = (e, item) => {
    setResetPassword({
      ...resetPassword,
      [item]: e,
    });
  };

  return (
    <Box>
      {menu === "resetPassword" && (
        <ResetPasswordTemplate
          onChange={onChange}
          resetPassword={resetPassword}
        />
      )}
      {menu === "enableDisableUser" && <DisableUserTemplate type={type} />}
      {menu === "assignMenus" && (
        <AssignMenusTemplate
          assignedMenus={assignedMenus}
          setAssignedMenus={setAssignedMenus}
        />
      )}
    </Box>
  );
};

export default UserContextMenu;
