// Importing necessary components from React Router and Redux
import { useLocation, Navigate } from "react-router-dom"; // For handling navigation and getting current location
import { useSelector } from "react-redux"; // For accessing the Redux store
import MainLayout from "../../../components/Pages/MainLayout"; // Importing the layout for authenticated users

// This component ensures that only authenticated users can access certain routes
const RequireAuth = () => {
  // Get the current user from the Redux store (auth slice)
  const user = useSelector((state) => state.auth.user);

  // Get the current location (the URL the user is trying to visit)
  const location = useLocation();

  // If the user is authenticated (i.e., `user` is not null or undefined)
  if (user) {
    // If the user is authenticated, render the MainLayout component (protected page content)
    return <MainLayout />;
  }

  // If the user is not authenticated:
  // Redirect them to the login page ('/') and pass the current location in the state.
  // This way, after logging in, the user can be redirected back to the originally requested page.
  return <Navigate to="/" replace state={{ from: location }} />;
};

// Export the RequireAuth component for use in routing
export default RequireAuth;
