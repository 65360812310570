import React, { useState } from "react";

const CutomizedAddTemplate = (props) => {
  // Initialize the state with the passed data from props
  const [state, setState] = useState({ ...props[0] });

  // Handle change in the input fields and update the state
  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value, // Update the respective field in state
    }));
  };

  return (
    <div id="tab0" className="tab" style={{ display: "ruby" }}>
      <div className="form-row" style={{ width: "50%" }}>
        {/* Description input field */}
        <div
          className="form-group col-md-6"
          style={{
            width: "100%", // Make the input field take up 100% of the container
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            {/* Menu Description input field */}
            <input
              id="menuDesc"
              name="menuDesc" // Field name to bind to state
              type="text"
              value={state.menuDesc || ""} // Set the value from state or empty string
              onChange={onChange} // Call onChange function to update state
            />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Description</label>{" "}
            {/* Label for the input field */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CutomizedAddTemplate;
