import { Box, Grid } from "@mui/material"; // Importing Box and Grid components from MUI for layout
import React from "react"; // Importing React
import BasicTextFields from "../../../../Common/TextField"; // Importing custom BasicTextFields component for input fields
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import {
  setMasterInfoData, // Redux action to set master info data
  setMasterInfoFilter, // Redux action to set master info filter
} from "../../../../../Services/Redux/Reducers/addressBookReducer";
import BasicSelect from "../../../../Common/Select"; // Importing custom BasicSelect component for dropdown
import BasicButtons from "../../../../Common/Button"; // Importing custom BasicButtons component for button

const MasterInfoFilterReturn = ({ setIsLoading }) => {
  // Selecting the master info filter state from the Redux store
  const masterInfoFilter = useSelector(
    (state) => state?.addressBook?.masterInfoFilter
  );
  const dispatch = useDispatch(); // Initializing Redux dispatch
  const socket = useSelector((state) => state.auth.socket); // Accessing socket from the Redux store

  // Function to handle input changes and update the filter in the Redux store
  const handleChange = (e, item) => {
    dispatch(
      setMasterInfoFilter({
        ...masterInfoFilter, // Retain previous filter values
        [item]: e?.target?.value, // Update the specific filter item with the new value
      })
    );
  };

  // Options for the "Address Type" dropdown
  const addressType = [
    {
      title: "All", // Title for the dropdown option
      value: "all", // Value for the option
    },
    {
      title: "Employee", // Title for the dropdown option
      value: "E", // Value for the option
    },
    {
      title: "Customer",
      value: "C",
    },
  ];

  // Function to handle search logic by sending a socket event
  const onSearch = () => {
    setIsLoading(true); // Set loading state to true when the search starts
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "addressBook",
        addressBook: {
          type: "searchBook", // Specifying the search type
          searchBook: {
            fullName: masterInfoFilter?.fullName, // Pass full name filter
            addressNb: masterInfoFilter?.addressNb, // Pass address number filter
            addressType:
              masterInfoFilter?.addressType === "all"
                ? ""
                : masterInfoFilter?.addressType, // Pass address type filter
          },
        },
      },
      (response) => {
        dispatch(setMasterInfoData(response?.data)); // Update master info data with response
        setIsLoading(false); // Set loading state to false when the search completes
      }
    );
  };

  return (
    <Box>
      <Grid container>
        {" "}
        {/* Grid container for layout */}
        {/* Address number input field */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={masterInfoFilter?.addressNb} // Setting the value from the filter state
            id="addressNb" // Assigning a unique ID
            onChange={(e) => handleChange(e, "addressNb")} // Handling change for address number
            label="Address Nb" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Full name input field */}
        <Grid sx={{ width: "15%" }}>
          <BasicTextFields
            value={masterInfoFilter?.fullName} // Setting the value from the filter state
            id="fullName" // Assigning a unique ID
            onChange={(e) => handleChange(e, "fullName")} // Handling change for full name
            label="Full Name" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Address type dropdown field */}
        <Grid sx={{ width: "15%" }}>
          <BasicSelect
            label={"Address Type"} // Label for the dropdown
            value={masterInfoFilter?.addressType} // Setting the value from the filter state
            onChange={(e) => handleChange(e, "addressType")} // Handling change for address type
            data={addressType} // Data source for the dropdown
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Search button */}
        <Grid sx={{ width: "8%", display: "flex" }}>
          <BasicButtons text={"Search"} onClick={onSearch} />{" "}
          {/* Button to trigger the search */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MasterInfoFilterReturn; // Exporting the component as default
