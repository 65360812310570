import { Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import DualListBox from "../../../../../../Common/DualListBox";

const AssignMenusTemplate = ({ assignedMenus, setAssignedMenus }) => {
  const userMenusData = useSelector(
    (state) => state?.addressBook?.userMenusData
  );
  const usersMenusData = useSelector(
    (state) => state?.addressBook?.usersMenusData
  )?.menus;

  const titles = ["All Menus", "User Menu"]; // Titles for DualListBox

  // Prepare the data for the DualListBox component
  const data = () => {
    // Get the specific pages for the current menu
    const specific =
      userMenusData?.map(({ menuId, menuDesc, userId, lineId }) => ({
        menuId: menuId,
        text: menuDesc,
        userId: userId,
        lineId: lineId,
      })) || [];

    // Get the IDs of pages that are already selected
    const idsToRemove = specific?.map((obj) => obj?.menuId);

    // Filter out pages that are already selected (i.e., pages that are not in 'specific')
    const all =
      usersMenusData
        ?.map(({ menuId, menuDesc }) => ({
          menuId: menuId,
          text: menuDesc,
        }))
        ?.filter((item) => !idsToRemove?.includes(item.menuId)) || [];

    return {
      groupa: all, // Pages that are available to add
      groupb: specific, // Pages that are already selected
    };
  };

  const onChanges = (e) => {
    if (e?.eventName === "moveAllTo") {
      // Move all pages to selected
      setAssignedMenus(usersMenusData); // Select all pages
    } else if (e?.eventName === "moveAllFrom") {
      // Move all pages to available
      setAssignedMenus([]); // Deselect all pages
    } else if (e?.eventName === "moveTo") {
      // Move a page to selected
      setAssignedMenus([...assignedMenus, e?.items?.[0]]); // Add selected page
    } else if (e?.eventName === "moveFrom") {
      // Move a page to available
      const removed = assignedMenus?.filter(
        (item) => item.menuId !== e?.items?.[0]?.menuId // Remove the page from the selected list
      );
      setAssignedMenus(removed); // Update selected pages
    }
  };

  return (
    <Box>
      <DualListBox
        data={data()}
        titles={titles}
        onChanges={onChanges}
        onlyText={true}
      />
    </Box>
  );
};

export default AssignMenusTemplate;
