import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid"; // Import the custom data grid component
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer"; // Redux action for showing notifications
import CutomizedTemplate from "./CustomizedTemplate";
import AlertDialog from "../../../../Common/AlertDialog";
import AddressNbPopUp from "./AddressNbPopUp";
import {
  setAddressNbUserSelected,
  setUserMenusData,
  setUsersAddressNbData,
} from "../../../../../Services/Redux/Reducers/addressBookReducer";
import MyAlertDialog from "../../../../Common/MyAlertDialog";
import UserContextMenu from "./ContextMenu";

const UsersBody = ({ onSearch }) => {
  // Get socket and data from Redux store
  const socket = useSelector((state) => state.auth.socket);
  const usersData = useSelector((state) => state?.addressBook?.usersData);
  const dispatch = useDispatch();
  const [openAddressNb, setOpenAddressNb] = useState(false);
  const [addressNbLoading, setAddressNbLoading] = useState(false);
  const [addressNbRowSelected, setAddressNbRowSelected] = useState({});
  const [resetPassword, setResetPassword] = useState({
    autoGenerate: true,
    addressNb: "",
    usernameId: "",
    newPass: "",
  });
  const userMenusData = useSelector(
    (state) => state?.addressBook?.userMenusData
  );
  const [assignedMenus, setAssignedMenus] = useState([]);

  // State for controlling context menu visibility and data
  const [openContextMenu, setOpenContextMenu] = useState({
    menu: "",
    open: false,
    data: {},
  });

  const generalAPI = (type, data, resp) => {
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "addressBook",
        addressBook: {
          type: type, // Specifying the type of action (menuUpdate)
          [type]: data,
        },
      },
      (response) => {
        resp(response);
      }
    );
  };

  // Column configuration for the DataGrid
  const ColumnDirective = [
    {
      field: "userId",
      headerText: "User ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
      allowEditing: false,
    },
    { field: "addressNb", headerText: "Address Nb", width: "100" },
    { field: "userName", headerText: "Username", width: "100" },
    {
      field: "userDefaultLanguage",
      headerText: "Default Language",
      width: "100",
    },
    { field: "userType", headerText: "Type", width: "100" },
    { field: "userStatus", headerText: "Status", width: "100" },
  ];

  // Function to send data to the server via socket connection
  const sendData = (data, action) => {
    // Construct the data object to be sent
    const sendedData =
      (action === "add" && {
        action: "add",
        username: data?.userName ? data?.userName : "",
        addressNb: data?.addressNb ? data?.addressNb : "",
        defaultLanguage: data?.userDefaultLanguage
          ? (data?.userDefaultLanguage === "English" && "en") ||
            (data?.userDefaultLanguage === "French" && "fr")
          : "",
        userType: data?.userType
          ? (data?.userType === "Customer" && "C") ||
            (data?.userType === "Employee" && "E")
          : "",
      }) ||
      (action === "delete" && {
        action: "delete",
        usernameId: data?.userId, // Pass full name filter
        addressNb: data?.addressNb, // Pass address number filter
        action: "delete", // Pass address number filter
      });
    // Send the data using socket event
    if (data?.addressNb) {
      generalAPI(
        action === "add" ? "addUser" : "updateUser",
        sendedData,
        (e) => {
          dispatch(
            setNotificationSnackBar({
              open: true, // Open the notification snackbar
              message: e?.message?.text, // Show the response message
              type: e?.message?.messageType, // Notification type (success, error, etc.)
            })
          );
          onSearch();
        }
      );
    }
  };

  // Function to handle changes in the DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      sendData(args?.promise?.[0], "delete"); // Handle delete action
    } else if (args.action === "edit") {
      const updatedData = { ...args.data }; // Clone the data for editing
      delete updatedData.sections; // Exclude read-only field
      sendData(updatedData, "update"); // Handle update action
    } else if (args.action === "add") {
      sendData(args.data, "add"); // Handle add action
    }
  };

  const selectAddressNb = (addressNb) => {
    setOpenAddressNb(false);
    dispatch(setAddressNbUserSelected(addressNb));
  };

  // Conditional dialog template based on whether it's add or edit
  const dialogTemplate = (props, grid) => {
    const a = [props, grid];

    const openAddressNbr = () => {
      setAddressNbLoading(true);
      generalAPI(
        "searchBook",
        {
          fullName: "", // Pass full name filter
          addressNb: "", // Pass address number filter
          addressType: "", // Pass address type filter
        },
        (e) => {
          dispatch(setUsersAddressNbData(e?.data)); // Update master info data with response
          setAddressNbLoading(false); // Set loading state to false when the search completes
          setOpenAddressNb(true);
        }
      );
    };

    return (
      <CutomizedTemplate
        {...a}
        openAddressNbr={openAddressNbr}
        addressNbRowSelected={addressNbRowSelected}
        isAdd={props?.isAdd}
      />
    ); // Return add template for new entries
  };

  const handleClose = () => {
    setOpenAddressNb(false);
    setOpenContextMenu({
      menu: "",
      open: false,
      data: {},
    });
  };

  const contextMenuItems = [
    { text: "Assign Menus", target: ".e-content", id: "assignMenus" }, // Menu item for emails
    { text: "Reset Password", target: ".e-content", id: "resetPassword" }, // Menu item for emails
    {
      text: "Enable/Disable User",
      target: ".e-content",
      id: "enableDisableUser",
    }, // Menu item for phone numbers
  ];

  // Function to open context menu with selected menu and data
  const openContextMenuPopUp = (menu, data) => {
    setOpenContextMenu({
      menu: menu,
      open: true,
      data: data,
    });
  };

  const contextMenuClick = (args, gridInstance) => {
    if (gridInstance && args.item.id === "resetPassword") {
      openContextMenuPopUp("resetPassword", args?.rowInfo?.rowData); // Open context menu for resetPassword
      setResetPassword({
        ...resetPassword,
        addressNb: args?.rowInfo?.rowData?.addressNb,
        usernameId: args?.rowInfo?.rowData?.userId,
      });
    } else if (gridInstance && args.item.id === "enableDisableUser") {
      openContextMenuPopUp("enableDisableUser", args?.rowInfo?.rowData); // Open context menu for enableDisableUser
    } else if (gridInstance && args.item.id === "assignMenus") {
      openContextMenuPopUp("assignMenus", args?.rowInfo?.rowData); // Open context menu for enableDisableUser
      generalAPI(
        "userMenus",
        {
          usernameId: args?.rowInfo?.rowData?.userId,
        },
        (e) => {
          dispatch(setUserMenusData(e?.data));
          setAssignedMenus(e?.data);
        }
      );
    }
  };

  const sendContextData = () => {
    const api =
      (openContextMenu?.menu === "resetPassword" && {
        type: "resetPass",
        data: {
          usernameId: resetPassword?.usernameId, // Pass full name filter
          addressNb: resetPassword?.addressNb, // Pass address number filter
          autoGenerate: resetPassword?.autoGenerate, // Pass address type filter
          newPass: resetPassword?.newPass, // Pass address type filter
        },
      }) ||
      (openContextMenu?.menu === "enableDisableUser" && {
        type: "updateUser",
        data: {
          usernameId: openContextMenu?.data?.userId, // Pass full name filter
          addressNb: openContextMenu?.data?.addressNb, // Pass address number filter
          action:
            openContextMenu?.data?.userStatus === "Active"
              ? "disable"
              : "enable", // Pass address type filter
        },
      }) ||
      (openContextMenu?.menu === "assignMenus" && {
        type: "updateUserMenus",
        data: {
          usernameId: openContextMenu?.data?.userId, // Pass full name filter
          userMenus: assignedMenus?.map((obj) => obj?.menuId), // Pass address number filter
        },
      });
    generalAPI(api?.type, api?.data, (e) => {
      dispatch(
        setNotificationSnackBar({
          open: true, // Open the notification snackbar
          message: e?.message?.text, // Show the response message
          type: e?.message?.messageType, // Notification type (success, error, etc.)
        })
      );
      setOpenContextMenu({
        menu: "",
        open: false,
        data: {},
      });
      setResetPassword({
        autoGenerate: true,
        addressNb: "",
        usernameId: "",
        newPass: "",
      });
      onSearch();
    });
  };

  const myRequiredFields = (args) => {
    if (!args?.data?.addressNb) {
      alert("Some fields are required to proceed.");
      args.cancel = true;
    }
  };

  return (
    <Grid>
      <SingleHierarchy
        columns={ColumnDirective}
        data={JSON.parse(JSON.stringify(usersData || []))} // Make sure data is properly parsed and passed
        primaryKey={"userId"}
        selection={"Single"}
        onChanges={onChanges} // Attach onChanges handler
        readOnly={false} // Allow editing
        template={(props, grid) => dialogTemplate(props, grid)} // Custom template for the dialog
        contextMenu={true} // Enabling context menu
        contextMenuItems={contextMenuItems}
        contextMenuClick={(args, gridInstance) =>
          contextMenuClick(args, gridInstance)
        }
        mode={"noEdit"}
        required={true} // Set as required
        myRequiredFields={myRequiredFields}
      />
      {openAddressNb && ( // Conditionally render context menu popup
        <AlertDialog
          open={openAddressNb}
          handleClose={handleClose}
          title={"Address Number"} // Display appropriate title
          content={
            <AddressNbPopUp
              addressNbLoading={addressNbLoading}
              setAddressNbRowSelected={setAddressNbRowSelected}
            />
          }
          buttonTitle={"Select"}
          buttonClick={() => selectAddressNb(addressNbRowSelected?.addressNb)} // Send changes on button click
          maxWidth={"1000px"}
        />
      )}
      {openContextMenu?.open && ( // Conditionally render context menu popup
        <MyAlertDialog
          open={openContextMenu?.open}
          handleClose={handleClose}
          title={
            (openContextMenu?.menu === "resetPassword" && "Reset Password") ||
            (openContextMenu?.menu === "assignMenus" && "Assign Menus") ||
            (openContextMenu?.menu === "enableDisableUser" &&
            openContextMenu?.data?.userStatus === "Active"
              ? "Disable User"
              : "Enable User")
          } // Display appropriate title
          content={
            <UserContextMenu
              menu={openContextMenu?.menu}
              resetPassword={resetPassword}
              setResetPassword={setResetPassword}
              setAssignedMenus={setAssignedMenus}
              assignedMenus={assignedMenus}
              type={
                openContextMenu?.menu === "enableDisableUser" &&
                openContextMenu?.data?.userStatus
              }
            />
          }
          buttonTitle={
            openContextMenu?.menu === "resetPassword" ||
            openContextMenu?.menu === "assignMenus"
              ? "Send"
              : openContextMenu?.data?.userStatus === "Active"
                ? "Disable"
                : "Enable"
          }
          width={openContextMenu?.menu === "assignMenus" ? "50%" : "30%"}
          buttonClick={sendContextData} // Send changes on button click
          disable={assignedMenus === userMenusData}
        />
      )}
    </Grid>
  );
};

export default UsersBody;
