import * as React from "react"; // Import React library
import Stack from "@mui/material/Stack"; // Import Material-UI Stack component for layout
import Button from "@mui/material/Button"; // Import Material-UI Button component
import "./button.css"; // Import custom CSS for additional button styling

// BasicButtons is a functional component that renders a styled button within a Stack layout
export default function BasicButtons(props) {
  return (
    // Stack container to arrange the button with spacing and direction
    <Stack
      spacing={2} // Space between stack items
      direction="row" // Arrange items in a horizontal row
      width={"100%"} // Full width of the container
      height={props?.boxheight} // Dynamic height based on the `boxheight` prop
    >
      {/* Material-UI Button with props spread for flexibility */}
      <Button
        {...props} // Spread props to allow customization (e.g., `onClick`, `disabled`, etc.)
        variant="contained" // Set button style to "contained" (elevated style)
        className="basic-button" // Apply custom CSS class for additional styles
      >
        {props.text} {/* Display button text passed via the `text` prop */}
      </Button>
    </Stack>
  );
}
