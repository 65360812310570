import React, { useState } from "react"; // Import React and useState hook for managing local state
import { useSelector } from "react-redux"; // Import useSelector to access Redux state
import CommonTab from "./tabs/common"; // Import the CommonTab component for displaying common settings
import LanguagesTab from "./tabs/languages"; // Import the LanguagesTab component for language settings
import { Box } from "@mui/material"; // Import Box component from Material-UI for layout
import Tab from "@mui/material/Tab"; // Import Tab component from Material-UI for tabs
import TabContext from "@mui/lab/TabContext"; // Import TabContext to manage active tab state
import TabList from "@mui/lab/TabList"; // Import TabList to display tab navigation
import TabPanel from "@mui/lab/TabPanel"; // Import TabPanel to display content based on selected tab

// CustomizeTemplate component allows customization of templates with tabs for "Common" and "Languages"
const CustomizeTemplate = (props) => {
  // Local state to manage current tab and form data
  const [state, setState] = useState({ ...props[0], currentTab: 0 }); // Initial state based on props, including currentTab for tab navigation
  const [value, setValue] = useState("0"); // State for currently selected tab value (string format)

  // Accessing languages data from Redux store using useSelector hook
  const languagesData = useSelector(
    (state) => state?.userFeatures?.languagesData // Fetching language data from the Redux state
  );

  // Function to handle tab change and update the selected tab value
  const handleChange = (event, newValue) => {
    setValue(newValue); // Update the active tab when a new tab is selected
  };

  // Handle form input changes and update state with new values
  const onChange = (e) => {
    const { name, value } = e.target; // Extract name and value from the input event
    setState((prevState) => ({
      ...prevState,
      [name]: value, // Update the state with the new value for the changed input field
    }));
    // Propagate the change to the parent component's updated changes
    props?.setUpdatedChanges((prevState) => ({
      ...prevState,
      [name]: value, // Update the parent state with the new value for the changed input field
    }));
  };

  return (
    <div>
      {/* Box container for tabs and their content */}
      <Box sx={{ width: "100%", typography: "body1" }}>
        {/* TabContext component to manage the active tab */}
        <TabContext value={value}>
          {/* TabList for navigation between tabs */}
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              {/* Tab for "Common" settings */}
              <Tab label="Common" value="0" />
              {/* Tab for "Languages" settings */}
              <Tab label="Languages" value="1" />
            </TabList>
          </Box>

          {/* TabPanel for displaying content for the "Common" tab */}
          <TabPanel value="0">
            <CommonTab state={state} onChange={onChange} />{" "}
            {/* Pass state and onChange handler to CommonTab */}
          </TabPanel>

          {/* TabPanel for displaying content for the "Languages" tab */}
          <TabPanel value="1">
            <LanguagesTab
              languagesData={languagesData} // Pass the languages data from Redux
              state={state} // Pass the current state
              onChange={onChange} // Pass the onChange handler to update the state
            />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default CustomizeTemplate; // Export the component for use in other parts of the application
