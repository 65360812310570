import * as React from "react";
import Box from "@mui/material/Box"; // Importing Box for layout spacing
import InputLabel from "@mui/material/InputLabel"; // Importing InputLabel for dropdown label
import MenuItem from "@mui/material/MenuItem"; // Importing MenuItem for each dropdown option
import FormControl from "@mui/material/FormControl"; // Importing FormControl to wrap Select component
import Select from "@mui/material/Select"; // Importing Select for the dropdown
import Checkbox from "@mui/material/Checkbox"; // Importing Checkbox to allow multi-selection
import ListItemText from "@mui/material/ListItemText"; // Importing ListItemText to display option text

/**
 * MultiSelect Component
 * A reusable multi-select dropdown with a "Select All" feature using Material UI.
 *
 * Props:
 * - label: The label text for the select input.
 * - value: The array of selected values.
 * - onChange: A callback function triggered when the selected values change.
 * - data: An array of options, each containing a 'title' and 'value'.
 */
export default function MultiSelect({ label, value, onChange, data = [] }) {
  // Check if all options are selected
  const allSelected = value.length === data.length;

  /**
   * handleChange - Handles selection changes for the dropdown.
   * @param {Event} event - The event triggered by user interaction.
   */
  const handleChange = (event) => {
    const selectedValues = event.target.value;

    // If "Select All" is clicked, either select all or clear selection
    if (selectedValues.includes("all")) {
      onChange(allSelected ? [] : data.map((item) => item.value));
    } else {
      onChange(selectedValues); // Update state with selected values
    }
  };

  return (
    <Box sx={{ minWidth: 200 }}>
      {/* FormControl ensures the dropdown works correctly */}
      <FormControl fullWidth>
        {/* InputLabel for displaying the label */}
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>

        {/* Select component allowing multiple selections */}
        <Select
          labelId={`${label}-select-label`}
          id={`${label}-select`}
          multiple
          value={value}
          onChange={handleChange}
          renderValue={
            (selected) =>
              selected.length === data.length
                ? "All Selected" // Display when all options are selected
                : selected.join(", ") // Otherwise, show selected values
          }
        >
          {/* "Select All" Option */}
          <MenuItem value="all">
            <Checkbox checked={allSelected} /> {/* Checkbox for "Select All" */}
            <ListItemText primary="Select All" />
          </MenuItem>

          {/* Mapping over data to create individual options */}
          {data.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={value.includes(item.value)} />{" "}
              {/* Checkbox for each option */}
              <ListItemText primary={item.title} /> {/* Display option text */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
