// Importing necessary dependencies
import { Box } from "@mui/material"; // MUI Box component for layout styling
import React from "react"; // React library for building the component
import DefaultComponent from "../../Common/BoxItems"; // Custom component for rendering data

// HomeComponent is a functional component that receives 'selectedMenu' as a prop
const HomeComponent = ({ selectedMenu }) => {
  return (
    // Box component for setting padding and layout styling
    <Box sx={{ paddingX: "5%" }}>
      {/* Rendering DefaultComponent and passing selectedMenu data as a prop */}
      <DefaultComponent data={selectedMenu} />
    </Box>
  );
};

export default HomeComponent;
