// Import necessary modules and components
import React from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material"; // Material-UI components
import SingleHierarchy from "../../../../../Common/DataGrid/singleGrid"; // Custom grid component
import { useSelector } from "react-redux"; // Redux hook to access store data
import { DropDownList } from "@syncfusion/ej2-dropdowns"; // Syncfusion dropdown component
import "@syncfusion/ej2-base/styles/material.css"; // Syncfusion styles for components
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";

// ContextMenuComponent to manage context menus and grid operations
const ContextMenuComponent = ({
  menu, // Type of menu ('emails' or 'phones')
  changes, // State that holds changes to be saved
  setChanges, // Function to update changes state
  data, // Data passed to component
  isContextMenuLoading, // Boolean to control loading state
  phoneTypes, // List of available phone types for dropdown
}) => {
  // Access contextMenuData from Redux store
  const contextMenuData = useSelector(
    (state) => state?.addressBook?.contextMenuData // Getting contextMenuData from Redux
  );

  // Parameters to configure the dropdown editor for phone types
  const editPhoneTypeParams = {
    create: () => {
      const input = document.createElement("input"); // Create input element for dropdown
      input.className = "e-input"; // Apply styling to input
      return input; // Return input element
    },
    read: (element) => element.ej2_instances[0]?.value, // Get the selected value from the dropdown
    write: (args) => {
      // Initialize the dropdown editor with phone types
      new DropDownList({
        dataSource: phoneTypes || [], // Use phoneTypes, default to empty if undefined
        fields: { text: "phoneType", value: "phoneType" }, // Mapping text and value for dropdown
        value: args.rowData[args.column.field] || null, // Preselect value for the dropdown
        popupHeight: "300px", // Set height of the dropdown
        placeholder: "Select Unit", // Placeholder text
        floatLabelType: "Always", // Always show label
      }).appendTo(args.element); // Append dropdown to the editor element
    },
  };

  // Column definitions for phone data
  const ColumnPhoneDirective = [
    {
      field: "lineId",
      headerText: "ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
    }, // Primary key
    { field: "countryCode", headerText: "Code", width: "100" },
    { field: "phoneNb", headerText: "Number", width: "100" },
    {
      field: "phoneType", // Phone type column with dropdown editor
      headerText: "Type",
      width: "100",
      editType: "dropdownedit", // Set editor type to dropdown
      edit: editPhoneTypeParams, // Attach phone type dropdown configuration
    },
  ];

  // Column definitions for email data
  const ColumnEmailsDirective = [
    {
      field: "lineId",
      headerText: "ID",
      width: "50",
      isPrimaryKey: true,
      isIdentity: true,
    },
    { field: "email", headerText: "Email", width: "300" },
  ];

  // Helper function to check if two objects are equal (used for detecting changes)
  const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  // Function to generate a random lineId for new entries
  function getRandomlineId() {
    const min = 100;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Function to handle changes (add, edit, delete) in the grid
  const onChanges = (args, gridInstance) => {
    let updated = {
      action: args?.action === "add" ? "add" : "update", // Determine action type (add or update)
      addressNb: data?.addressNb, // Include address number in changes
      ...args.data, // Include row data
    };

    if (args.requestType === "delete") {
      // Handle delete action
      const changesDelete = {
        action: "delete", // Action type for deletion
        lineId: args?.promise?.[0]?.lineId, // Line ID of the deleted record
        addressNb: data?.addressNb, // Address number for the deleted record
      };

      // Update changes state for deletion
      const existingIndex = changes?.findIndex(
        (change) => change?.lineId === args?.promise?.[0]?.lineId
      );

      if (gridInstance?.current) {
        gridInstance.current.refresh(); // Refresh the grid after deletion
      }

      if (existingIndex !== -1) {
        setChanges((prevChanges) => {
          if (changes[existingIndex]?.action === "update") {
            const updatedChanges = prevChanges.filter(
              (_, index) => index !== existingIndex
            );
            return [...updatedChanges, changesDelete];
          } else {
            const updatedChanges = prevChanges.filter(
              (_, index) => index !== existingIndex
            );
            return [...updatedChanges];
          }
        });
      } else {
        setChanges((prevChanges) => [...prevChanges, changesDelete]);
      }
    } else if (args.action === "edit") {
      // Handle edit action
      const existingIndex = changes?.findIndex(
        (change) => change?.lineId === args?.data?.lineId
      );

      if (existingIndex !== -1) {
        if (contextMenuData?.some((item) => areObjectsEqual(item, args.data))) {
          // If data is unchanged, remove from changes
          setChanges((prevChanges) => {
            const updatedChanges = [...prevChanges];
            updatedChanges.splice(existingIndex, 1);
            return updatedChanges;
          });
        } else if (changes[existingIndex]?.action === "add") {
          // If the action is 'add', update the data
          setChanges((prevChanges) =>
            prevChanges.map((change, index) =>
              index === existingIndex
                ? {
                    action: "add",
                    addressNb: data?.addressNb,
                    ...{ ...args.data },
                  }
                : change
            )
          );
        } else {
          // Otherwise, update the existing entry
          setChanges((prevChanges) =>
            prevChanges.map((change, index) =>
              index === existingIndex
                ? {
                    ...change,
                    addressNb: data?.addressNb,
                    ...{ ...args.data },
                  }
                : change
            )
          );
        }
      } else {
        setChanges((prevChanges) => [...prevChanges, { ...updated }]);
      }
    } else if (args.action === "add") {
      // Handle add action
      if (!args.data.lineId) {
        args.data.lineId = getRandomlineId(); // Assign a random lineId for new records
      }

      updated = {
        action: "add",
        addressNb: data?.addressNb,
        ...args.data,
      };

      setChanges((prevChanges) => [...prevChanges, updated]);
    }

    // Refresh grid after saving or deleting
    if (args.requestType === "save" || args.requestType === "delete") {
      if (gridInstance?.current) {
        gridInstance.current.refresh();
      }
    }
  };

  // Logic for opening the context menu popup
  const openContextMenuPopUp = (type, rowData) => {
    // Logic for opening context menu goes here
  };

  // Logic for retrieving context menu data
  const getContextMenuData = (type, id) => {
    // Logic for retrieving context menu data goes here
  };

  return (
    <Box>
      <Grid>
        {/* Conditional rendering based on loading state */}
        {!isContextMenuLoading ? (
          <SingleHierarchy
            columns={
              menu === "emails" ? ColumnEmailsDirective : ColumnPhoneDirective
            } // Select columns based on menu type
            data={JSON.parse(JSON.stringify(contextMenuData))} // Pass context menu data to grid
            primaryKey={"lineId"} // Set the primary key
            selection={"Multiple"} // Allow multiple selections
            onChanges={onChanges} // Handle changes in the grid
            openContextMenuPopUp={openContextMenuPopUp} // Open context menu popup
            getContextMenuData={getContextMenuData} // Retrieve context menu data
            contextMenu={false} // Disable context menu by default
            readOnly={false} // Allow editing of data
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Typography variant="body1">Loading records...</Typography>{" "}
            {/* Loading text */}
            <CircularProgress size={24} sx={{ ml: 2 }} />{" "}
            {/* Loading spinner */}
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default ContextMenuComponent;
