import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import GraphicalPagesUpdate from "./GraphicUpdate"; // Component for graphical page updates
import {
  setPagesDataChanges,
  setUpdatePageData,
} from "../../../../../Services/Redux/Reducers/userFeaturesReducer"; // Actions for updating the Redux store
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid"; // Component for the grid
import JsonArrays from "../JSON"; // JSON data for dropdowns
import { DropDownList } from "@syncfusion/ej2-dropdowns"; // Syncfusion dropdown
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer"; // Action for showing notifications
import CustomizeTemplate from "./CustomizeTemplate"; // Template for customizing pages
import CustomizeEditTemplate from "./CustomizeEditTemplate"; // Template for editing page customization

// Component for managing menu pages
const MenuPagesBody = ({
  openGraphicUpdate, // State for managing graphic update modal
  setOpenGraphicUpdate, // Function to toggle graphic update modal
  onSearch, // Function for search functionality
}) => {
  // Redux selectors to get state from the store
  const socket = useSelector((state) => state.auth.socket); // Socket connection
  const menuPagesData = useSelector(
    (state) => state?.userFeatures?.menuPagesData
  ); // Menu pages data from Redux store
  const updatePageData = useSelector(
    (state) => state?.userFeatures?.updatePageData
  ); // Page data for update

  const menuPagesFilter = useSelector(
    (state) => state?.userFeatures?.menuPagesFilter
  );

  const dispatch = useDispatch();

  // State to hold changes made to page data
  const [updatedChanges, setUpdatedChanges] = useState({
    pageId: "",
    pageTitle: "",
    pageDesc: "",
    pageFrTilte: "",
    pageFrDesc: "",
    pageType: "",
    pageTarget: "",
    platform: "",
    pageCategory: "",
    pageIcon: "",
    pageRoute: "",
  });

  // Open the graphic update modal with the selected page's data
  const openGraphic = (e) => {
    const modifiedData = { ...e };
    delete modifiedData.column; // Remove unnecessary column data
    delete modifiedData.sections; // Remove unnecessary section data

    // Set the state for opening the graphic update modal
    setOpenGraphicUpdate({
      open: true,
      data: e,
    });

    // Dispatch actions to update the Redux store
    dispatch(setUpdatePageData(e));
    dispatch(setPagesDataChanges(modifiedData));
  };

  // Close the graphic update modal
  const handleClose = () => {
    setOpenGraphicUpdate({
      open: false,
      data: {},
    });
    dispatch(setPagesDataChanges([])); // Reset the page data changes
  };

  // Dropdown configuration for "Target" column (in DataGrid)
  const editTargetParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input";
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value,
    write: (args) => {
      // Initialize dropdown list for the "Target" column
      new DropDownList({
        dataSource: JsonArrays.targets, // Data source for dropdown
        fields: { text: "title", value: "title" },
        value: args.rowData[args.column.field] || null, // Set value if available
        popupHeight: "300px",
        placeholder: "Target",
        floatLabelType: "Always",
      }).appendTo(args.element);
    },
  };

  // Dropdown configuration for "Platform" column (in DataGrid)
  const editPlatformParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input";
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value,
    write: (args) => {
      // Initialize dropdown list for the "Platform" column
      new DropDownList({
        dataSource: JsonArrays.platforms, // Data source for dropdown
        fields: { text: "title", value: "title" },
        value: args.rowData[args.column.field] || null, // Set value if available
        popupHeight: "300px",
        placeholder: "Platform",
        floatLabelType: "Always",
      }).appendTo(args.element);
    },
  };

  // Dropdown configuration for "Type" column (in DataGrid)
  const editTypeParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input";
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value,
    write: (args) => {
      // Initialize dropdown list for the "Type" column
      new DropDownList({
        dataSource: JsonArrays.type, // Data source for dropdown
        fields: { text: "title", value: "title" },
        value: args.rowData[args.column.field] || null, // Set value if available
        popupHeight: "300px",
        placeholder: "Type",
        floatLabelType: "Always",
      }).appendTo(args.element);
    },
  };

  // Column definitions for the DataGrid
  const ColumnDirective = [
    {
      field: "pageId",
      headerText: "Page ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
      allowEditing: false,
    },
    { field: "pageTitle", headerText: "Title", width: "100" },
    { field: "pageFrTitle", headerText: "Title", width: "100", visible: false },
    { field: "pageDesc", headerText: "Description", width: "100" },
    {
      field: "pageFrDesc",
      headerText: "Description",
      width: "100",
      visible: false,
    },
    {
      field: "pageType",
      headerText: "Type",
      width: "100",
      edit: editTypeParams,
    },
    {
      field: "pageTarget",
      headerText: "Target",
      width: "100",
      edit: editTargetParams,
    },
    {
      field: "platform",
      headerText: "Platform",
      width: "100",
      edit: editPlatformParams,
    },
    { field: "pageCategory", headerText: "Category", width: "100" },
    { field: "pageIcon", headerText: "Image", width: "100" },
    { field: "pageRoute", headerText: "Route", width: "100" },
  ];

  // Function to send data via socket connection
  const sendData = (data, action) => {
    const sendedData =
      // Prepare data based on the action (add, update, delete)
      action === "add"
        ? {
            action: "add",
            pageIcon: updatedChanges?.pageIcon || "",
            pageCategory: updatedChanges?.pageCategory || " ",
            platform: updatedChanges?.platform
              ? updatedChanges?.platform === "All"
                ? "mob,web"
                : updatedChanges?.platform
              : "",
            pageTarget: updatedChanges?.pageTarget
              ? updatedChanges?.pageTarget === "User"
                ? "user"
                : "admin"
              : "",
            pageType: updatedChanges?.pageType
              ? updatedChanges?.pageType === "Solo"
                ? "solo"
                : "composite"
              : "",
            pageRoute: updatedChanges?.pageRoute || "",
            language: [
              {
                lan: "en",
                pageTitle: updatedChanges?.pageTitle || "",
                pageDesc: updatedChanges?.pageDesc || "",
              },
              {
                lan: "fr",
                pageTitle: updatedChanges?.pageFrTitle || "",
                pageDesc: updatedChanges?.pageFrDesc || "",
              },
            ],
          }
        : action === "update"
          ? {
              action: "update",
              pageId: data?.pageId,
              pageDesc: data?.pageDesc || "",
              language: menuPagesFilter?.language === "EN" ? "en" : "fr",
              pageIcon: data?.pageIcon || "",
              pageCategory: data?.pageCategory || " ",
              platform: data?.platform
                ? data?.platform === "All"
                  ? "mob,web"
                  : data?.platform
                : "",
              pageTarget: data?.pageTarget
                ? data?.pageTarget === "User"
                  ? "user"
                  : "admin"
                : "",
              pageTitle: data?.pageTitle || "",
              pageType: data?.pageType
                ? data?.pageType === "Solo"
                  ? "solo"
                  : "composite"
                : "",
              pageRoute: data?.pageRoute || "",
            }
          : action === "delete"
            ? {
                action: "delete",
                pageId: data?.pageId,
              }
            : {};

    // Emit the data through the socket
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "pageUpdate", // Specifying the search type
          pageUpdate: sendedData,
        },
      },
      (response) => {
        if (response?.responseStatus === "success") {
          // Dispatch a notification upon success
          dispatch(
            setNotificationSnackBar({
              open: true, // Open the notification snackbar
              message: response?.message?.text, // Display the response message
              type: response?.message?.messageType, // Set notification type
            })
          );
          onSearch(); // Perform search after success
        } else {
          // Dispatch a notification upon failure
          dispatch(
            setNotificationSnackBar({
              open: true,
              message: response?.message?.text,
              type: response?.message?.messageType,
            })
          );
        }
      }
    );
  };

  // Handle changes in the DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      sendData(args?.promise?.[0], "delete");
    } else if (args.action === "edit") {
      const updatedData = { ...args.data };
      delete updatedData.sections; // Remove unnecessary section data
      sendData(updatedData, "update");
    } else if (args.action === "add") {
      sendData(args.data, "add");
    }
  };

  // Context menu items and their actions
  const contextMenuItems = [
    {
      text: "Languages", // Option to manage languages
      target: ".e-content",
      id: "languages",
      iconCss: "e-icons e-character-style",
    },
    "Edit", // Option for editing
    "Delete", // Option for deleting
    {
      text: "Customize", // Option for customizing page
      target: ".e-content",
      id: "customize",
      iconCss: "e-icons e-properties-1",
    },
  ];

  // Handle context menu item click events
  const contextMenuClick = (args, gridInstance) => {
    if (args.item.id === "customize") {
      openGraphic(args?.rowInfo?.rowData); // Open customization modal
    }
  };

  // Define dialog templates for add and edit modes
  const dialogTemplate = (props, grid) => {
    const a = [props, grid];
    if (props?.isAdd) {
      return <CustomizeTemplate {...a} setUpdatedChanges={setUpdatedChanges} />;
    } else {
      return (
        <CustomizeEditTemplate {...a} setUpdatedChanges={setUpdatedChanges} />
      );
    }
  };

  const myRequiredFields = (args) => {
    const add = args?.requestType === "add";
    if (
      !updatedChanges.pageTarget ||
      !updatedChanges.platform ||
      !updatedChanges.pageType ||
      !updatedChanges.pageTitle ||
      (add && !updatedChanges.pageFrTitle) ||
      !updatedChanges.pageDesc ||
      (add && !updatedChanges.pageFrDesc)
    ) {
      alert("Some fields are required to proceed.");
      args.cancel = true;
    }
  };

  return (
    <Grid>
      {!openGraphicUpdate?.open ? (
        // Render SingleHierarchy component when graphic update modal is not open
        <SingleHierarchy
          columns={ColumnDirective} // Define columns for the grid
          data={JSON.parse(JSON.stringify(menuPagesData))} // Clone data for display
          primaryKey={"pageId"} // Primary key for the grid
          selection={"Single"} // Single row selection
          onChanges={onChanges} // Handle grid changes (add, edit, delete)
          contextMenu={true} // Enable context menu
          readOnly={false} // Allow editing
          required={true} // Set as required
          contextMenuItems={contextMenuItems} // Define context menu items
          contextMenuClick={(args, gridInstance) =>
            contextMenuClick(args, gridInstance)
          }
          mode={"noPDF"} // Specify grid mode
          template={(props, grid) => dialogTemplate(props, grid)} // Render dialog templates
          style={true} // Apply styles
          myRequiredFields={myRequiredFields} // Pass updated changes data
        />
      ) : (
        // Render GraphicalPagesUpdate component when the modal is open
        <GraphicalPagesUpdate
          data={updatePageData} // Pass data for graphic update
          handleClose={handleClose} // Close modal function
          setOpenGraphicUpdate={setOpenGraphicUpdate} // Function to close the modal
          onSearch={onSearch} // Perform search after updating
        />
      )}
    </Grid>
  );
};

export default MenuPagesBody;
