// Importing the necessary modules from React and React Router
import React from "react"; // Importing React for component creation
import { Navigate } from "react-router-dom"; // Importing the `Navigate` component to redirect routes

// MissingRoute component: Used for handling undefined routes (404 page)
function MissingRoute() {
  // Redirects the user to the root path ("/") if no matching route is found
  return <Navigate to={{ pathname: "/" }} />;
}

// Exporting the MissingRoute component for use in other parts of the application
export { MissingRoute };
