import { Box, FormLabel, Grid } from "@mui/material"; // Import necessary MUI components for layout and styling
import React from "react"; // Import React library
import BasicTextFields from "../../../../../../../Common/TextField"; // Import custom text field component
import { UploaderComponent } from "@syncfusion/ej2-react-inputs"; // Import file uploader component from Syncfusion

// UpdateApps component handles the form for updating app details
const UpdateApps = ({ updateApp, setUpdateApp }) => {
  // Handles changes in text input fields and updates the corresponding value in the state
  const handleChange = (e, item) => {
    setUpdateApp({
      ...updateApp,
      [item]: e?.target?.value, // Dynamically update the corresponding field
    });
  };

  // Handles file selection and sets the selected image file URL
  const handleFileSelected = (event) => {
    const fileDetails = event.filesData[0]; // Get the first selected file
    if (fileDetails) {
      const file = fileDetails.rawFile; // Get the raw file object
      const fileUrl = URL.createObjectURL(file); // Generate a temporary URL for the file
      setUpdateApp({
        ...updateApp,
        appImgURL: fileUrl, // Set the image URL in the state
      });
      // You can display or use the fileUrl further as needed
    }
  };

  return (
    // Box component with some margin for styling
    <Box sx={{ marginY: "2%" }}>
      <Grid container>
        {/* Input field for the app ID (disabled) */}
        <Grid item width={"20%"}>
          <BasicTextFields
            value={updateApp?.appId ? updateApp?.appId : ""} // Display the appId if available
            id="appId" // Unique ID for the field
            label="ID" // Label for the field
            disabled // Disable the field so it cannot be edited
          />
        </Grid>
        <Grid item width={"3%"}></Grid>
        {/* Input field for the app title */}
        <Grid item width={"32%"}>
          <BasicTextFields
            value={updateApp?.appTitle ? updateApp?.appTitle : ""} // Display appTitle from the state
            id="appTitle" // Unique ID for the field
            onChange={(e) => handleChange(e, "appTitle")} // Update appTitle on change
            label="Title" // Label for the field
          />
        </Grid>
        <Grid item width={"3%"}></Grid>
        {/* Input field for the app description */}
        <Grid item width={"42%"}>
          <BasicTextFields
            value={updateApp?.appDesc ? updateApp?.appDesc : ""} // Display appDesc from the state
            id="appDesc" // Unique ID for the field
            onChange={(e) => handleChange(e, "appDesc")} // Update appDesc on change
            label="Description" // Label for the field
          />
        </Grid>
      </Grid>
      {/* Row for app route and socket label */}
      <Grid container sx={{ marginY: "2%" }}>
        {/* Input field for the app route */}
        <Grid item width={"30%"}>
          <BasicTextFields
            value={updateApp?.appRoute ? updateApp?.appRoute : ""} // Display appRoute from the state
            id="appRoute" // Unique ID for the field
            onChange={(e) => handleChange(e, "appRoute")} // Update appRoute on change
            label="Route" // Label for the field
          />
        </Grid>
        <Grid item width={"5%"}></Grid>
        {/* Input field for the app socket label */}
        <Grid item width={"30%"}>
          <BasicTextFields
            value={updateApp?.appSocketLabel ? updateApp?.appSocketLabel : ""} // Display appSocketLabel from the state
            id="appSocketLabel" // Unique ID for the field
            onChange={(e) => handleChange(e, "appSocketLabel")} // Update appSocketLabel on change
            label="Socket Label" // Label for the field
          />
        </Grid>
      </Grid>
      {/* Section for uploading the app image */}
      <Grid container>
        <Grid item width={"100%"}>
          {/* Label for the image uploader */}
          <FormLabel htmlFor="image-uploader" style={{ marginBottom: "8px" }}>
            Image URL
          </FormLabel>
          {/* UploaderComponent to allow file selection */}
          <UploaderComponent
            id="image-uploader" // Unique ID for the uploader
            allowedExtensions=".jpg,.jpeg,.png,.gif" // Allowed file types for upload
            multiple={false} // Only one file can be selected
            selected={handleFileSelected} // Event handler for file selection
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdateApps; // Export the UpdateApps component for use in other parts of the application
