import React, { useState } from "react"; // Importing React and useState for managing component state
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid"; // Importing a custom SingleHierarchy DataGrid component
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import "../../../../Common/DataGrid/singleGrid.css"; // Importing custom CSS for the SingleHierarchy DataGrid
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer"; // Importing Redux action to set a notification
import MyAlertDialog from "../../../../Common/MyAlertDialog"; // Importing a custom alert dialog
import getUDCApi from "../../../../../Services/Common/UDCAPI"; // Importing API utility for fetching UDC data
import ContextMenuItemsComponent from "./ContextMenu"; // Importing custom component for context menu items
import {
  setContextMenuItemsData,
  setFeatureTypeList,
  setUnitsList,
} from "../../../../../Services/Redux/Reducers/inventoryReducer"; // Importing Redux actions
import { DropDownList } from "@syncfusion/ej2-dropdowns"; // Importing Syncfusion's DropDownList for editable cells
import "@syncfusion/ej2-base/styles/material.css"; // Importing Syncfusion styles for dropdowns and grids
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";

// Functional component for rendering items master data with features and context menu functionality
const ItemsMasterBody = ({ brands }) => {
  // Accessing necessary data from Redux store using useSelector
  const itemsMasterData = useSelector(
    (state) => state?.inventory?.itemsMasterData // Accessing items master data from Redux store
  )?.map((data) => {
    // Modifying 'addressType' for better readability
    if (data?.addressType === "E") {
      return { ...data, addressType: "Employee" };
    } else {
      return { ...data, addressType: "Customer" };
    }
  });

  const [selectedRow, setSelectedRow] = useState([]); // State for holding selected row data

  // Fetching brand, unit, and feature type data from Redux store
  const brandList = useSelector((state) => state?.inventory?.brandList)?.data;
  const unitsList = useSelector((state) => state?.inventory?.unitsList);
  const featureTypeList = useSelector(
    (state) => state?.inventory?.featureTypeList
  );

  // Mapping brand, unit, and feature type lists for usage in dropdowns
  const units = unitsList?.data?.map((list) => ({
    id: Math.random(),
    featureUnitDesc: list?.UVDESC,
  }));

  const featureType = featureTypeList?.data?.map((type) => ({
    id: Math.random(),
    featureType: type?.UVDESC,
  }));

  // State for managing the loading state of context menu data
  const [isContextMenuLoading, setIsContextMenuLoading] = useState(false);

  const dispatch = useDispatch(); // Initializing Redux dispatch function

  const [changes, setChanges] = useState([]); // State for tracking changes in the grid
  const [openContextMenu, setOpenContextMenu] = useState({
    menu: "",
    open: false,
    data: {},
  }); // State for controlling context menu visibility and data

  // Function to open the context menu popup with data for the selected row
  const openContextMenuPopUp = (menu, data) => {
    setOpenContextMenu({
      menu: menu,
      open: true,
      data: data,
    });
  };

  // Function to close the context menu and reset states
  const handleClose = () => {
    setChanges([]); // Clear any changes
    dispatch(setContextMenuItemsData([])); // Reset context menu items in Redux store
    setOpenContextMenu({
      menu: "",
      open: false,
      data: {},
    });
  };

  // Defining parameters for the editable dropdown in the DataGrid for 'brandDesc' column
  const editBrandParams = {
    create: () => {
      const input = document.createElement("input"); // Create an input field
      input.className = "e-input"; // Add styling to the input field
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value, // Read value from the dropdown
    write: (args) => {
      new DropDownList({
        dataSource: brands || [], // Use the brands prop as the data source
        fields: { text: "brandDesc", value: "brandDesc" }, // Set fields for dropdown
        value: args.rowData[args.column.field] || null, // Preselect the current value
        popupHeight: "300px", // Set height for the dropdown popup
        placeholder: "Select Unit", // Placeholder text
        floatLabelType: "Always", // Always float label
      }).appendTo(args.element); // Attach the dropdown to the element
    },
  };

  // Column configuration for the DataGrid
  const ColumnDirective = [
    {
      field: "itemId", // Item ID column
      headerText: "Item ID",
      width: "100",
      isPrimaryKey: true, // Marking itemId as the primary key
      isIdentity: true, // Item ID is a unique identifier
    },
    { field: "itemNb", headerText: "Item Nb", width: "100" },
    { field: "itemDesc1", headerText: "Description 1", width: "100" },
    { field: "itemDesc2", headerText: "Description 2", width: "100" },
    {
      field: "brandDesc", // Editable 'brandDesc' column
      headerText: "Brand",
      width: "100",
      editType: "dropdownedit", // Set edit type to dropdown
      edit: editBrandParams, // Use the defined dropdown parameters
    },
  ];

  // Function to send data to the server using the socket connection
  const sendData = (data, action) => {
    const sendedData =
      (action === "add" && {
        action: "add",
        itemNb: data?.itemNb,
        itemDesc1: data?.itemDesc1,
        itemDesc2: data?.itemDesc2,
        brand: brandList?.filter(
          (brand) => brand?.UVDESC === data?.brandDesc
        )?.[0]?.UVVALUE,
      }) ||
      (action === "update" && {
        action: "update",
        itemId: data?.itemId,
        itemNb: data?.itemNb,
        itemDesc1: data?.itemDesc1,
        itemDesc2: data?.itemDesc2,
        brand: brandList?.filter(
          (brand) => brand?.UVDESC === data?.brandDesc
        )?.[0]?.UVVALUE,
      }) ||
      (action === "delete" && {
        action: "delete",
        itemId: data,
      });

    // socketproject.emit(
    //   "automation:web:admin:usr:v1", // Emitting data to the server using socket connection
    //   {
    //     request: "inventory",
    //     inventory: {
    //       type: "items",
    //       items: sendedData,
    //     },
    //   },
    //   (response) => {
    //     dispatch(
    //       setNotificationSnackBar({
    //         open: true, // Display notification snackbar based on response
    //         message: response?.message?.text,
    //         type: response?.message?.messageType,
    //       })
    //     );
    //   }
    // );
  };

  // Function to fetch context menu data from the server
  const getContextMenuData = (menu, itemId) => {
    setIsContextMenuLoading(true); // Set loading state to true
    // socketproject.emit(
    //   "automation:web:admin:usr:v1",
    //   {
    //     request: "inventory",
    //     inventory: {
    //       type: menu,
    //       [menu]: {
    //         action: "search",
    //         itemId: itemId,
    //       },
    //     },
    //   },
    //   (response) => {
    //     dispatch(setContextMenuItemsData(response?.data)); // Store context menu data in Redux store
    //     if (menu === "itemsFeature") {
    //       // Fetch UDC data for units and feature types
    //       getUDCApi(
    //         socketproject,
    //         "IV",
    //         "UT",
    //         dispatch,
    //         setUnitsList,
    //         setIsContextMenuLoading
    //       );
    //       getUDCApi(
    //         socketproject,
    //         "IV",
    //         "TP",
    //         dispatch,
    //         setFeatureTypeList,
    //         setIsContextMenuLoading
    //       );
    //     } else {
    //       setIsContextMenuLoading(false); // End loading if no further actions are needed
    //     }
    //   }
    // );
  };

  // Handle changes (add, update, delete) in the DataGrid
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      // Handle delete action
    } else if (args.action === "edit") {
      sendData(args?.data, "update"); // Handle update action
    } else if (args.action === "add") {
      sendData(args?.data, "add"); // Handle add action
    }
  };

  // Send context menu data (email/phone) to the server after changes
  const sendContextData = () => {
    const updatedData = changes.map((item) =>
      item?.action === "add"
        ? {
            action: item?.action,
            itemId: item?.itemId,
            featureType: featureTypeList?.data?.filter(
              (type) => type?.UVDESC === item?.featureType
            )?.[0]?.UVVALUE,
            featureDesc: item?.featureDesc,
            featureUnit: unitsList?.data?.filter(
              (unit) => unit?.UVDESC === item?.featureUnitDesc
            )?.[0]?.UVVALUE,
          }
        : item?.action === "update"
          ? {
              action: item?.action,
              itemId: item?.itemId,
              featureId: item?.featureId,
              featureType: featureTypeList?.data?.filter(
                (type) => type?.UVDESC === item?.featureType
              )?.[0]?.UVVALUE,
              featureDesc: item?.featureDesc,
              featureUnit: unitsList?.data?.filter(
                (unit) => unit?.UVDESC === item?.featureUnitDesc
              )?.[0]?.UVVALUE,
            }
          : item?.action === "delete" && {
              action: item?.action,
              itemId: item?.itemId,
              featureId: item?.featureId,
            }
    );

    const type =
      openContextMenu?.menu === "itemFeatures"
        ? "itemsFeature"
        : "updateItemCommunication";
    // socketproject.emit(
    //   "automation:web:admin:usr:v1",
    //   {
    //     request: "inventory",
    //     inventory: {
    //       type: type,
    //       [type]: updatedData,
    //     },
    //   },
    //   (response) => {
    //     dispatch(
    //       setNotificationSnackBar({
    //         open: true,
    //         message: response?.message?.text,
    //         type: response?.message?.messageType,
    //       })
    //     );
    //     handleClose(); // Close the context menu after sending the data
    //   }
    // );
  };

  return (
    <div className="grid-container">
      <SingleHierarchy
        gridData={itemsMasterData}
        ColumnDirective={ColumnDirective}
        onChanges={onChanges}
      />
      <MyAlertDialog
        isOpen={openContextMenu?.open}
        handleClose={handleClose}
        handleDataSend={sendContextData}
      />
      <ContextMenuItemsComponent
        open={openContextMenu?.open}
        menu={openContextMenu?.menu}
        data={openContextMenu?.data}
        getContextMenuData={getContextMenuData}
        isContextMenuLoading={isContextMenuLoading}
      />
    </div>
  );
};

export default ItemsMasterBody;
