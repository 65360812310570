// Function to make an API call through socket and update the state
const getUDCApi = (socket, type1, type2, dispatch, setState, setIsLoading) => {
  // Emit a socket event to fetch UDC (User Defined Config) data
  socket.emit(
    "automation:admin:web:usr:v1", // Event name for the socket
    {
      request: "udcData", // Type of request being made
      udcData: {
        type: "getUdc", // Specify the action we want, in this case, "getUdc"
        getUdc: {
          type1: type1, // First parameter for fetching UDC data
          type2: type2, // Second parameter for fetching UDC data
        },
      },
    },
    // Callback function to handle the response
    (response) => {
      // Dispatch the response data to update the state in Redux store
      dispatch(setState(response));

      // If a loading state function is provided, set loading to false
      if (setIsLoading) {
        setIsLoading(false);
      }

      // Log the response for debugging purposes
      console.log("response");
      console.log(response);
    }
  );
};

// Export the function for use in other parts of the app
export default getUDCApi;
