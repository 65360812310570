// Importing the necessary libraries and reducers
import { configureStore } from "@reduxjs/toolkit"; // For configuring the Redux store
import { apiSlice } from "../RTKQuery/apiSlice"; // The API slice for RTK Query
import authReducer from "./Reducers/authSliceReducer"; // Reducer for authentication-related actions
import addressBookReducer from "./Reducers/addressBookReducer"; // Reducer for address book actions
import inventoryReducer from "./Reducers/inventoryReducer"; // Reducer for inventory actions
import userFeaturesReducer from "./Reducers/userFeaturesReducer"; // Reducer for user features
import projectsSetupReducer from "./Reducers/projectsSetupReducer"; // Reducer for projects setup

// Configuring the Redux store with reducers and middleware
export const store = configureStore({
  reducer: {
    // Integrating API slice reducer to handle API calls
    [apiSlice.reducerPath]: apiSlice.reducer,

    // Adding custom reducers to the store
    auth: authReducer, // Authentication reducer
    addressBook: addressBookReducer, // Address book data reducer
    inventory: inventoryReducer, // Inventory data reducer
    userFeatures: userFeaturesReducer, // User features data reducer
    projectsSetup: projectsSetupReducer, // User features data reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignoring specific actions from the serializable check as they might contain non-serializable values like socket connections
        ignoredActions: [
          "auth/setClient", // Ignoring client set action
          "auth/setSocket", // Ignoring socket set action
          "userFeatures/setUpdatePageData", // Ignoring page data update action
          "userFeatures/setPagesDataChanges", // Ignoring page data changes action
        ],
        ignoredPaths: [
          "auth.client", // Ignoring client state in auth
          "auth.socket", // Ignoring socket state in auth
          "userFeatures.updatePageData", // Ignoring updatePageData in userFeatures state
          "userFeatures.pagesDataChanges", // Ignoring pagesDataChanges in userFeatures state
        ],
      },
    }).concat(apiSlice.middleware), // Adding the RTK Query API slice middleware to the store
  devTools: true, // Enabling Redux DevTools for development
});
