import "./index.css"; // Import styles
import * as React from "react"; // Import React
import { ListBoxComponent } from "@syncfusion/ej2-react-dropdowns"; // Import Syncfusion ListBox component

/**
 * DualListBox Component
 * A dual list box implementation using Syncfusion ListBoxComponent, allowing users to manage and transfer items between two groups.
 *
 * Props:
 * - data: Object containing two arrays (`groupa` and `groupb`) representing the data for both list boxes.
 * - titles: Array of strings representing titles for the two list boxes.
 * - onChanges: Callback function triggered when changes occur in the list box.
 */
const DualListBox = ({ data, titles, onChanges, onlyText }) => {
  // Destructure data for both list boxes
  const dataA = data.groupa; // Data for the first list box (Group A)
  const dataB = data.groupb; // Data for the second list box (Group B)

  // Field mappings for the ListBoxComponent
  const fields = {
    text: "text", // Field representing the display text
    description: "description", // Field for the description
    icon: "icon", // Field for the item's icon
  };

  // Toolbar settings for Group A list box (used for moving items)
  const toolbarSettingsA = {
    items: ["moveTo", "moveFrom", "moveAllTo", "moveAllFrom"], // Allow moving items to and from Group B
  };

  // Toolbar settings for Group B list box (optional, used for reordering items)
  const toolbarSettingsB = {
    items: ["moveUp", "moveDown"], // Allow moving items up or down
  };

  // Custom template to display when no records are available
  const noRecordsTemplate =
    '<div class="e-list-nrt"><span>NO DATA AVAILABLE</span></div>';

  // Handler for changes in Group B
  const handleGroupBChange = (e) => {
    onChanges(e); // Trigger the onChanges callback when items change
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <div className="dual-list-wrapper">
          {/* Group A ListBox */}
          <div className="dual-list-groupa">
            <h4>{titles[0]}</h4> {/* Title for Group A */}
            <ListBoxComponent
              dataSource={dataA} // Data source for Group A
              fields={fields} // Field mappings
              height="330px" // Set height for the list box
              scope="#combined-listbox" // Scope ID for related ListBox
              toolbarSettings={toolbarSettingsA} // Toolbar settings for item movement
              noRecordsTemplate={noRecordsTemplate} // Template for no records
              // Custom template for items in the list
              itemTemplate={
                !onlyText &&
                '<div class="list-wrapper"><span style="background-image: url(${icon});width: 50px; height: 50px;" class="image e-avatar e-avatar-xlarge e-avatar-circle"></span><span class="text">${text}</span><span class="description">${description}</span></div>'
              }
              actionComplete={handleGroupBChange} // Triggered when actions (like move) are completed
            />
          </div>

          {/* Group B ListBox */}
          <div className="dual-list-groupb">
            <h4>{titles[1]}</h4> {/* Title for Group B */}
            <ListBoxComponent
              id="combined-listbox" // ID for Group B ListBox
              dataSource={dataB} // Data source for Group B
              height="330px" // Set height for the list box
              fields={fields} // Field mappings
              noRecordsTemplate={noRecordsTemplate} // Template for no records
              // Uncomment the toolbar settings below to enable reordering
              // toolbarSettings={toolbarSettingsB}
              // Custom template for items in the list
              itemTemplate={
                !onlyText &&
                '<div class="list-wrapper"><span style="background-image: url(${icon}); width: 50px; height: 50px;" class="image e-avatar e-avatar-xlarge e-avatar-circle"></span><span class="text">${text}</span><span class="description">${description}</span></div>'
              }
              actionComplete={handleGroupBChange} // Triggered when actions (like move) are completed
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DualListBox;
