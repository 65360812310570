// Importing necessary dependencies and components
import React, { useEffect, useRef, useState } from "react"; // React hooks
import BasicTextFields from "../../Common/TextField"; // Custom TextField component
import BasicButtons from "../../Common/Button"; // Custom Button component
import { useNavigate } from "react-router-dom"; // Navigation hook for routing
import { CircularProgress } from "@mui/material"; // Circular loading spinner
import ArrowBackIcon from "@mui/icons-material/ArrowBack"; // Back arrow icon
import { usePasswordResetMutation } from "../../../Services/RTKQuery/auth/authApiSlice"; // RTK query mutation for password reset
import { useDispatch } from "react-redux"; // Redux dispatch to trigger actions
import { setNotificationSnackBar } from "../../../Services/Redux/Reducers/authSliceReducer"; // Action to show snack bar notifications
import ChangePasswordComponent from "./ChangePassword"; // Component for changing password after verification

// WrappedTextBox component, using forwardRef to pass ref to BasicTextFields
const WrappedTextBox = React.forwardRef((props, ref) => {
  return (
    <div>
      <BasicTextFields
        {...props}
        inputRef={(element) => {
          if (element && ref) {
            ref.current = element; // Forward ref to the input element
          }
        }}
      />
    </div>
  );
});

const ForgetPasswordComponent = () => {
  // State and refs initialization
  const [username, setUsername] = useState(""); // State for storing the entered username
  const userRef = useRef(); // Reference for focusing on the username input field
  const dispatch = useDispatch(); // Redux dispatch hook
  const navigate = useNavigate(); // React Router navigation hook
  const [passwordReset, { isLoading, isError, error }] =
    usePasswordResetMutation(); // RTK query mutation for password reset
  const [openCheckCode, setOpenCheckCode] = useState(false); // State to manage showing the next step (code verification)
  const [ID, setID] = useState(""); // State to store the request ID for password reset

  // Handle username input change
  const handleUserInput = (e) => setUsername(e.target.value);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Attempt to reset password using the entered username
    const reset = await passwordReset({
      username: username,
    }).unwrap();

    // If reset request is successful, show the next step (code verification)
    if (reset?.message?.type === "info") {
      setOpenCheckCode(true);
      setID(reset?.requestId); // Store request ID for the next steps
    } else {
      setUsername(""); // Reset username field if request failed
    }

    // Dispatch notification for success or failure
    dispatch(
      setNotificationSnackBar({
        open: true,
        message: reset?.message?.text,
        type: reset?.message?.type,
      })
    );
  };

  // Focus on the username input field when the component mounts
  useEffect(() => {
    if (userRef.current) {
      userRef.current.focus();
    }
  }, []);

  // Close the code verification step
  const onCloseCheck = () => {
    setOpenCheckCode(false);
  };

  return (
    <section
      className="login"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgb(222, 217, 238)", // Light background color for the section
      }}
    >
      {/* Left-side logo section */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "35%",
          backgroundColor: "rgb(175 175 175)", // Blue background for logo section
          height: "100%",
          alignItems: "center",
        }}
      >
        <div style={{ borderRadius: "7.5px" }}>
          <img
            src="/utilic-logo-removebg-preview.png"
            width={"100%"}
            alt="Logo"
          />{" "}
          {/* Logo image */}
        </div>
      </div>

      {/* Main content section */}
      {!isLoading && !openCheckCode && (
        <div
          style={{
            width: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "40%" }}>
            {/* Back button */}
            <div
              style={{
                backgroundColor: "black",
                width: "8%",
                borderRadius: "7.5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")} // Navigate back to home page
            >
              <ArrowBackIcon sx={{ height: "1.5em" }} />
            </div>

            {/* Heading and form for entering username */}
            <div
              style={{
                marginBottom: "5%",
                textAlign: "center",
                color: "black",
              }}
            >
              <h1>Forget Password</h1>
              <h6>Please enter your Username</h6>
            </div>

            <form onSubmit={handleSubmit}>
              <WrappedTextBox
                value={username}
                ref={userRef}
                id="username"
                onChange={handleUserInput}
                placeholder="Username"
                type="text"
                autoComplete="off" // Disable autocomplete for better security
              />
              <BasicButtons
                style={{ marginTop: "3%" }}
                text={
                  !isLoading ? (
                    "Send" // Display "Send" button text when not loading
                  ) : (
                    <CircularProgress
                      sx={{
                        color: "white",
                        width: "20px !important",
                        height: "20px !important",
                      }}
                    /> // Show loading spinner when loading
                  )
                }
                type="submit"
                disabled={username === ""} // Disable the button if username is empty
              />
            </form>
          </div>
        </div>
      )}

      {/* Show loading spinner when in loading state */}
      {isLoading && !openCheckCode && (
        <div
          style={{
            width: "75%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            sx={{
              color: "black",
              width: "200px !important",
              height: "200px !important",
            }}
          />
        </div>
      )}

      {/* Show ChangePasswordComponent after successful username input */}
      {openCheckCode && (
        <ChangePasswordComponent onCloseCheck={onCloseCheck} id={ID} />
      )}
    </section>
  );
};

export default ForgetPasswordComponent;
