// Import necessary components from MUI and other libraries
import { Box, Grid } from "@mui/material"; // Material-UI components
import React from "react"; // React library
import { useSelector } from "react-redux"; // Redux hook for accessing state
import SingleHierarchy from "../../../../../Common/DataGrid/singleGrid"; // Custom grid component

// CompanyIDPopUp component for displaying a list of companies in a grid format
const CompanyIDPopUp = ({ setCompanyIdRowSelected }) => {
  // Access company list from the Redux store
  const companyList = useSelector((state) => state?.addressBook?.companyList);

  // Column configuration for displaying company data
  const ColumnCompanyDirective = [
    {
      field: "UVVALUE", // Field representing the company value
      headerText: "Value", // Header text for the 'Value' column
      width: "100", // Column width
      isPrimaryKey: true, // Set UVVALUE as the primary key for the grid
      isIdentity: true, // ID is not editable
    },
    {
      field: "UVID", // Field representing the company ID
      headerText: "ID", // Header text for the 'ID' column
      width: "100", // Column width
      isPrimaryKey: true, // Set UVID as the primary key for the grid
      isIdentity: true, // ID is not editable
    },
    { field: "UVDESC", headerText: "Description", width: "200" }, // Description column
  ];

  return (
    <Box sx={{ marginTop: "3%" }}>
      {/* Render the grid only if companyList data exists */}
      {companyList?.data?.length > 0 && (
        <Grid container>
          {/* SingleHierarchy component is used to render the company data */}
          <SingleHierarchy
            columns={ColumnCompanyDirective} // Pass column configuration
            data={JSON.parse(JSON.stringify(companyList?.data))} // Clone the data to avoid mutations
            primaryKey={"UVVALUE"} // Set 'UVVALUE' as the primary key for the grid
            selection={"Single"} // Allow single selection in the grid
            contextMenu={false} // Disable the built-in context menu of the grid
            readOnly={true} // Make the grid read-only
            setRowSelected={setCompanyIdRowSelected} // Callback to handle row selection
          />
        </Grid>
      )}
    </Box>
  );
};

export default CompanyIDPopUp;
