import { Box, Grid } from "@mui/material";
import React from "react";
import BasicTextFields from "../../../../../../Common/TextField";
import SwitchLabels from "../../../../../../Common/SwicthLabels";

const ResetPasswordTemplate = ({ resetPassword, onChange }) => {
  return (
    <Box>
      <SwitchLabels
        label={"Auto Generate"}
        onChange={(e) => onChange(e?.target?.checked, "autoGenerate")}
      />
      {!resetPassword?.autoGenerate && (
        <Grid sx={{ marginY: "5%" }}>
          <BasicTextFields
            type="password"
            value={resetPassword?.newPass} // Setting the value from the filter state
            id="newPass" // Assigning a unique ID
            onChange={(e) => onChange(e?.target?.value, "newPass")} // Handling change for address number
            label="New Password" // Label for the input field
          />
        </Grid>
      )}
    </Box>
  );
};

export default ResetPasswordTemplate;
