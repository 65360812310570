// Import necessary components from MUI (Material-UI) and React
import { Box, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import BigBoxItems from "./BigBoxItems";
import BasicTextFields from "../../../../../../Common/TextField";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import BasicButtons from "../../../../../../Common/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setPagesDataChanges,
  setUpdatePageData,
} from "../../../../../../../Services/Redux/Reducers/userFeaturesReducer";
import AlertDialog from "../../../../../../Common/AlertDialog"; // Importing a custom AlertDialog component
import UpdateApps from "./UpdateAppPopUp";

// Define the DefaultComponent which receives props 'data' and 'path'
const DefaultUpdateComponent = ({ data }) => {
  const [focus, setFocus] = useState({
    data: {},
    sectionTitle: false,
  });
  const [isUpdatingApp, setIsUpdatingApp] = useState(false);
  const [updateApp, setUpdateApp] = useState({
    pageId: "",
    sectionId: "",
    appId: "",
    appDesc: "",
    appImgURL: "",
    appRoute: "",
    appTitle: "",
  });

  const updatePageData = useSelector(
    (state) => state?.userFeatures?.updatePageData
  );

  const pagesDataChanges = useSelector(
    (state) => state?.userFeatures?.pagesDataChanges
  );

  const dispatch = useDispatch();

  const onFocus = (e, item) => {
    setFocus({
      ...focus,
      data: e,
      [item]: true,
    });
  };

  const onBlur = (item) => {
    setFocus({
      ...focus,
      data: {},
      [item]: false,
    });
  };

  const TextFieldStyle = (e, item) => {
    return {
      height: "100% !important",
      ".css-j882ge-MuiInputBase-root-MuiOutlinedInput-root": {
        height: "100% !important",
      },
      ".custom-textfield, .MuiInputBase-input": {
        color: "white !important",
        fontWeight: "bold",
        fontSize: "1.4em",
      },
      ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
        border:
          !focus?.[item] || focus?.data !== e
            ? "none !important"
            : "1px solid white",
      },
    };
  };

  const addSection = () => {
    const newSection = {
      pageId: data?.pageId,
      sectionId: Math.random(),
      sectionTitle: "",
      action: "add",
      apps: [],
    };

    // Update `updatePageData.sections`
    const updatedSections = [...(updatePageData?.sections || []), newSection];

    // Retain only items with `action` and add the new section
    const updatedPagesDataChanges = {
      ...pagesDataChanges,
      sections: [
        ...(pagesDataChanges?.sections?.filter((section) => section.action) ||
          []),
        newSection,
      ],
    };

    dispatch(
      setUpdatePageData({
        ...updatePageData,
        sections: updatedSections,
      })
    );

    dispatch(setPagesDataChanges(updatedPagesDataChanges));
  };

  const handleChange = (e, id, item) => {
    const updatedValue = e?.target?.value;

    // Update focus state
    setFocus((prevFocus) => ({
      ...prevFocus,
      sectionTitle: true,
    }));

    const modifySections = (data) => {
      return data?.sections?.map((section) => {
        if (section.sectionId === id) {
          const existingAction = section.action === "add" ? "add" : "edit";
          return {
            ...section,
            action: existingAction,
            [item]: updatedValue,
          };
        }
        return section;
      });
    };

    // Find the updated section
    const updatedSection = modifySections(updatePageData).find(
      (section) => section.sectionId === id
    );

    // Update pagesDataChanges: if section exists with action 'none', change it to 'edit'
    const updatedPagesDataChanges = {
      ...pagesDataChanges,
      sections: [
        ...(pagesDataChanges?.sections
          ?.map((section) => {
            if (section.sectionId === id) {
              if (section.action === "none") {
                // Change action to 'edit' if it was 'none'
                return {
                  ...updatedSection,
                  action: "edit", // Update action to 'edit'
                };
              }
              return {
                ...updatedSection,
                action: section.action, // Preserve existing action
              };
            }
            return section;
          })
          ?.filter((section) => section.action) || []),
        !pagesDataChanges?.sections?.some((section) => section.sectionId === id)
          ? updatedSection
          : null, // Add updated section only if it doesn't already exist
      ].filter(Boolean),
    };

    // Dispatch to update `updatePageData`
    dispatch(
      setUpdatePageData({
        ...updatePageData,
        sections: modifySections(updatePageData),
      })
    );

    // Dispatch to update `pagesDataChanges`
    dispatch(setPagesDataChanges(updatedPagesDataChanges));
  };

  const deleteSection = (id) => {
    // If sectionId is between 0 and 1, handle it differently
    if (id > 0 && id < 1) {
      // Filter out the deleted section from `updatePageData.sections`
      const updatedSections = updatePageData?.sections?.filter(
        (section) => section.sectionId !== id
      );

      // Remove any sections with the same `sectionId` from `pagesDataChanges.sections`
      const updatedPagesDataChanges = {
        ...pagesDataChanges,
        sections: pagesDataChanges?.sections?.filter(
          (section) => section.sectionId !== id
        ),
      };

      // Dispatch the updated states
      dispatch(
        setUpdatePageData({
          ...updatePageData,
          sections: updatedSections,
        })
      );

      dispatch(setPagesDataChanges(updatedPagesDataChanges));
      return;
    }

    // If sectionId is not between 0 and 1, proceed with the normal deletion logic

    // Filter out the deleted section from `updatePageData.sections`
    const updatedSections = updatePageData?.sections?.filter(
      (section) => section.sectionId !== id
    );

    // Create a new change object for the deleted section
    const change = {
      pageId: updatePageData?.pageId, // Assuming `updatePageData` contains `pageId`
      sectionId: id,
      action: "delete",
    };

    // Update `pagesDataChanges` to replace any existing section with the same `sectionId`
    const updatedPagesDataChanges = {
      ...pagesDataChanges,
      sections: pagesDataChanges?.sections?.map((section) => {
        if (section.sectionId === id) {
          // If the section exists, replace it with the delete action
          return change;
        }
        return section;
      }) || [change], // If no existing section is found, just add the delete change
    };

    // Dispatch the updated states
    dispatch(
      setUpdatePageData({
        ...updatePageData,
        sections: updatedSections,
      })
    );

    dispatch(setPagesDataChanges(updatedPagesDataChanges));
  };

  const addApp = (sectionId) => {
    const newApp = {
      pageId: data?.pageId,
      sectionId: sectionId,
      appId: Math.random(),
      appDesc: "",
      appImgURL: "",
      appRoute: "",
      appTitle: "",
      action: "add",
    };

    const updated = (data) => {
      return data?.sections?.map((obj) => {
        if (obj?.sectionId === sectionId) {
          return {
            ...obj,
            apps: [...(obj?.apps || []), newApp],
          };
        }
        return obj;
      });
    };

    // Update `updatePageData.sections`
    const updatedUpdatePageData = {
      ...updatePageData,
      sections: updated(updatePageData),
    };

    // Check if the sectionId exists in `pagesDataChanges.sections`
    const sectionExistsInPagesDataChanges = pagesDataChanges?.sections?.some(
      (section) => section.sectionId === sectionId
    );

    let updatedPagesDataChanges = { ...pagesDataChanges };

    if (!sectionExistsInPagesDataChanges) {
      // If the sectionId doesn't exist, add the section from `updatePageData` to `pagesDataChanges`
      const sectionToAdd = updatePageData?.sections?.find(
        (section) => section.sectionId === sectionId
      );
      if (sectionToAdd) {
        // Add `action: 'none'` to the section when it doesn't exist in `pagesDataChanges`
        updatedPagesDataChanges.sections = [
          ...(updatedPagesDataChanges?.sections || []),
          {
            ...sectionToAdd,
            action: "none", // Add 'none' action if the section doesn't exist
          },
        ];
      }
    }

    // Add the new app to `pagesDataChanges` for the corresponding section
    updatedPagesDataChanges = {
      ...updatedPagesDataChanges,
      sections: updated(updatedPagesDataChanges),
    };

    // Dispatch the updated states
    dispatch(setUpdatePageData(updatedUpdatePageData));
    dispatch(setPagesDataChanges(updatedPagesDataChanges));
  };

  const onEditClick = (app) => {
    setIsUpdatingApp(true);
    setUpdateApp({
      pageId: data?.pageId,
      sectionId: app?.sectionId,
      appId: app?.appId,
      appDesc: app?.appDesc,
      appImgURL: app?.appImgURL,
      appRoute: app?.appRoute,
      appTitle: app?.appTitle,
    });
  };

  const handleClose = () => {
    setIsUpdatingApp(false);
    setUpdateApp({});
  };

  const updateApps = () => {
    // Update the `apps` array in `updatePageData.sections`
    const updatedSections = updatePageData.sections.map((section) => {
      if (section.sectionId === updateApp?.sectionId) {
        const updatedApps = section.apps.map((app) =>
          app.appId === updateApp?.appId ? updateApp : app
        );

        return {
          ...section,
          apps: updatedApps, // Keep apps for `updatePageData`
        };
      }
      return section;
    });

    // Check if the sectionId exists in `pagesDataChanges`
    const sectionExistsInPagesDataChanges = pagesDataChanges?.sections?.some(
      (section) => section.sectionId === updateApp?.sectionId
    );

    let updatedPagesDataChanges = { ...pagesDataChanges };

    if (!sectionExistsInPagesDataChanges) {
      // If the sectionId doesn't exist, add the section from `updatePageData` to `pagesDataChanges`
      const sectionToAdd = updatePageData?.sections?.find(
        (section) => section.sectionId === updateApp?.sectionId
      );
      if (sectionToAdd) {
        // Add `action: 'none'` when the section doesn't exist in `pagesDataChanges`
        updatedPagesDataChanges.sections = [
          ...(updatedPagesDataChanges?.sections || []),
          {
            ...sectionToAdd,
            action: "none", // Add 'none' action if the section doesn't exist
          },
        ];
      }
    }

    // Update the apps in `pagesDataChanges`
    updatedPagesDataChanges = {
      ...updatedPagesDataChanges,
      sections: updatedPagesDataChanges?.sections?.map((section) => {
        if (section.sectionId === updateApp?.sectionId) {
          const updatedApps = section.apps.map((app) => {
            if (app.appId === updateApp?.appId) {
              // Check the action and set appropriately
              const action = app.action === "add" ? "add" : "edit";
              return { ...updateApp, action };
            }
            return app;
          });

          return {
            ...section,
            apps: updatedApps, // Update apps for `pagesDataChanges`
          };
        }
        return section;
      }),
    };

    // Dispatch updated `updatePageData`
    dispatch(
      setUpdatePageData({ ...updatePageData, sections: updatedSections })
    );

    // Dispatch updated `pagesDataChanges`
    dispatch(setPagesDataChanges(updatedPagesDataChanges));

    // Close modal or editor
    handleClose();
  };

  return (
    <Box sx={{ marginTop: "2%" }}>
      {/* Check if the 'pageType' in 'data' is 'composite', then map over the 'pageSections' */}
      {data?.sections?.map((e) => (
        // Create a box for each section with some margin and a unique key
        <Box
          marginY={"1%"}
          key={e?.sectionId}
          sx={{ border: "1px dotted #bdbdbd", padding: "1%" }}
        >
          {/* Render a Grid to display the section title with custom styles */}
          <Grid
            sx={{
              backgroundColor: "#175779", // Dark blue background
              color: "white", // White text color
              height: "30px", // Set height of the title bar
              borderRadius: "7.5px", // Rounded corners
              marginBottom: "2%", // Space below the title
              paddingLeft: "3%", // Left padding
              display: "flex", // Flex display to center items
              alignItems: "center", // Vertically center content
              fontSize: "1.4em", // Font size for the section title
              justifyContent: "space-between",
            }}
          >
            <Grid container sx={{ height: "100%" }}>
              <Grid item sx={{ height: "100%" }}>
                {/* Display the section title */}
                <BasicTextFields
                  sx={() => TextFieldStyle(e, "sectionTitle")}
                  onFocus={() => onFocus(e, "sectionTitle")}
                  onBlur={() => onBlur("sectionTitle")}
                  value={e?.sectionTitle} // Setting the value from the filter state
                  id="sectionTitle" // Assigning a unique ID
                  onChange={(f) =>
                    handleChange(f, e?.sectionId, "sectionTitle")
                  } // Handling change for address number
                />
              </Grid>
              <Grid item sx={{ height: "100%" }}>
                <BasicButtons
                  sx={{
                    marginX: "1%",
                    cursor: "pointer",
                    backgroundColor: "transparent",
                    boxShadow: "none",
                  }}
                  text={<EditIcon />}
                  onClick={() => onFocus(e, "sectionTitle")}
                />
              </Grid>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                justifyContent: "right",
                marginRight: "2%",
              }}
            >
              <BasicButtons
                onClick={() => deleteSection(e?.sectionId)}
                sx={{
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  boxShadow: "none",
                }}
                text={<DeleteIcon />}
              />
            </Grid>
          </Grid>

          {/* Render a grid to display apps in the section */}
          <Grid
            sx={{
              display: "flex", // Flexbox for arranging the items
              justifyContent: "left", // Align items to the left
              width: "100%", // Full width of the container
              flexWrap: "wrap", // Wrap items to the next line if needed
            }}
          >
            {/* Map through each app (lBox) in the section */}
            {e?.apps?.map((lBox, index) => (
              // Tooltip to display additional info when hovering over an app
              <Tooltip
                key={lBox?.appId} // Unique key for each app
                slotProps={{
                  tooltip: {
                    sx: {
                      width: "11rem", // Set width of the tooltip
                      fontSize: "0.9rem", // Font size of tooltip text
                    },
                  },
                }}
                // Tooltip content displaying app details like Page ID and Name
                title={
                  <Grid container>
                    <Grid container>
                      <Grid item xs={5}>
                        Page ID:
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={6}>
                        {lBox?.appId}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={5}>
                        Name:
                      </Grid>
                      <Grid item xs={1}></Grid>
                      <Grid item xs={6}>
                        {lBox?.appRoute}
                      </Grid>
                    </Grid>
                  </Grid>
                }
                placement="bottom" // Position the tooltip below the app item
              >
                {/* Box for each app item with specified width and margin */}
                <Box
                  sx={{
                    width: "20%",
                    marginRight: "5%",
                    border: "1px dotted #bdbdbd",
                    padding: "1%",
                  }}
                >
                  {/* Render the BigBoxItems component for each app */}
                  <BigBoxItems
                    lBox={lBox}
                    focus={focus}
                    setFocus={setFocus}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    sectionId={e?.sectionId}
                    onEditClick={onEditClick}
                    pageId={data?.pageId}
                  />
                </Box>
              </Tooltip>
            ))}
            <Grid
              sx={{
                width: "20%",
                marginRight: "5%",
                border: "1px dotted #bdbdbd",
                // padding: "1%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BasicButtons
                boxheight={"100%"}
                sx={{
                  backgroundColor: "transparent",
                  color: "black",
                }}
                text={
                  <AddIcon
                    sx={{ width: "30%", height: "30%" }}
                    onClick={() => addApp(e?.sectionId)}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
      ))}
      {/* Render a Grid to display the section title with custom styles */}
      <Grid
        sx={{
          backgroundColor: "#175779", // Dark blue background
          color: "white", // White text color
          height: "30px", // Set height of the title bar
          borderRadius: "7.5px", // Rounded corners
          // marginBottom: "2%", // Space below the title
          // paddingLeft: "3%", // Left padding
          display: "flex", // Flex display to center items
          alignItems: "center", // Vertically center content
          fontSize: "1.4em", // Font size for the section title
          justifyContent: "space-between",
        }}
      >
        <BasicButtons
          boxheight={"100%"}
          sx={{
            backgroundColor: "transparent",
            color: "white",
          }}
          text={
            <AddIcon
              sx={{ width: "100%", height: "100%" }}
              onClick={addSection}
            />
          }
        />
      </Grid>
      {isUpdatingApp && (
        <AlertDialog
          open={isUpdatingApp}
          handleClose={handleClose} // Function to handle closing the popup
          title={"Update App"}
          content={
            <UpdateApps updateApp={updateApp} setUpdateApp={setUpdateApp} />
          } // Render Company ID popup content
          buttonTitle={"Update"}
          buttonClick={updateApps} // Placeholder button click handler
          width={"100%"}
        />
      )}
    </Box>
  );
};

export default DefaultUpdateComponent;
