import React, { useState } from "react"; // Import React and useState for state management
import DefaultUpdateComponent from "./BoxItems"; // Component to render the default update UI
import { Grid } from "@mui/material"; // Import Grid from Material-UI for layout
import BasicButtons from "../../../../../Common/Button"; // Common button component
import { useDispatch, useSelector } from "react-redux"; // Redux hooks for state and dispatch
import { setPagesDataChanges } from "../../../../../../Services/Redux/Reducers/userFeaturesReducer"; // Redux action to set page data changes
import MyAlertDialog from "../../../../../Common/MyAlertDialog"; // Custom alert dialog component
import { setNotificationSnackBar } from "../../../../../../Services/Redux/Reducers/authSliceReducer";

// Main component for handling graphical updates to pages
const GraphicalPagesUpdate = ({
  data, // Data for the update
  handleClose, // Function to close the update modal
  setOpenGraphicUpdate, // Function to update graphic update state
  onSearch, // Function to trigger search
}) => {
  const socket = useSelector((state) => state.auth.socket); // Access socket instance from Redux store
  const pagesDataChanges = useSelector(
    (state) => state?.userFeatures?.pagesDataChanges // Access pages data changes from Redux store
  );
  const dispatch = useDispatch(); // Initialize Redux dispatch
  const [openSureSave, setOpenSureSave] = useState(false); // State to manage the confirmation dialog visibility

  const menuPagesFilter = useSelector(
    (state) => state?.userFeatures?.menuPagesFilter
  );

  // Function to remove apps without an action and replace undefined values with an empty string
  const removeAppsWithoutAction = () => {
    const replaceUndefinedWithEmptyString = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => replaceUndefinedWithEmptyString(item)); // Recursively process arrays
      } else if (obj && typeof obj === "object") {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          acc[key] =
            value === undefined ? "" : replaceUndefinedWithEmptyString(value); // Replace undefined values with empty strings
          return acc;
        }, {});
      }
      return obj;
    };

    // Update the `pagesDataChanges` by removing apps without an `action` property
    const updatedPagesDataChanges = replaceUndefinedWithEmptyString({
      ...pagesDataChanges,
      sections: pagesDataChanges?.sections?.map((section) => {
        return {
          ...section,
          apps: section?.apps?.filter((app) => app?.action), // Keep only apps with an `action` property
        };
      }),
    });

    // Dispatch the updated `pagesDataChanges` to Redux
    dispatch(setPagesDataChanges(updatedPagesDataChanges));
  };

  // Function to save changes by sending the updated data via a socket event
  const saveChanges = () => {
    removeAppsWithoutAction(); // Clean the data before saving
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "pageUpdate", // Specify the type of update
          pageUpdate: {
            ...pagesDataChanges,
            action: "none",
            language: menuPagesFilter?.language,
          }, // Add the required action field
        },
      },
      (response) => {
        if (response?.responseStatus === "success") {
          setOpenGraphicUpdate({
            open: false, // Close the graphic update modal
            data: {},
          });
          onSearch(); // Trigger a search to refresh the data
        }
        dispatch(
          setNotificationSnackBar({
            open: true, // Open the notification snackbar
            message: response?.message?.text, // Display the response message
            type: response?.message?.messageType, // Set notification type
          })
        );
      }
    );
  };

  // Function to close the confirmation dialog
  const handleCloseSure = () => {
    setOpenSureSave(false);
  };

  return (
    <Grid sx={{ padding: "3%" }}>
      {" "}
      {/* Main container with padding */}
      <Grid container sx={{ display: "flex", justifyContent: "right" }}>
        <Grid item width={"8%"}>
          <BasicButtons
            text={"Save Changes"} // Button to save changes
            onClick={() => setOpenSureSave(true)} // Open confirmation dialog on click
          />
        </Grid>
        <Grid item width={"2%"}></Grid> {/* Spacer for alignment */}
        <Grid item width={"8%"}>
          <BasicButtons
            text={"Cancel"} // Button to cancel and close the modal
            sx={{ backgroundColor: "#9b9a9a" }} // Custom button style
            onClick={handleClose} // Trigger close function
          />
        </Grid>
      </Grid>
      <DefaultUpdateComponent data={data} />{" "}
      {/* Render the main update component */}
      {openSureSave && ( // Conditional rendering for confirmation dialog
        <MyAlertDialog
          open={openSureSave}
          handleClose={handleCloseSure} // Close the dialog on cancel
          title={"Are you sure?"} // Dialog title
          buttonTitle={"Save"} // Save button title
          buttonClick={() => {
            saveChanges(); // Save changes on confirmation
            setOpenSureSave(false); // Close the dialog
          }}
        />
      )}
    </Grid>
  );
};

export default GraphicalPagesUpdate; // Export the component for reuse
