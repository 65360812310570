// Importing necessary modules from Redux Toolkit and universal-cookie
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";

const cookies = new Cookies(); // Cookies instance for managing cookies

// Define the initial state of the authentication slice
const addressBookSliceInitialState = {
  // Retrieve userAll from cookies if available, otherwise initialize as an empty object
  masterInfoFilter: {
    addressNb: "",
    fullName: "",
    addressType: "",
  },
  masterInfoData: [],
  contextMenuData: [],
  companyList: [],
  companyIDSelected: "",
  phoneNbTypes: [],
  usersFilter: {
    fullName: "",
    username: "",
    addressNb: "",
    userMenus: [],
    search: "",
  },
  usersMenusData: cookies.get("_usersMenusData")
    ? cookies.get("_usersMenusData")
    : [],
  usersData: [],
  usersAddressNbData: [],
  addressNbUserSelected: "",
  userMenusData: [],
};

// Create a slice for authentication using Redux Toolkit's createSlice function
const addressBookSlice = createSlice({
  name: "addressBook", // Name of the slice
  initialState: addressBookSliceInitialState, // Initial state defined above

  // Define the reducers (functions to update the state)
  reducers: {
    // Set user credentials in the state and cookies
    setMasterInfoFilter: (state, action) => {
      // Update the state with the new user information
      state.masterInfoFilter = action.payload;
    },
    setMasterInfoData: (state, action) => {
      // Update the state with the new user information
      state.masterInfoData = action.payload;
    },
    setContextMenuData: (state, action) => {
      // Update the state with the new user information
      state.contextMenuData = action.payload;
    },
    setCompanyList: (state, action) => {
      // Update the state with the new user information
      state.companyList = action.payload;
    },
    setCompanyIDSelected: (state, action) => {
      // Update the state with the new user information
      state.companyIDSelected = action.payload;
    },
    setPhoneNbTypes: (state, action) => {
      // Update the state with the new user information
      state.phoneNbTypes = action.payload;
    },
    setUsersFilter: (state, action) => {
      // Update the state with the new user information
      state.usersFilter = action.payload;
    },
    setUsersMenusData: (state, action) => {
      // Update the state with the new user information
      state.usersMenusData = action.payload;
    },
    setUsersData: (state, action) => {
      // Update the state with the new user information
      state.usersData = action.payload;
    },
    setUsersAddressNbData: (state, action) => {
      // Update the state with the new user information
      state.usersAddressNbData = action.payload;
    },
    setAddressNbUserSelected: (state, action) => {
      // Update the state with the new user information
      state.addressNbUserSelected = action.payload;
    },
    setUserMenusData: (state, action) => {
      // Update the state with the new user information
      state.userMenusData = action.payload;
    },
  },
});

// Export the actions to be used in other parts of the application
export const {
  setMasterInfoFilter,
  setMasterInfoData,
  setContextMenuData,
  setCompanyList,
  setCompanyIDSelected,
  setPhoneNbTypes,
  setUsersFilter,
  setUsersMenusData,
  setUsersData,
  setUsersAddressNbData,
  setAddressNbUserSelected,
  setUserMenusData,
} = addressBookSlice.actions;

// Export the reducer to be used in the store configuration
export default addressBookSlice.reducer;
