import { Box, Grid } from "@mui/material"; // Importing Box and Grid components from MUI for layout
import React from "react"; // Importing React
import BasicTextFields from "../../../../Common/TextField"; // Importing custom BasicTextFields component for input fields
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import {
  setUsersFilter, // Redux action to set users filter
} from "../../../../../Services/Redux/Reducers/addressBookReducer";
import BasicButtons from "../../../../Common/Button"; // Importing custom BasicButtons component for button
import MultiSelect from "../../../../Common/MultipleSelect";

const UsersFilterReturn = ({ onSearch }) => {
  // Selecting the master info filter state from the Redux store
  const usersFilter = useSelector((state) => state?.addressBook?.usersFilter);
  const usersMenusData = useSelector(
    (state) => state?.addressBook?.usersMenusData
  )?.menus;
  const dispatch = useDispatch(); // Initializing Redux dispatch

  // Function to handle input changes and update the filter in the Redux store
  const handleChange = (selectedValues, item) => {
    if (item === "userMenus") {
      if (selectedValues.includes("all")) {
        dispatch(
          setUsersFilter({
            ...usersFilter,
            [item]: usersMenusData.map((menu) => menu.menuId), // Select all available menu IDs
          })
        );
      } else {
        dispatch(
          setUsersFilter({
            ...usersFilter,
            [item]: selectedValues, // Update only selected values
          })
        );
      }
    } else {
      dispatch(
        setUsersFilter({
          ...usersFilter,
          [item]: selectedValues?.target?.value, // Update other fields normally
        })
      );
    }
  };

  const menusStructure = () => {
    const structure = usersMenusData?.map((obj) => ({
      title: obj?.menuDesc,
      value: obj?.menuId,
    }));
    return structure;
  };

  return (
    <Box>
      <Grid container>
        {" "}
        {/* Grid container for layout */}
        {/* Customer Name input field */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={usersFilter?.fullName} // Setting the value from the filter state
            id="fullName" // Assigning a unique ID
            onChange={(e) => handleChange(e, "fullName")} // Handling change for customer name
            label="Full Name" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Username input field */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={usersFilter?.username} // Setting the value from the filter state
            id="username" // Assigning a unique ID
            onChange={(e) => handleChange(e, "username")} // Handling change for username
            label="Username" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}{" "}
        {/* Address Nb input field */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={usersFilter?.addressNb} // Setting the value from the filter state
            id="addressNb" // Assigning a unique ID
            onChange={(e) => handleChange(e, "addressNb")} // Handling change for addressNb
            label="Address Nb" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Address type dropdown field */}
        <Grid sx={{ width: "13.5%" }}>
          <MultiSelect
            label={"User Menus"} // Label for the dropdown
            value={usersFilter?.userMenus ? usersFilter?.userMenus : []} // Setting the value from the filter state
            onChange={(e) => handleChange(e, "userMenus")} // Handling change for address type
            data={menusStructure()} // Data source for the dropdown
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}{" "}
        {/* Search input field */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={usersFilter?.search} // Setting the value from the filter state
            id="search" // Assigning a unique ID
            onChange={(e) => handleChange(e, "search")} // Handling change for search
            label="Search" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Search button */}
        <Grid sx={{ width: "8%", display: "flex" }}>
          <BasicButtons text={"Search"} onClick={onSearch} />{" "}
          {/* Button to trigger the search */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default UsersFilterReturn; // Exporting the component as default
