import { Box, Grid } from "@mui/material"; // Import Material-UI components for layout and structure
import React from "react"; // Import React
import Card from "@mui/material/Card"; // Import Material-UI Card component
import CardContent from "@mui/material/CardContent"; // Import Material-UI CardContent component
import Typography from "@mui/material/Typography"; // Import Material-UI Typography component

// BoxItem is a reusable functional component to display a card with an image, title, and description
const BoxItem = (props) => {
  return (
    <Card
      sx={{
        display: "flex", // Use flexbox for layout
        backgroundColor: "#c4d0d6", // Light grey background color for the card
        width: "100%", // Full width
        height: "100%", // Full height
        color: "#175779", // Blue text color
      }}
    >
      {/* Grid container for layout */}
      <Grid container>
        {/* Image section (left part of the card) */}
        <Grid
          item
          xs={3} // Take up 3/12 columns of the grid
          sx={{
            display: "flex", // Use flexbox
            justifyContent: "center", // Center the image horizontally
            alignItems: "center", // Center the image vertically
          }}
        >
          {/* Render the image if the image prop is not "null" */}
          {props.image !== "null" && (
            <img
              alt="" // Empty alt for decorative image
              src={props.image} // Source of the image from props
              style={{ width: "50%" }} // Set image width to 50% of the container
            />
          )}
        </Grid>

        {/* Text content section (right part of the card) */}
        <Grid
          item
          xs={9} // Take up 9/12 columns of the grid
          sx={{
            display: "flex", // Use flexbox
            alignItems: "center", // Vertically center the text
          }}
        >
          {/* Box container for the title and description */}
          <Box
            sx={{
              display: "flex", // Use flexbox
              flexDirection: "column", // Arrange children in a column
              textAlign: "left", // Align text to the left
            }}
          >
            {/* Card content section */}
            <CardContent
              sx={{
                flex: "1 0 auto", // Flex-grow to fill available space
                paddingLeft: "0px", // Remove left padding
              }}
            >
              {/* Title */}
              <Typography
                component="div"
                sx={{
                  fontWeight: "bold", // Bold text for title
                  fontSize: "1.6rem", // Larger font size
                }}
              >
                {props?.title} {/* Render the title passed as a prop */}
              </Typography>

              {/* Description */}
              <Typography
                variant="h6" // Use Material-UI h6 variant
                color="text.secondary" // Default secondary text color
                component="div"
                sx={{ color: "#175779" }} // Override color to match the card theme
              >
                {props?.description}{" "}
                {/* Render the description passed as a prop */}
              </Typography>
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default BoxItem; // Export the BoxItem component for use in other parts of the application
