// Importing Material-UI components for layout and loading indicator
import { Box, CircularProgress, Grid } from "@mui/material";
// Importing React and the useState hook
import React, { useState } from "react";
// Importing custom components for filtering and body content
import FilterComponent from "../../../Common/Filter";
import ItemsInfoFilterReturn from "./FilterReturn";
import ItemsMasterBody from "./Body";
// Importing useSelector to access Redux state
import { useSelector } from "react-redux";

// ItemsMasterComponent is a functional component that renders the items data with filters and loading states
const ItemsMasterComponent = () => {
  // State to manage the loading status of data
  const [isLoading, setIsLoading] = useState(false);

  // Selecting the 'itemsMasterData' from Redux store (inventory state)
  const itemsMasterData = useSelector(
    (state) => state?.inventory?.itemsMasterData
  );

  // Selecting 'brandList' from Redux store (inventory state)
  const brandList = useSelector((state) => state?.inventory?.brandList);

  // Transforming 'brandList' to match expected format for rendering
  const brands = brandList?.data?.map((list) => {
    return {
      id: Math.random(), // Generating a random id for each brand
      brandDesc: list?.UVDESC, // Mapping brand description
    };
  });

  return (
    <Box sx={{ color: "black" }}>
      {/* Main container with text color set to black */}
      <Grid sx={{ backgroundColor: "rgb(226, 226, 226)" }}>
        {/* Rendering FilterComponent, passing ItemsInfoFilterReturn with loading state handler */}
        <FilterComponent
          children={<ItemsInfoFilterReturn setIsLoading={setIsLoading} />}
        />
        {/* Rendering body content only if not loading and data exists */}
        {!isLoading && itemsMasterData?.length > 0 && (
          <ItemsMasterBody brands={brands} />
        )}
      </Grid>
      {/* Loading spinner shown when data is being loaded */}
      {isLoading && (
        <div
          style={{
            width: "100%", // Full width
            display: "flex", // Flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          {/* Circular progress spinner displayed while loading */}
          <CircularProgress
            sx={{
              color: "#1976d2", // Spinner color set to blue
              width: "100px !important", // Spinner width
              height: "100px !important", // Spinner height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default ItemsMasterComponent; // Exporting the component for use in other parts of the application
