import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

export default function SwitchLabels({ label, onChange }) {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch defaultChecked onChange={onChange} />}
        label={label}
        labelPlacement="start"
        sx={{
          display: "flex",
          justifyContent: "left",
          ".css-k06gjd-MuiFormControlLabel-root, .MuiFormControlLabel-label": {
            fontSize: "medium",
          },
        }}
      />
    </FormGroup>
  );
}
