import React, { useState } from "react";
import FilterComponent from "../../../Common/Filter";
import { Box, CircularProgress, Grid } from "@mui/material";
import { setNotificationSnackBar } from "../../../../Services/Redux/Reducers/authSliceReducer";
import { useDispatch, useSelector } from "react-redux";
import FeaturesSetupFilterReturn from "./FilterReturn";
import { setFeaturesSetupData } from "../../../../Services/Redux/Reducers/projectsSetupReducer";
import FeaturesSetupBody from "./Body";

const FeaturesSetupComponent = () => {
  const socket = useSelector((state) => state.auth.socket); // Accessing socket instance from Redux store
  const [isLoading, setIsLoading] = useState(false); // State to manage the loading spinner
  const featuresSetupFilter = useSelector(
    (state) => state?.projectsSetup?.featuresSetupFilter // Accessing filter state from Redux store
  );
  const dispatch = useDispatch(); // Initializing Redux dispatch

  // Function to handle search logic by sending a socket event
  const onSearch = () => {
    setIsLoading(true); // Show loading spinner while search is in progress
    socket.emit(
      "automation:project:web:admin:usr:v1", // Socket event name
      {
        request: "mainSetup", // API request type
        mainSetup: {
          type: "getFeatures", // Specify the type of data being fetched
          getFeatures: {
            searchTxt: featuresSetupFilter?.searchTxt, // Pass search text from filter
          },
        },
      },
      (response) => {
        if (response?.responseStatus === "success") {
          // If the server response is successful, update the Redux store
          dispatch(setFeaturesSetupData(response?.data));
        } else {
          // If the server response indicates failure, show a notification
          dispatch(
            setNotificationSnackBar({
              open: true, // Open the notification snackbar
              message: response?.message?.text, // Display the response message
              type: response?.message?.messageType, // Set notification type
            })
          );
        }
        setIsLoading(false); // Hide loading spinner after search completes
      }
    );
  };

  return (
    <Box sx={{ color: "black" }}>
      {/* Main container with black text */}
      <Grid sx={{ backgroundColor: "rgb(226, 226, 226)" }}>
        {/* Background color for the filter and body */}
        <FilterComponent
          children={<FeaturesSetupFilterReturn onSearch={onSearch} />}
        />
        {!isLoading && (
          <FeaturesSetupBody
            onSearch={onSearch} // Trigger the search function
          />
        )}
      </Grid>
      {isLoading && (
        <div
          style={{
            width: "100%", // Full width of the parent container
            display: "flex", // Use flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <CircularProgress
            sx={{
              color: "#1976d2", // Set spinner color to Material-UI primary blue
              width: "100px !important", // Override default width
              height: "100px !important", // Override default height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default FeaturesSetupComponent;
