import * as React from "react"; // Importing React
import Box from "@mui/material/Box"; // Import Box component from Material UI for layout
import InputLabel from "@mui/material/InputLabel"; // Import InputLabel from Material UI for the label
import MenuItem from "@mui/material/MenuItem"; // Import MenuItem from Material UI to define each option in the Select
import FormControl from "@mui/material/FormControl"; // Import FormControl from Material UI to wrap the Select component
import Select from "@mui/material/Select"; // Import Select from Material UI to create a dropdown/select input

/**
 * BasicSelect
 * A reusable select input component built using Material UI's Select and FormControl components.
 * It displays a dropdown of options and allows for user selection.
 *
 * Props:
 * - label: The label text displayed for the select input.
 * - value: The current selected value, managed externally (e.g., from parent component state).
 * - onChange: A callback function triggered when the selected value changes.
 * - data: An array of options to display in the dropdown, each containing a 'value' and 'title'.
 */
export default function BasicSelect({ label, value, onChange, data = [] }) {
  return (
    <Box sx={{ minWidth: 120 }}>
      {" "}
      {/* Box component to control the minimum width of the select container */}
      <FormControl
        fullWidth // Ensures the FormControl takes up full width available
        sx={{
          ".css-1q964xs-MuiFormLabel-root-MuiInputLabel-root": {
            color: "#1976d2", // Set the color of the label to blue
            fontWeight: "bold", // Make the label text bold
          },
        }}
      >
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>{" "}
        {/* Input label for the select */}
        <Select
          labelId={`${label}-select-label`} // Associates the label with the Select component
          id={`${label}-select`} // Unique ID for the select component
          value={value || ""} // Default value for the Select. If value is undefined or null, set it to an empty string
          label={label} // Display the label for the select
          onChange={onChange} // Callback function when the selection changes
        >
          {/* Conditionally render MenuItems based on the data prop */}
          {data.length > 0 ? (
            data.map((item) => (
              <MenuItem key={item?.value} value={item?.value}>
                {" "}
                {/* Render each option in the dropdown */}
                {item?.title} {/* Display the title for each option */}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">
              {" "}
              {/* Display this when no options are available */}
              <em>No Options Available</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
}
