import React, { useState, useRef } from "react";
import {
  GridComponent, // Syncfusion Grid component
  ColumnsDirective, // Defines a collection of grid columns
  ColumnDirective, // Represents a single column in the grid
  Inject, // Used to inject services into the grid
  DetailRow, // Service for expanding rows to show details
  Page, // Service for enabling pagination
  Sort, // Service for sorting
  Filter, // Service for filtering
  Toolbar, // Service for adding toolbar options
  Edit, // Service for editing
  Selection, // Service for row selection
  PdfExport, // Service for exporting to PDF
  ExcelExport, // Service for exporting to Excel
  ColumnMenu, // Service for showing column menu
  ContextMenu, // Service for context menu
  Resize, // Service for column resizing
} from "@syncfusion/ej2-react-grids";
import "./singleGrid.css"; // Import custom CSS for styling
import "font-awesome/css/font-awesome.min.css"; // FontAwesome for icons

// SingleHierarchy component handles a Syncfusion hierarchical grid with extensive customization
function SingleHierarchy({
  columns, // Column configuration for the grid
  data, // Data source for the grid
  primaryKey, // Primary key field
  selection, // Selection type (e.g., Single, Multiple)
  onChanges, // Callback for changes in the grid
  contextMenu, // Boolean to enable/disable context menu
  readOnly, // Boolean to set grid to read-only mode
  setRowSelected, // Callback for row selection
  contextMenuItems, // Items in the context menu
  contextMenuClick, // Callback for context menu click
  onDelete, // Callback for row deletion
  handleClose, // Callback for closing actions
  required, // Boolean to validate required fields
  mode, // Mode to customize toolbar options
  template, // Template for custom dialogs
  style, // Additional styles for dialogs
  myRequiredFields, // Object containing changes for validation
}) {
  const [gridData, setGridData] = useState([...data]); // State to manage grid data
  const gridInstance = useRef(null); // Reference to the Grid component

  const filterSettings = { type: "Excel" }; // Filter configuration

  // Configure toolbar options based on mode and readOnly flag
  const toolbarOptions = (readOnly && []) ||
    (mode === "noPDF" && [
      { id: "Add", tooltipText: "Add", prefixIcon: "e-add" },
      { id: "Edit", tooltipText: "Edit", prefixIcon: "e-edit" },
      { id: "Delete", tooltipText: "Delete", prefixIcon: "e-delete" },
      {
        id: "ExcelExport",
        tooltipText: "Excel Export",
        prefixIcon: "e-excelexport",
      },
    ]) ||
    (mode === "noEdit" && [
      { id: "Add", tooltipText: "Add", prefixIcon: "e-add" },
      { id: "Delete", tooltipText: "Delete", prefixIcon: "e-delete" },
      { id: "PdfExport", tooltipText: "PDF Export", prefixIcon: "e-pdfexport" },
      {
        id: "ExcelExport",
        tooltipText: "Excel Export",
        prefixIcon: "e-excelexport",
      },
    ]) || [
      { id: "Add", tooltipText: "Add", prefixIcon: "e-add" },
      { id: "Edit", tooltipText: "Edit", prefixIcon: "e-edit" },
      { id: "Delete", tooltipText: "Delete", prefixIcon: "e-delete" },
      { id: "PdfExport", tooltipText: "PDF Export", prefixIcon: "e-pdfexport" },
      {
        id: "ExcelExport",
        tooltipText: "Excel Export",
        prefixIcon: "e-excelexport",
      },
    ];

  // Edit settings for the grid
  const editSettings = {
    allowDeleting: !readOnly, // Enable/disable deleting
    allowEditing: !readOnly && mode !== "noEdit", // Enable/disable editing
    allowAdding: !readOnly, // Enable/disable adding
    mode: "Dialog", // Editing mode is Dialog
    template: template && ((props) => template?.(props, gridInstance)), // Custom dialog template
  };

  const pageSettings = { pageCount: 5 }; // Pagination settings

  // Selection settings for the grid
  const selectionSettings = {
    type: selection, // Selection type (e.g., Single, Multiple)
    checkboxMode: "ResetOnRowClick", // Reset checkboxes on row click
    persistSelection: true, // Maintain selection state
  };

  // Handles actions like deleting and saving
  const actionBegin = (args) => {
    if (args.requestType === "delete") {
      const shouldCancel = window.confirm(
        "Are you sure you want to delete this row?"
      );
      if (!shouldCancel) {
        args.cancel = true;
        return;
      }
      const updatedData = gridData.filter(
        (row) => !args.data.some((item) => item[primaryKey] === row[primaryKey])
      );
      setGridData(updatedData); // Update grid data after deletion
      onDelete?.(); // Invoke onDelete callback
      handleClose?.(); // Invoke handleClose callback
    }

    // Validation for required fields
    if (required && args.requestType === "save") {
      myRequiredFields(args);
    }
  };

  // Handles actions like completing edits or updates
  const actionComplete = (args) => {
    onChanges?.(args, gridInstance); // Notify parent about changes
    if (
      style &&
      (args.requestType === "add" || args.requestType === "beginEdit")
    ) {
      const dialog = args.dialog;
      dialog.height = "auto";
      dialog.width = "30%"; // Adjust dialog width
    }
    if (style === "usermenu" && args.requestType === "beginEdit") {
      const dialog = args.dialog;
      dialog.height = "auto";
      dialog.width = "50%"; // Adjust width for user menu dialogs
    }
    if (args.requestType === "save" || args.requestType === "delete") {
      gridInstance.current?.refresh(); // Refresh grid after save or delete
    }
  };

  // Handles context menu click events
  const contextMenuClicking = (args) => {
    contextMenuClick?.(args, gridInstance); // Notify parent about context menu action
  };

  // Handles row selection
  const rowSelected = (args) => {
    setRowSelected?.(args?.data); // Notify parent about selected row
  };

  // Handles toolbar button clicks
  const toolbarClick = (args) => {
    if (!gridInstance.current) return;

    switch (args.item.id) {
      case "Add":
        gridInstance.current.addRecord(); // Add a new record
        break;
      case "Edit":
        if (gridInstance.current.getSelectedRecords().length > 0) {
          gridInstance.current.startEdit(); // Start editing the selected record
        } else {
          alert("Please select a row to edit.");
        }
        break;
      case "Delete":
        if (gridInstance.current.getSelectedRecords().length > 0) {
          gridInstance.current.deleteRecord(); // Delete the selected record
        } else {
          alert("Please select a row to delete.");
        }
        break;
      case "ExcelExport":
        gridInstance.current.excelExport({ hierarchyExportMode: "All" }); // Export to Excel
        break;
      case "PdfExport":
        gridInstance.current.pdfExport({ hierarchyExportMode: "All" }); // Export to PDF
        break;
      default:
        break;
    }
  };

  return (
    <div className="control-pane" style={{ position: "relative" }}>
      <div className="control-section">
        {/* GridComponent renders the Syncfusion grid */}
        <GridComponent
          dataSource={gridData} // Data source for the grid
          id="MasterDetailsExport" // Unique ID for the grid
          ref={gridInstance} // Reference to the grid instance
          allowSorting={true} // Enable sorting
          showColumnMenu={true} // Show column menu
          allowFiltering={true} // Enable filtering
          filterSettings={filterSettings} // Apply filter settings
          toolbar={!readOnly ? toolbarOptions : []} // Configure toolbar based on read-only state
          allowPaging={true} // Enable pagination
          editSettings={editSettings} // Apply edit settings
          pageSettings={pageSettings} // Apply pagination settings
          selectionSettings={selectionSettings} // Apply selection settings
          allowPdfExport={!readOnly} // Enable/disable PDF export
          allowExcelExport={!readOnly} // Enable/disable Excel export
          actionBegin={actionBegin} // Handle actions like save or delete
          actionComplete={actionComplete} // Handle actions after save or delete
          contextMenuItems={contextMenu && contextMenuItems} // Configure context menu
          contextMenuClick={contextMenu && contextMenuClicking} // Handle context menu clicks
          rowSelected={rowSelected} // Handle row selection
          toolbarClick={toolbarClick} // Handle toolbar clicks
        >
          {/* Define grid columns */}
          <ColumnsDirective>
            {selection !== "Single" && (
              <ColumnDirective type="checkbox" width="50" /> // Checkbox for multi-select
            )}
            {columns?.map((cd, i) => (
              <ColumnDirective
                key={i} // Unique key for each column
                field={cd?.field} // Data field for the column
                headerText={cd?.headerText} // Column header text
                width={cd?.width || "100"} // Column width
                isPrimaryKey={cd?.isPrimaryKey} // Specify if the column is the primary key
                allowEditing={cd?.allowEditing ?? !cd?.isPrimaryKey} // Enable/disable editing
                editType={cd?.editType || "text"} // Type of editing (e.g., text, dropdown)
                editTemplate={cd?.editTemplate} // Custom template for editing
                visible={cd?.visible && cd?.visible} // Control visibility
              />
            ))}
          </ColumnsDirective>
          {/* Inject services for additional features */}
          {!readOnly && (
            <Inject
              services={[
                DetailRow, // For row details
                Page, // For pagination
                Sort, // For sorting
                Filter, // For filtering
                Toolbar, // For toolbar
                Edit, // For editing
                Selection, // For selection
                PdfExport, // For exporting to PDF
                ExcelExport, // For exporting to Excel
                ColumnMenu, // For column menu
                ContextMenu, // For context menu
                Resize, // For resizing columns
              ]}
            />
          )}
        </GridComponent>
      </div>
    </div>
  );
}

export default SingleHierarchy; // Export the component for use in other parts of the application
