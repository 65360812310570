import React, { useState } from "react"; // Importing React and useState for managing component state
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid"; // Importing a custom SingleHierarchy DataGrid component
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import "../../../../Common/DataGrid/singleGrid.css"; // Importing custom CSS for the SingleHierarchy DataGrid
import DialogCompanyTemplate from "./DialogCompanyTemplate"; // Importing DialogCompanyTemplate component for Company ID editing
import CompanyIDPopUp from "./CompanyIDPopUp"; // Importing a popup component for selecting Company ID
import AlertDialog from "../../../../Common/AlertDialog"; // Importing a custom AlertDialog component
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer"; // Importing Redux action to set a notification
import {
  setCompanyIDSelected,
  setCompanyList,
  setContextMenuData,
  setPhoneNbTypes,
} from "../../../../../Services/Redux/Reducers/addressBookReducer"; // Importing Redux action to set context menu data
import ContextMenuComponent from "./ContextMenu"; // Importing a custom context menu component
import MyAlertDialog from "../../../../Common/MyAlertDialog"; // Importing a custom alert dialog
import getUDCApi from "../../../../../Services/Common/UDCAPI";
import { DropDownList } from "@syncfusion/ej2-dropdowns";

const MasterInfoBody = () => {
  const socket = useSelector((state) => state?.auth?.socket); // Accessing socket from the Redux store
  const masterInfoData = useSelector(
    (state) => state?.addressBook?.masterInfoData // Accessing masterInfoData from the Redux store
  )?.map((data) => {
    // Mapping addressType values to readable strings (Employee or Customer)
    if (data?.addressType === "E") {
      return { ...data, addressType: "Employee" };
    } else {
      return { ...data, addressType: "Customer" };
    }
  });

  const [isContextMenuLoading, setIsContextMenuLoading] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState({
    open: false,
    data: [],
    action: "delete",
  });

  const phoneNbTypes = useSelector((state) => state?.addressBook?.phoneNbTypes);

  const phoneTypes = phoneNbTypes?.data?.map((type) => {
    return {
      id: Math.random(),
      phoneType: type?.UVDESC,
    };
  });

  const companyIDSelected = useSelector(
    (state) => state?.addressBook?.companyIDSelected // Accessing masterInfoData from the Redux store
  );

  const dispatch = useDispatch(); // Initializing Redux dispatch

  // State for managing changes in the data grid
  const [changes, setChanges] = useState([]);
  const [companyIdRowSelected, setCompanyIdRowSelected] = useState({});

  // State for controlling the Company ID popup visibility
  const [openCompanyID, setOpenCompanyID] = useState(false);

  // State for controlling context menu visibility and data
  const [openContextMenu, setOpenContextMenu] = useState({
    menu: "",
    open: false,
    data: {},
  });

  // Function to open the Company ID popup
  const openCompanyIDPopUp = () => {
    setOpenCompanyID(true);
    getUDCApi(socket, "FA", "CM", dispatch, setCompanyList);
  };

  // Function to open context menu with selected menu and data
  const openContextMenuPopUp = (menu, data) => {
    setOpenContextMenu({
      menu: menu,
      open: true,
      data: data,
    });
  };

  // Function to handle closing popups and resetting states
  const handleClose = () => {
    setOpenCompanyID(false);
    setChanges([]);
    dispatch(setContextMenuData([])); // Clear context menu data in Redux store
    setOpenContextMenu({
      menu: "",
      open: false,
      data: {},
    });
    setDeletePopUp({
      open: false,
      data: [],
      action: "delete",
    });
  };

  // Dropdown options for the "Title" column
  const editTitleParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input"; // Input styling
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value, // Read selected value
    write: (args) => {
      new DropDownList({
        dataSource: [{ title: "Mrs" }, { title: "Mr" }], // Default to an empty array if undefined
        fields: { text: "title", value: "title" }, // Map 'phoneType' as both text and value
        value: args.rowData[args.column.field] || null, // Preselect the current value
        popupHeight: "300px",
        placeholder: "Title",
        floatLabelType: "Always",
      }).appendTo(args.element); // Attach the dropdown to the editor element
    },
  };

  const editTypeParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input"; // Input styling
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value, // Read selected value
    write: (args) => {
      new DropDownList({
        dataSource: [{ addressType: "Customer" }, { addressType: "Employee" }], // Default to an empty array if undefined
        fields: { text: "addressType", value: "addressType" }, // Map 'phoneType' as both text and value
        value: args.rowData[args.column.field] || null, // Preselect the current value
        popupHeight: "300px",
        placeholder: "Address Type",
        floatLabelType: "Always",
      }).appendTo(args.element); // Attach the dropdown to the editor element
    },
  };

  const ColumnDirective = [
    {
      field: "addressNb",
      headerText: "Address Nb",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
    },
    {
      field: "title",
      headerText: "Title",
      width: "100",
      edit: editTitleParams,
    },
    { field: "fullName", headerText: "Full Name", width: "100" },
    { field: "address1", headerText: "Address 1", width: "100" },
    { field: "address2", headerText: "Address 2", width: "100" },
    {
      field: "addressType",
      headerText: "Address Type",
      width: "100",
      edit: editTypeParams,
    },
    {
      field: "companyId",
      headerText: "Company ID",
      width: "100",
      editTemplate: (props) => (
        <DialogCompanyTemplate
          {...props}
          openCompanyIDPopUp={openCompanyIDPopUp}
          companyIdRowSelected={companyIdRowSelected}
        />
      ),
    },
  ];

  // Function to send data to the server via socket connection
  const sendData = (data, action) => {
    const sendedData =
      (action === "add" && {
        action: "add",
        title: data?.title,
        fullName: data?.fullName,
        address1: data?.address1,
        address2: data?.address2,
        addressType: data?.addressType === "Employee" ? "E" : "C",
        companyId: Number(companyIDSelected),
      }) ||
      (action === "update" && {
        action: "update",
        addressNb: data?.addressNb,
        title: data?.title,
        fullName: data?.fullName,
        address1: data?.address1,
        address2: data?.address2,
        addressType: data?.addressType === "Employee" ? "E" : "C",
        companyId: Number(companyIDSelected),
      }) ||
      (action === "delete" && {
        action: "delete",
        addressNb: data,
      });
    socket.emit(
      "automation:admin:web:usr:v1", // Emit data to server with socket connection
      {
        request: "addressBook",
        addressBook: {
          type: "updateBook",
          updateBook: sendedData,
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true, // Display notification snackbar based on server response
            message: response?.message?.text,
            type: response?.message?.messageType,
          })
        );
      }
    );
  };

  // Function to fetch context menu data from the server
  const getContextMenuData = (menu, addressNb) => {
    setIsContextMenuLoading(true);
    socket.emit(
      "automation:admin:web:usr:v1",
      {
        request: "addressBook",
        addressBook: {
          type: menu,
          [menu]: {
            addressNb: addressNb,
          },
        },
      },
      (response) => {
        dispatch(setContextMenuData(response?.data)); // Update context menu data in Redux store
        if (menu === "searchPhone") {
          getUDCApi(
            socket,
            "AB",
            "PN",
            dispatch,
            setPhoneNbTypes,
            setIsContextMenuLoading
          );
        } else {
          setIsContextMenuLoading(false);
        }
      }
    );
  };

  // Function to handle changes in the DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      setDeletePopUp({
        open: true,
        data: args?.promise?.[0],
        action: "delete",
      });
    } else if (args.action === "edit") {
      sendData(args?.data, "update");
    } else if (args.action === "add") {
      sendData(args?.data, "add");
    }
  };

  // Function to send context menu data (email/phone) to the server
  const sendContextData = () => {
    const type =
      openContextMenu?.menu === "emails" ? "updateEmail" : "updatePhone";
    socket.emit(
      "automation:admin:web:usr:v1",
      {
        request: "addressBook",
        addressBook: {
          type: type,
          [type]: changes,
        },
      },
      (response) => {
        dispatch(
          setNotificationSnackBar({
            open: true,
            message: response?.message?.text,
            type: response?.message?.messageType,
          })
        );
        handleClose(); // Close popup after submission
      }
    );
  };

  const selectCompanyID = () => {
    setOpenCompanyID(false);
    dispatch(setCompanyIDSelected(companyIdRowSelected?.UVVALUE));
  };

  const contextMenuItems = [
    { text: "Emails", target: ".e-content", id: "emails" }, // Menu item for emails
    { text: "Phone Numbers", target: ".e-content", id: "phoneNumbers" }, // Menu item for phone numbers
  ];

  const contextMenuClick = (args, gridInstance) => {
    if (gridInstance && args.item.id === "emails") {
      getContextMenuData("searchEmail", args?.rowInfo?.rowData?.["addressNb"]); // Fetch data for email
      openContextMenuPopUp("emails", args?.rowInfo?.rowData); // Open context menu for emails
    } else if (gridInstance && args.item.id === "phoneNumbers") {
      getContextMenuData("searchPhone", args?.rowInfo?.rowData?.["addressNb"]); // Fetch data for phone number
      openContextMenuPopUp("phoneNumbers", args?.rowInfo?.rowData); // Open context menu for phone numbers
    }
  };

  const onDelete = () => {
    sendData(companyIdRowSelected?.addressNb, "delete");
  };

  return (
    <div
      style={{
        marginTop: "1%", // Adding margin to position the DataGrid
        zIndex: "0", // Ensuring correct layering
        display: "flex", // Flexbox to center content
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {masterInfoData && ( // Conditionally render DataGrid when data is available
        <div
          style={{
            opacity: openContextMenu?.open && "0.3", // Dim the DataGrid when context menu is open
          }}
        >
          <SingleHierarchy
            columns={ColumnDirective} // Passing column definitions
            data={masterInfoData} // Passing data to the DataGrid
            primaryKey={"addressNb"} // Setting primary key
            openContextMenuPopUp={openContextMenuPopUp} // Passing function to open context menu
            getContextMenuData={getContextMenuData} // Passing function to fetch context menu data
            selection={"Single"} // Single selection mode
            onChanges={onChanges} // Passing function to handle changes
            contextMenu={true} // Enabling context menu
            setRowSelected={setCompanyIdRowSelected}
            readOnly={false}
            contextMenuItems={contextMenuItems}
            contextMenuClick={(args, gridInstance) =>
              contextMenuClick(args, gridInstance)
            }
            onDelete={onDelete}
            handleClose={handleClose}
          />
        </div>
      )}
      {openCompanyID && ( // Conditionally render Company ID popup
        <AlertDialog
          open={openCompanyID}
          handleClose={handleClose} // Function to handle closing the popup
          title={"Company ID"}
          content={
            <CompanyIDPopUp setCompanyIdRowSelected={setCompanyIdRowSelected} />
          } // Render Company ID popup content
          buttonTitle={"Select"}
          buttonClick={selectCompanyID} // Placeholder button click handler
          width={"100%"}
        />
      )}
      {openContextMenu?.open && ( // Conditionally render context menu popup
        <MyAlertDialog
          open={openContextMenu?.open}
          handleClose={handleClose}
          title={
            openContextMenu?.menu === "emails" ? "Emails" : "Phone Numbers"
          } // Display appropriate title
          content={
            // Render context menu content
            <ContextMenuComponent
              menu={openContextMenu?.menu}
              data={openContextMenu?.data}
              changes={changes}
              setChanges={setChanges}
              isContextMenuLoading={isContextMenuLoading}
              phoneTypes={phoneTypes}
            />
          }
          buttonTitle={"Save"}
          buttonClick={sendContextData} // Send changes on button click
        />
      )}
    </div>
  );
};

export default MasterInfoBody; // Exporting the component as default
