import * as React from "react"; // Import React library
import FormGroup from "@mui/material/FormGroup"; // Import Material-UI FormGroup component for grouping form controls
import FormControlLabel from "@mui/material/FormControlLabel"; // Import Material-UI FormControlLabel for pairing a label with a control
import Checkbox from "@mui/material/Checkbox"; // Import Material-UI Checkbox component
import "./checkbox.css"; // Import custom CSS for additional styling

// CheckboxLabels is a functional component for rendering a labeled checkbox
export default function CheckboxLabels(props) {
  return (
    // FormGroup provides a container for grouping form controls
    <FormGroup
      sx={{
        display: "flex", // Use flexbox for layout
        alignItems: "center", // Align items vertically at the center
      }}
      className="checkbox-label" // Custom CSS class for additional styling
    >
      {/* FormControlLabel combines the label and the checkbox */}
      <FormControlLabel
        {...props} // Spread props to allow flexibility in customization (e.g., label text, event handlers)
        control={<Checkbox defaultChecked />} // Render a checkbox, defaulting to checked
      />
    </FormGroup>
  );
}
