import { Box, CircularProgress, Grid } from "@mui/material"; // Importing Material-UI components for layout and loading indicator
import React, { useState } from "react"; // Importing React and useState hook
import FilterComponent from "../../../Common/Filter"; // Importing a custom Filter component
import MasterInfoFilterReturn from "./FilterReturn"; // Importing a filter return component for managing loading state
import MasterInfoBody from "./Body"; // Importing the body component to display master info data
import { useSelector } from "react-redux"; // Importing useSelector hook for accessing Redux state

const MasterInfoComponent = () => {
  const [isLoading, setIsLoading] = useState(false); // State to manage loading status
  const masterInfoData = useSelector(
    (state) => state?.addressBook?.masterInfoData // Selecting masterInfoData from Redux state
  );

  return (
    <Box sx={{ color: "black" }}>
      {" "}
      <Grid sx={{ backgroundColor: "rgb(226, 226, 226)" }}>
        {/* Main container with text color set to black */}
        <FilterComponent
          children={<MasterInfoFilterReturn setIsLoading={setIsLoading} />} // Rendering filter component with loading state handler
        />
        {/* Conditionally rendering MasterInfoBody if not loading and data is available */}
        {!isLoading && masterInfoData?.length > 0 && <MasterInfoBody />}
      </Grid>
      {/* Displaying a loading spinner when isLoading is true */}
      {isLoading && (
        <div
          style={{
            width: "100%", // Full width
            display: "flex", // Flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <CircularProgress
            sx={{
              color: "#1976d2", // Spinner color
              width: "100px !important", // Setting width
              height: "100px !important", // Setting height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default MasterInfoComponent; // Exporting the component for use in other parts of the application
