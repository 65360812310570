import React, { useEffect, useRef } from "react"; // Import necessary React hooks and components
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft"; // Left arrow icon for collapsing sidebar
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"; // Right arrow icon for expanding sidebar
import styles from "./sidenav.module.css"; // Import custom styles
import { NavLink, useNavigate } from "react-router-dom"; // Import NavLink for navigation and useNavigate for programmatic navigation
import { Box, Divider } from "@mui/material"; // Import Material UI components for layout and styling
import { useDispatch, useSelector } from "react-redux"; // Import Redux hooks to interact with the store
import { setSelectedMenu } from "../../../Services/Redux/Reducers/authSliceReducer"; // Redux action to set selected menu
import Cookies from "universal-cookie"; // For handling cookies

export default function Sidenav({ open, setopen }) {
  const sidebarRef = useRef(null); // Reference to sidebar to detect clicks outside
  const userMenu = useSelector((state) => state.auth.userMenu); // Get user menu from Redux store
  const dispatch = useDispatch(); // Dispatch function for Redux
  const cookies = new Cookies(); // Initialize cookies
  const navigate = useNavigate(); // For programmatic navigation
  const selectedMenu = cookies.get("_selectedMenu"); // Get selected menu from cookies

  // Effect to close sidebar when clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setopen(false); // Close sidebar if clicked outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside); // Add event listener for mouse down
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Clean up event listener on unmount
    };
  }, [setopen]);

  // Toggle sidebar open/closed
  const toggleOpen = () => {
    setopen(!open);
  };

  // Handle menu item selection and navigation
  const onSelectMenu = (item) => {
    navigate("/main/home"); // Navigate to home when a menu item is selected
    dispatch(setSelectedMenu(item)); // Dispatch action to set the selected menu in Redux
    cookies.set("_selectedMenu", JSON.stringify(item), {
      path: "/",
      expires: new Date(Date.now() + 2592000), // Set cookie with selected menu and expiration
    });
  };

  return (
    <div
      ref={sidebarRef} // Attach ref to the sidebar
      className={open ? styles.sidenav : styles.sidenavClosed} // Apply styles based on open/closed state
    >
      {open && (
        <div
          style={{
            borderRadius: "7.5px", // Apply rounded corners to the logo container
          }}
        >
          <img src="/utilic-logo-removebg-preview.png" width={"100%"} />{" "}
          {/* Display the logo when sidebar is open */}
        </div>
      )}
      {/* If sidebar is closed, show button to open it */}
      {!open ? (
        <button
          className={styles.menuBtn}
          style={{ width: "85%" }}
          onClick={toggleOpen} // Toggle sidebar open state when clicked
        >
          <KeyboardDoubleArrowRightIcon
            sx={{ width: "1.2em", height: "1.2em" }} // Style the arrow icon
          />
        </button>
      ) : (
        // If sidebar is open, display close button and a divider
        <Divider
          textAlign="left"
          sx={{
            "&::before, &::after": {
              borderColor: "#a5a5a5", // Customize divider color
            },
            marginTop: !open ? "40%" : "0%", // Adjust margin when sidebar is open
          }}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <button className={styles.menuBtn} onClick={toggleOpen}>
              {" "}
              {/* Close button */}
              <KeyboardDoubleArrowLeftIcon
                sx={{ width: "1.2em", height: "1.2em" }} // Style the left arrow icon
              />
            </button>
          </Box>
        </Divider>
      )}

      {/* Map through the user menu items and display them */}
      {userMenu?.map((item) => {
        const selected = item?.pageId === selectedMenu?.pageId; // Check if the current item is selected
        return (
          <div key={item.pageId} onClick={() => onSelectMenu(item)}>
            {" "}
            {/* Handle item click */}
            <NavLink
              className={styles.sideitem} // Apply custom style to each menu item
              to={item?.link} // Set the link for navigation
              style={{
                display: "flex",
                justifyContent: !open ? "center" : "left", // Adjust content alignment based on sidebar state
                backgroundColor: selected && "rgb(172 203 0 / 78%)", // Highlight selected item
              }}
              onClick={() => setopen(false)} // Close the sidebar when an item is selected
            >
              {/* Menu item icon */}
              <div
                style={{
                  borderRadius: "6px",
                  width: !open ? "70%" : "13%",
                  height: !open ? "70%" : "13%",
                  marginRight: !open ? "0%" : "20px",
                  marginLeft: open ? "10px" : "0px",
                }}
              >
                <img
                  src={item?.pageIcon} // Display icon for each menu item
                  width={"100%"}
                  style={{
                    marginBottom: "0px",
                    filter:
                      //  selected
                      //   ? "invert(50%) sepia(100%) saturate(500%) hue-rotate(120deg)" // Optional filter for selected items
                      //   :
                      "invert(100%)", // Make icon color inverted
                    borderRadius: "0px",
                  }}
                />
              </div>
              {open && (
                <span className={styles.linkText}>
                  {" "}
                  {/* Menu item description */}
                  {item.pageDesc}
                </span>
              )}
            </NavLink>
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <Divider
                sx={{
                  borderColor: "rgb(150 150 150 / 54%)", // Customize divider color
                  paddingX: "5%", // Padding for the divider
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}
