import React, { useState } from "react";
import DualListBox from "../../../../../Common/DualListBox"; // Import the custom DualListBox component
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

const CutomizedEditTemplate = (props) => {
  // Initialize state for the menu data and pages
  const [state, setState] = useState({ ...(props?.[0] || {}) }); // Set the initial state based on passed props
  const [menuPages, setMenuPages] = useState(props?.[0]?.pages); // Initialize menu pages from props

  const setUpdateChanges = props?.setUpdateChanges; // Get the function to update changes

  // Retrieve all pages data and user menu data from the Redux store
  const titles = ["All Pages", "My Pages"]; // Titles for DualListBox
  const allPagesData = useSelector(
    (state) => state?.userFeatures?.userMenuData
  )?.allPages;
  const userMenuData = useSelector(
    (state) => state?.userFeatures?.userMenuData
  )?.menus;

  // Prepare the data for the DualListBox component
  const data = () => {
    // Get the specific pages for the current menu
    const specific =
      userMenuData
        ?.find((obj) => obj?.menuId === props?.[0]?.menuId)
        ?.pages?.map(({ pageId, pageDesc, pageIcon, pageRoute }) => ({
          pageId: pageId,
          text: pageDesc,
          description: pageRoute,
          icon: pageIcon,
        })) || [];

    // Get the IDs of pages that are already selected
    const idsToRemove = specific?.map((obj) => obj?.pageId);

    // Filter out pages that are already selected (i.e., pages that are not in 'specific')
    const all =
      allPagesData
        ?.map(({ pageId, pageDesc, pageIcon, pageRoute }) => ({
          pageId: pageId,
          text: pageDesc,
          description: pageRoute,
          icon: pageIcon,
        }))
        ?.filter((item) => !idsToRemove?.includes(item.pageId)) || [];

    return {
      groupa: all, // Pages that are available to add
      groupb: specific, // Pages that are already selected
    };
  };

  // Handle changes in input fields (for Menu ID or Description)
  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value, // Update the respective field in state
    }));
    // Update the changes when input fields are modified
    setUpdateChanges(menuPages);
  };

  // Handle changes in the DualListBox (move items between lists)
  const onChanges = (e) => {
    if (e?.eventName === "moveAllTo") {
      // Move all pages to selected
      setMenuPages(allPagesData); // Select all pages
      setUpdateChanges(allPagesData); // Update the changes state
    } else if (e?.eventName === "moveAllFrom") {
      // Move all pages to available
      setMenuPages([]); // Deselect all pages
      setUpdateChanges([]); // Clear the changes state
    } else if (e?.eventName === "moveTo") {
      // Move a page to selected
      setMenuPages([...menuPages, e?.items?.[0]]); // Add selected page
      setUpdateChanges([...menuPages, e?.items?.[0]]); // Update the changes state
    } else if (e?.eventName === "moveFrom") {
      // Move a page to available
      const removed = menuPages?.filter(
        (item) => item.pageId !== e?.items?.[0]?.pageId // Remove the page from the selected list
      );
      setMenuPages(removed); // Update selected pages
      setUpdateChanges(removed); // Update the changes state
    }
  };

  return (
    <Box>
      <Grid>
        <div id="tab0" className="tab" style={{ display: "ruby" }}>
          <div className="form-row" style={{ width: "50%" }}>
            {/* Menu ID input field (disabled) */}
            <div
              className="form-group col-md-6"
              style={{ width: "100%", marginBottom: "0px" }}
            >
              <div
                className="e-float-input e-control-wrapper"
                style={{ marginTop: "16px" }}
              >
                <input
                  id="menuId"
                  name="menuId"
                  type="text"
                  disabled={true} // Disable editing for Menu ID
                  value={state.menuId || ""} // Set the value from state
                  onChange={onChange} // Handle input change
                />
                <span className="e-float-line" />
                <label className="e-float-text e-label-top">Menu ID</label>
              </div>
            </div>
          </div>
          <div className="form-row" style={{ width: "50%" }}>
            {/* Menu Description input field */}
            <div
              className="form-group col-md-6"
              style={{
                width: "100%",
                marginBottom: "0px",
                marginTop: "10px",
              }}
            >
              <div className="e-float-input e-control-wrapper">
                <input
                  id="menuDesc"
                  name="menuDesc"
                  type="text"
                  value={state.menuDesc || ""} // Set the value from state
                  onChange={onChange} // Handle input change
                />
                <span className="e-float-line" />
                <label className="e-float-text e-label-top">Description</label>
              </div>
            </div>
          </div>
        </div>
      </Grid>
      {/* Render the DualListBox component with the prepared data */}
      <DualListBox data={data()} titles={titles} onChanges={onChanges} />
    </Box>
  );
};

export default CutomizedEditTemplate;
