// Importing the `io` function from socket.io-client to establish a WebSocket connection
import { io } from "socket.io-client";
// Importing the Socket.io URL from a global configuration variable
import { SOCKET_IO_URL } from "../GlobalVar/commonVar";

// SocketIO function that establishes a socket connection with specific configurations
const SocketIO = (token, path, auto) => {
  // Create a new socket connection using the provided URL and configuration options
  const socket = io(SOCKET_IO_URL, {
    autoConnect: auto, // Determines whether the socket should auto-connect when instantiated
    path: path, // The path for the socket connection (typically a URL path)

    upgrade: false, // Disable HTTP long-polling and only use WebSocket for the connection
    reconnection: true, // Enable automatic reconnection if the connection is lost
    reconnectionAttempts: Infinity, // The number of reconnection attempts (Infinity means it will try forever)
    reconnectionDelay: 1000, // Delay before the first reconnection attempt (in milliseconds)
    reconnectionDelayMax: 5000, // The maximum delay between reconnection attempts (in milliseconds)
    query: {
      token: token, // Send the user's token as a query parameter for authorization
    },
    transports: ["websocket"], // Use WebSocket transport first, and avoid other transports like polling
    rejectUnauthorized: false, // Disable the rejection of unauthorized SSL certificates (useful for testing)
  });

  // Return the created socket instance
  return socket;
};

// Export the `SocketIO` function for use in other parts of the application
export default SocketIO;
