// Importing necessary modules from Redux Toolkit and universal-cookie
import { createSlice } from "@reduxjs/toolkit";

// Define the initial state of the authentication slice
const projectsSetupSliceInitialState = {
  // Retrieve userAll from cookies if available, otherwise initialize as an empty object
  featuresSetupFilter: {
    searchTxt: "",
  },
  featuresSetupData: [],
};

// Create a slice for authentication using Redux Toolkit's createSlice function
const projectsSetupSlice = createSlice({
  name: "projectsSetup", // Name of the slice
  initialState: projectsSetupSliceInitialState, // Initial state defined above

  // Define the reducers (functions to update the state)
  reducers: {
    // Set user credentials in the state and cookies
    setFeaturesSetupFilter: (state, action) => {
      // Update the state with the new user information
      state.featuresSetupFilter = action.payload;
    }, // Set user credentials in the state and cookies
    setFeaturesSetupData: (state, action) => {
      // Update the state with the new user information
      state.featuresSetupData = action.payload;
    }, // Set user credentials in the state and cookies
  },
});

// Export the actions to be used in other parts of the application
export const { setFeaturesSetupFilter, setFeaturesSetupData } =
  projectsSetupSlice.actions;

// Export the reducer to be used in the store configuration
export default projectsSetupSlice.reducer;
