import React from "react";
import { Box, Grid, Typography, CircularProgress } from "@mui/material"; // Importing MUI components
import SingleHierarchy from "../../../../../Common/DataGrid/singleGrid"; // Importing the SingleHierarchy grid component
import { useSelector } from "react-redux"; // Importing Redux hook to access the store
import { DropDownList } from "@syncfusion/ej2-dropdowns"; // Importing Syncfusion Dropdown
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-grids/styles/material.css";
import "@syncfusion/ej2-dropdowns/styles/material.css";

const ContextMenuItemsComponent = ({
  menu, // Prop: Defines the type of menu (itemFeatures or other)
  changes, // Prop: Stores changes made in the grid
  setChanges, // Prop: Function to update the changes state
  data, // Prop: Data related to the current context (e.g., itemId)
  isContextMenuLoading, // Prop: Flag to check if the context menu data is loading
  units, // Prop: Units data for dropdown
  featureType, // Prop: Feature types data for dropdown
}) => {
  // Accessing context menu items data from the Redux store
  const contextMenuItemsData = useSelector(
    (state) => state?.inventory?.contextMenuItemsData
  );

  // Edit parameters for the Units dropdown
  const editUnitsParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input"; // Styling the input
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value, // Reading the selected value
    write: (args) => {
      new DropDownList({
        dataSource: units || [], // Default to an empty array if no units provided
        fields: { text: "featureUnitDesc", value: "featureUnitDesc" }, // Display the 'featureUnitDesc' for both text and value
        value: args.rowData[args.column.field] || null, // Preselect the current value of the field
        popupHeight: "300px", // Set the dropdown popup height
        placeholder: "Select Unit", // Placeholder text for the dropdown
        floatLabelType: "Always", // Always float the label for better UX
      }).appendTo(args.element); // Attach the dropdown to the editor element
    },
  };

  // Edit parameters for the Feature Type dropdown
  const editFeatureTypeParams = {
    create: () => {
      const input = document.createElement("input");
      input.className = "e-input"; // Styling the input
      return input;
    },
    read: (element) => element.ej2_instances[0]?.value, // Reading the selected value
    write: (args) => {
      new DropDownList({
        dataSource: featureType || [], // Default to an empty array if no featureType provided
        fields: { text: "featureType", value: "featureType" }, // Display the 'featureType' for both text and value
        value: args.rowData[args.column.field] || null, // Preselect the current value of the field
        popupHeight: "300px", // Set the dropdown popup height
        placeholder: "Select Unit", // Placeholder text for the dropdown
        floatLabelType: "Always", // Always float the label for better UX
      }).appendTo(args.element); // Attach the dropdown to the editor element
    },
  };

  // Column configuration for the itemFeatures menu
  const ColumnFeaturesDirective = [
    {
      field: "featureId",
      headerText: "ID",
      width: "50",
      isPrimaryKey: true,
      isIdentity: true,
    },
    {
      field: "featureType",
      headerText: "Type",
      width: "150",
      editType: "dropdownedit", // Specifies this column uses a dropdown for editing
      edit: editFeatureTypeParams, // Attach the dropdown editor
    },
    { field: "featureDesc", headerText: "Description", width: "100" },
    {
      field: "featureUnitDesc",
      headerText: "Unit",
      width: "150",
      editType: "dropdownedit", // Specifies this column uses a dropdown for editing
      edit: editUnitsParams, // Attach the dropdown editor
    },
  ];

  // Column configuration for the communication menu (email management)
  const ColumnCommunicationDirective = [
    {
      field: "featureId",
      headerText: "ID",
      width: "50",
      isPrimaryKey: true,
      allowEditing: false, // Prevent editing of the featureId column
    },
    {
      field: "email",
      headerText: "Email",
      width: "300",
      allowEditing: true, // Allow editing of the email field
    },
  ];

  // Helper function to compare two objects (used to check if two objects are equal)
  const areObjectsEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true;
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key)) {
        return false;
      }

      if (!areObjectsEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  // Helper function to generate random featureId
  function getRandomlineId() {
    const min = 100;
    const max = 9999;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  // Handler for grid changes (add, update, delete)
  const onChanges = (args, gridInstance) => {
    let updated = {
      action: args?.action === "add" ? "add" : "update",
      itemId: data?.itemId,
      ...args.data,
    };

    // Handling delete action
    if (args.requestType === "delete") {
      const changesDelete = {
        action: "delete",
        featureId: args?.promise?.[0]?.featureId,
        itemId: data?.itemId,
      };

      // Checking if the featureId exists in the current changes
      const existingIndex = changes?.findIndex(
        (change) => change?.featureId === args?.promise?.[0]?.featureId
      );

      if (gridInstance?.current) {
        gridInstance.current.refresh(); // Refresh the grid
      }

      // Updating the changes state
      if (existingIndex !== -1) {
        setChanges((prevChanges) => {
          if (changes[existingIndex]?.action === "update") {
            const updatedChanges = prevChanges.filter(
              (_, index) => index !== existingIndex
            );
            return [...updatedChanges, changesDelete];
          } else {
            const updatedChanges = prevChanges.filter(
              (_, index) => index !== existingIndex
            );
            return [...updatedChanges];
          }
        });
      } else {
        setChanges((prevChanges) => [...prevChanges, changesDelete]);
      }
    } else if (args.action === "edit") {
      const existingIndex = changes?.findIndex(
        (change) => change?.featureId === args?.data?.featureId
      );

      if (existingIndex !== -1) {
        // Checking if the item data has changed, update accordingly
        if (
          contextMenuItemsData?.some((item) => areObjectsEqual(item, args.data))
        ) {
          setChanges((prevChanges) => {
            const updatedChanges = [...prevChanges];
            updatedChanges.splice(existingIndex, 1);
            return updatedChanges;
          });
        } else if (changes[existingIndex]?.action === "add") {
          setChanges((prevChanges) =>
            prevChanges.map((change, index) =>
              index === existingIndex
                ? {
                    action: "add",
                    itemId: data?.itemId,
                    ...{ ...args.data },
                  }
                : change
            )
          );
        } else {
          setChanges((prevChanges) =>
            prevChanges.map((change, index) =>
              index === existingIndex
                ? {
                    ...change,
                    itemId: data?.itemId,
                    ...{ ...args.data },
                  }
                : change
            )
          );
        }
      } else {
        setChanges((prevChanges) => [...prevChanges, { ...updated }]);
      }
    } else if (args.action === "add") {
      if (!args.data.featureId) {
        args.data.featureId = getRandomlineId(); // Assign a random featureId if not provided
      }
      updated = {
        action: "add",
        itemId: data?.itemId,
        ...args.data,
      };

      setChanges((prevChanges) => [...prevChanges, updated]);
    }

    // Refresh grid after save or delete
    if (args.requestType === "save" || args.requestType === "delete") {
      if (gridInstance?.current) {
        gridInstance.current.refresh(); // Refresh the grid
      }
    }
  };

  // Function to open context menu popup
  const openContextMenuPopUp = (type, rowData) => {
    // Logic for opening context menu (currently placeholder)
  };

  // Function to fetch context menu data
  const getContextMenuData = (type, id) => {
    // Logic for retrieving context menu data (currently placeholder)
  };

  return (
    <Box>
      <Grid>
        {!isContextMenuLoading ? (
          <SingleHierarchy
            columns={
              menu === "itemFeatures"
                ? ColumnFeaturesDirective
                : ColumnCommunicationDirective
            }
            data={JSON.parse(JSON.stringify(contextMenuItemsData))} // Make a copy of the contextMenuItemsData to avoid direct mutation
            primaryKey={"featureId"}
            selection={"Multiple"}
            onChanges={onChanges} // Handler for changes in the grid
            openContextMenuPopUp={openContextMenuPopUp} // Function to open the context menu
            getContextMenuData={getContextMenuData} // Function to retrieve context menu data
            contextMenu={false} // Disable default context menu
            readOnly={false} // Allow editing
          />
        ) : (
          // Loading state while the context menu data is loading
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Typography variant="body1">Loading records...</Typography>
            <CircularProgress size={24} sx={{ ml: 2 }} />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default ContextMenuItemsComponent;
