import { Grid2 } from "@mui/material"; // Import Grid2 component from MUI for layout
import React from "react"; // Import React library
import BoxItem from "./BoxItem"; // Import BoxItem component to display individual items

// BigBoxItems component renders a BoxItem component with the passed props
const BigBoxItems = ({
  lBox, // Object containing data for the box (e.g., title, description, image URL)
  focus, // Current focus state (e.g., whether the box is focused or not)
  setFocus, // Function to update the focus state
  onFocus, // Handler for focus event
  onBlur, // Handler for blur event
  sectionId, // Section ID where the box is located
  onEditClick, // Handler for edit button click
  pageId, // ID of the page the box is associated with
}) => {
  return (
    // Grid2 component to create a flexible layout with 100% width, margin, and height
    <Grid2 width={"100%"} marginY={"1%"} height={"100%"}>
      {/* Render the BoxItem component and pass all the relevant props */}
      <BoxItem
        title={lBox?.appTitle} // Title of the box (from lBox object)
        description={lBox?.appDesc} // Description of the box (from lBox object)
        image={lBox?.appImgURL} // Image URL for the box (from lBox object)
        focus={focus} // Focus state for the box
        setFocus={setFocus} // Function to update focus state
        onFocus={onFocus} // Focus event handler
        onBlur={onBlur} // Blur event handler
        sectionId={sectionId} // Section ID for the box
        data={lBox} // The full data of the box (e.g., title, description, image URL)
        onEditClick={onEditClick} // Edit button click handler
        pageId={pageId} // Page ID the box belongs to
      />
    </Grid2>
  );
};

export default BigBoxItems; // Export the BigBoxItems component for use elsewhere
