import * as React from "react"; // Import React library
import {
  Button, // Import Material-UI Button component
  Dialog, // Import Material-UI Dialog component
  DialogActions, // Import Material-UI DialogActions component for footer actions
  DialogContent, // Import Material-UI DialogContent component for main content
  DialogTitle, // Import Material-UI DialogTitle component for dialog title
  Grid, // Import Material-UI Grid component for layout
} from "@mui/material"; // Importing Material-UI components

// Functional component to render an alert dialog
function AlertDialog(props) {
  // Set default width to "100%" if no width is provided via props
  const width = props?.width ? props?.width : "100%";

  return (
    <Dialog
      open={props.open} // Control the open/close state of the dialog
      onClose={props.handleClose} // Callback function when the dialog is closed
      aria-labelledby="alert-dialog-title" // Accessibility label for the title
      aria-describedby="alert-dialog-description" // Accessibility label for the content
      container={document.body} // Ensure the dialog renders in the body element
      sx={{
        zIndex: 200000, // High z-index to ensure dialog appears above other elements
        position: "absolute", // Use absolute positioning
        ".css-c09uja-MuiPaper-root-MuiDialog-paper": {
          width: width, // Apply dynamic width for the dialog
        },
        ".css-c09uja-MuiPaper-root-MuiDialog-paper": {
          maxWidth: props?.maxWidth ?? props?.maxWidth,
        },
      }}
    >
      {/* Dialog Title */}
      <DialogTitle
        id="alert-dialog-title" // Accessibility identifier for the title
        sx={{
          color: "#064987", // Title color
          fontWeight: "bold", // Bold text for emphasis
          fontSize: "1.2em", // Slightly larger font size
        }}
        display={"flex"} // Use flexbox layout
        justifyContent={"center"} // Center-align the title
      >
        {props.title} {/* Render the title passed as a prop */}
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent>
        <Grid
          id="alert-dialog-description" // Accessibility identifier for the content
          sx={props?.sx} // Apply optional custom styles passed as a prop
        >
          {props.content} {/* Render the content passed as a prop */}
        </Grid>
      </DialogContent>

      {/* Dialog Actions */}
      <DialogActions>
        <Grid container>
          {" "}
          {/* Grid layout for organizing action buttons */}
          <Grid item xs={8}></Grid> {/* Empty grid item for spacing */}
          <Grid item xs={1.8}>
            {/* Render action button only if buttonTitle prop is provided */}
            {props.buttonTitle !== "" && (
              <Button
                autoFocus // Automatically focus this button when dialog opens
                onClick={props.buttonClick} // Callback function when button is clicked
                sx={{
                  backgroundColor: "#064987!important", // Custom background color
                  color: "white", // White text color
                  width: "100%", // Full-width button
                }}
                disabled={props.buttonDisabled} // Disable button based on prop
              >
                {props.buttonTitle} {/* Button text from props */}
              </Button>
            )}
          </Grid>
          <Grid item xs={0.1}></Grid> {/* Empty grid item for spacing */}
          <Grid item xs={1.8}>
            {/* Cancel button */}
            <Button
              autoFocus // Automatically focus this button when dialog opens
              sx={{
                backgroundColor: "#7d7d7d", // Grey background color for cancel button
                color: "white", // White text color
                width: "100%", // Full-width button
              }}
              onClick={props.handleClose} // Callback function for cancel action
            >
              Cancel {/* Button label for cancel action */}
            </Button>
          </Grid>
          <Grid item xs={0.3}></Grid> {/* Empty grid item for spacing */}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default AlertDialog; // Export the AlertDialog component for reuse
