import "./index.css"; // Import custom CSS for styling
import * as React from "react"; // Import React
import { useState } from "react"; // Import useState hook for managing component state
import { OtpInputComponent } from "@syncfusion/ej2-react-inputs"; // Import Syncfusion's OTP input component

/**
 * OTPInput
 * A reusable OTP (One-Time Password) input component that uses the Syncfusion OtpInputComponent
 * to display and manage OTP entry for users.
 *
 * Props:
 * - value: The OTP value that is controlled externally by the parent component.
 * - onChange: Callback function triggered whenever the OTP value changes.
 */
const OTPInput = ({ value, onChange }) => {
  return (
    <div
      className="control-pane"
      style={{ display: "flex", justifyContent: "center" }} // Center the OTP input container
    >
      <div
        className="control-section"
        style={{ display: "flex", justifyContent: "center" }} // Center the OTP input section
      >
        <div
          className="col-lg-8"
          style={{ display: "flex", justifyContent: "center" }} // Center the OTP input container within the section
        >
          <div
            id="otp-container"
            style={{ display: "flex", justifyContent: "center" }} // Center the OTP input element itself
          >
            {/* Syncfusion OTP Input Component */}
            <OtpInputComponent
              style={{ display: "flex", justifyContent: "center" }} // Center the OTP input component
              id="basicOtp" // Set a unique ID for the OTP input component
              separator={"-"} // Separator character between OTP digits
              placeholder={"X"} // Placeholder text for each OTP digit
              value={value} // Controlled OTP value passed from the parent component
              length={4} // Length of the OTP (4 digits)
              // cssClass={validationValue} // Optional: add dynamic validation styling class
              stylingMode={"outlined"} // Styling mode for the OTP input (e.g., outlined, underlined, etc.)
              input={(e) => onChange(e?.value)} // Callback to notify parent component when OTP value changes
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OTPInput; // Export the OTPInput component
