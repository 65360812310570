import { Button, Grid2 } from "@mui/material"; // Import Material-UI components for layout and interaction
import React from "react"; // Import React
import BoxItem from "./BoxItem"; // Import the reusable BoxItem component
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import Cookies from "universal-cookie"; // Import Cookies for managing cookies

// BigBoxItems is a functional component that wraps the BoxItem component in a clickable button
const BigBoxItems = (props) => {
  const navigate = useNavigate(); // Hook for navigation in React Router
  const details = props.lBox; // Extract lBox prop (expected to contain details for BoxItem)
  const path = props.path; // Extract path prop (not used directly in this code)
  const cookies = new Cookies(); // Create a cookies instance for managing cookies

  return (
    // Outer container for the component
    <Grid2 width={"100%"} marginY={"1%"} height={"100%"}>
      {/* Button that wraps the BoxItem to make it clickable */}
      <Button
        sx={{
          padding: "0px", // Remove padding around the button
          textTransform: "none", // Keep the button text as-is (no capitalization)
          width: "100%", // Full width of the container
          height: "100%", // Full height of the container
        }}
        onClick={() => {
          // Handle button click to navigate and optionally set cookies
          navigate(details?.appRoute); // Navigate to the route specified in details.appRoute

          // (Commented-out code for managing cookies)
          // Uncomment if cookies are needed to store information about the selected page or app
          // cookies.set("_selectedPage", details?.AIDESC, {
          //   path: "/", // Set the cookie for the root path
          //   expires: new Date(Date.now() + 2592000), // Set cookie expiry to 30 days
          // });
          // cookies.set(
          //   "_selectedApp",
          //   details?.AIDESC + " / " + details?.BJTITLE, // Store a combined value of AIDESC and BJTITLE
          //   {
          //     path: "/", // Set the cookie for the root path
          //     expires: new Date(Date.now() + 2592000), // Set cookie expiry to 30 days
          //   }
          // );
        }}
      >
        {/* Render the BoxItem component inside the button */}
        <BoxItem
          title={details?.appTitle} // Title for the BoxItem
          description={details?.appDesc} // Description for the BoxItem
          image={details?.appImageUrl} // Image URL for the BoxItem
        />
      </Button>
    </Grid2>
  );
};

export default BigBoxItems; // Export the BigBoxItems component for reuse
