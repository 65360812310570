// Importing necessary functions from Redux Toolkit for API handling
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"; // For creating API slice and base query function
import { setCredentials, logOut } from "../Redux/Reducers/authSliceReducer"; // For dispatching actions to set credentials and log out
import { HTTP_URL } from "../GlobalVar/commonVar"; // Importing base URL from global configuration

// Base query function using fetchBaseQuery
const baseQuery = fetchBaseQuery({
  baseUrl: HTTP_URL, // The base URL for all API requests
  credentials: "include", // To include cookies (like session or CSRF token) with each request
  prepareHeaders: (headers, { getState }) => {
    // This function is used to attach authorization token to the headers
    const token = getState().auth.token; // Get the current authentication token from the Redux state
    if (token) {
      headers.set("authorization", `Bearer ${token}`); // Attach token if available
    }
    return headers; // Return the headers
  },
});

// A modified base query function with refresh token handling
const baseQueryWithReauth = async (args, api, extraOptions) => {
  // Perform the original API request
  let result = await baseQuery(args, api, extraOptions);

  // Check if the response status is 403 (Forbidden), indicating the token might have expired
  if (result?.error?.originalStatus === 403) {
    // If the token is expired, try to get a new one using the refresh token
    const refreshResult = await baseQuery("/refresh", api, extraOptions); // Send refresh request to the server

    // If the refresh request is successful
    if (refreshResult?.data) {
      const user = api.getState().auth.user; // Get the current user from the state
      // Dispatch action to update the credentials (store the new token)
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      // Retry the original request with the new token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // If the refresh fails (e.g., no valid refresh token), log the user out
      api.dispatch(logOut());
    }
  }

  return result; // Return the final result (either new data or an error)
};

// Creating the API slice using createApi
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth, // Use the base query with re-authentication handling
  endpoints: (builder) => ({}), // Endpoints configuration (none defined in this code snippet)
});
