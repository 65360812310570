import * as React from "react"; // Import React
import { Stack, Snackbar } from "@mui/material"; // Import MUI Stack and Snackbar components for layout and notifications
import MuiAlert from "@mui/material/Alert"; // Import MUI Alert component for the styled alert box

// Custom Alert component using MuiAlert with a forwarded ref
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * CustomizedSnackbars
 * A reusable notification component that displays a Snackbar with a customizable alert message.
 * It uses MUI's Snackbar and Alert components to provide visual feedback to the user.
 *
 * Props:
 * - open: Boolean to control the visibility of the Snackbar.
 * - severity: String to define the severity of the alert (e.g., "error", "success").
 * - message: String to define the content of the alert.
 * - handleClose: Function to handle the closing of the Snackbar.
 */
export default function CustomizedSnackbars(props) {
  // Define the position for the Snackbar notification
  const vertical = "top"; // Vertical alignment for Snackbar (top/bottom)
  const horizontal = "right"; // Horizontal alignment for Snackbar (left/right)

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      {/* Snackbar component to show alerts */}
      <Snackbar
        open={props.open} // Controls if the Snackbar is open or not
        anchorOrigin={{ vertical, horizontal }} // Position of the Snackbar
        autoHideDuration={6000} // Duration to auto hide the Snackbar (in ms)
        key={vertical + horizontal} // Unique key for position
        onClose={props.handleClose} // Callback when the Snackbar is closed
      >
        {/* Alert component that shows the message with the specified severity */}
        <Alert
          onClose={props.handleClose} // Callback to handle closing the alert
          severity={props.severity} // Alert severity ("success", "error", etc.)
          sx={{ width: "100%" }} // Ensure the Alert takes up full width of the Snackbar
        >
          {props.message} {/* Display the message passed as a prop */}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
