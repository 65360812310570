// Import necessary libraries and components
import React from "react"; // React library
import ReactDOM from "react-dom/client"; // React DOM to render the app
import "./index.css"; // Global CSS file
import App from "./App"; // Main App component
import reportWebVitals from "./reportWebVitals"; // Web vitals reporting for performance measurement
import { registerLicense } from "@syncfusion/ej2-base"; // Syncfusion license registration
import { Provider } from "react-redux"; // Redux Provider to make store available throughout the app
import { HashRouter } from "react-router-dom"; // HashRouter for handling routing with hash-based URLs
import { store } from "./Services/Redux/store"; // Redux store

// Register Syncfusion license for components
registerLicense(
  "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5adkdjXnpZc3BdQmBf"
);

// Get the root element where the app will be rendered
const root = ReactDOM.createRoot(document.getElementById("root"));

// Rendering the App component into the root element with Redux store and routing setup
root.render(
  // React.StrictMode is commented out here to avoid double rendering in development
  // <React.StrictMode>
  <Provider store={store}>
    {" "}
    {/* Providing Redux store to the application */}
    <HashRouter>
      {" "}
      {/* Using HashRouter to manage the routing in the app */}
      <App /> {/* Main app component */}
    </HashRouter>
  </Provider>
  // </React.StrictMode>
);

// Measure performance in the app and log results or send to an analytics endpoint
reportWebVitals();
