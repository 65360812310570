import React from "react"; // Import React library for JSX and component creation
import { Box, Grid } from "@mui/material"; // Import Material-UI components for layout

// LanguagesTab component is responsible for displaying the language-related settings
const LanguagesTab = ({ languagesData, state, onChange }) => {
  // Helper function to return language name based on language code
  const language = (e) => {
    if (e === "en") {
      return "English"; // Return English if language code is 'en'
    } else {
      return "French"; // Return French if language code is 'fr'
    }
  };

  return (
    <Box>
      {" "}
      {/* Main container for the LanguagesTab */}
      {/* Table-like header for languages, title, and description columns */}
      <Grid
        container
        sx={{
          fontWeight: "bold", // Make the text bold
          borderBottom: "1px solid black", // Border at the bottom
          borderRight: "1px solid black", // Border at the right
          textAlign: "center", // Center align text
        }}
      >
        {/* Column for Language */}
        <Grid width={"20%"} sx={{ borderRight: "1px solid black" }}>
          Languages
        </Grid>
        {/* Column for Title */}
        <Grid width={"40%"} sx={{ borderRight: "1px solid black" }}>
          Title
        </Grid>
        {/* Column for Description */}
        <Grid width={"40%"}>Description</Grid>
      </Grid>
      {/* Conditionally rendering the language data if available */}
      {languagesData?.length > 0 ? (
        languagesData.map(
          (
            obj,
            i // Iterate through languagesData array
          ) => (
            <Grid
              container
              key={i} // Use index as key for each language entry
              sx={{
                textAlign: "center", // Center align text in the rows
                marginBottom: "4%", // Add margin at the bottom of each row
              }}
            >
              {/* Column for Language */}
              <Grid width={"20%"}>{language(obj.lang)}</Grid>
              {/* Column for Title */}
              <Grid width={"40%"}>
                <input
                  id="title"
                  name="title"
                  value={obj.title || ""} // Set value to title or empty string if undefined
                  onChange={(e) => onChange(e, obj.lang)} // Trigger onChange with the language code
                />
              </Grid>
              {/* Column for Description */}
              <Grid width={"40%"}>
                <input
                  id="desc"
                  name="desc"
                  value={obj.desc || ""} // Set value to desc or empty string if undefined
                  onChange={(e) => onChange(e, obj.lang)} // Trigger onChange with the language code
                />
              </Grid>
            </Grid>
          )
        )
      ) : (
        // Fallback content if no languagesData is available (default fields for English and French)
        <Grid>
          {/* Default row for English */}
          <Grid
            container
            sx={{
              textAlign: "center", // Center align text
              marginBottom: "4%", // Margin at the bottom
              marginTop: "4%", // Margin at the top
            }}
          >
            <Grid width={"20%"}>{language("en")}</Grid>{" "}
            {/* Language column for English */}
            <Grid width={"40%"}>
              <input
                id="pageTitle"
                name="pageTitle"
                value={state.pageTitle || ""} // Use value from state (or empty string if undefined)
                onChange={onChange} // Trigger onChange when input changes
              />
            </Grid>
            <Grid width={"40%"}>
              <input
                id="pageDesc"
                name="pageDesc"
                value={state.pageDesc || ""} // Use value from state (or empty string if undefined)
                onChange={onChange} // Trigger onChange when input changes
              />
            </Grid>
          </Grid>

          {/* Default row for French */}
          <Grid
            container
            sx={{
              textAlign: "center", // Center align text
              marginBottom: "4%", // Margin at the bottom
              marginTop: "4%", // Margin at the top
            }}
          >
            <Grid width={"20%"}>{language("fr")}</Grid>{" "}
            {/* Language column for French */}
            <Grid width={"40%"}>
              <input
                id="pageFrTitle"
                name="pageFrTitle"
                value={state.pageFrTitle || ""} // Use value from state for French title
                onChange={onChange} // Trigger onChange when input changes
              />
            </Grid>
            <Grid width={"40%"}>
              <input
                id="pageFrDesc"
                name="pageFrDesc"
                value={state.pageFrDesc || ""} // Use value from state for French description
                onChange={onChange} // Trigger onChange when input changes
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default LanguagesTab; // Export the component for use in other parts of the app
