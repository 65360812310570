import { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import Sidenav from "../Common/SideBar"; // Importing Sidebar component
import ResponsiveAppBar from "../Common/TopBar"; // Importing Top Bar component
import HomeComponent from "./Home"; // Importing Home page component
import MasterInfoComponent from "./AddressBook/MasterInfo"; // Importing Address Book page
import { useSelector } from "react-redux"; // To access redux store state
import ItemsMasterComponent from "./Inventory/ItemsMaster"; // Importing Inventory items page
import MenuPagesComponent from "./UserFeatures/MenuPages"; // Importing Menu Pages page
import UserMenuComponent from "./UserFeatures/UserMenu"; // Importing User Menu page
import UsersComponent from "./AddressBook/Users";
import FeaturesSetupComponent from "./Projects/FeaturesSetup";

const MainLayout = () => {
  const [open, setopen] = useState(false); // State for controlling sidebar open/close
  const selectedMenu = useSelector((state) => state.auth.selectedMenu); // Fetch selected menu from redux store

  const content = (
    <div className="mainDiv" style={{ width: "100%" }}>
      <Box height={"100%"}>
        {/* Display the sidebar */}
        <Grid container>
          <Grid item sx={{ width: !open ? "3%" : "14%" }}>
            <Box
              sx={{
                height: "auto",
                minHeight: "100vh", // Ensure it takes full height
              }}
            >
              <Sidenav open={open} setopen={setopen} />{" "}
              {/* Sidebar component */}
            </Box>
          </Grid>
          <Grid item sx={{ width: !open ? "97%" : "86%" }}>
            <Box
              sx={{
                height: "auto",
                minHeight: "100vh", // Ensure it takes full height
              }}
            >
              <Box width="5px" className="content" />
              <main className="content">
                {/* Display the top bar */}
                <Box className="topbar">
                  <ResponsiveAppBar /> {/* Top bar component */}
                </Box>
                {/* Display all the contents as routes with each link */}
                <Box sx={{ overflow: "hidden" }}>
                  <Routes>
                    {/* Default route for /main */}
                    <Route
                      path="/main"
                      element={<Navigate to={"/main/home"} />} // Redirect to /home
                    />
                    {/* Route for Home Component */}
                    <Route
                      exact
                      path="home"
                      element={<HomeComponent selectedMenu={selectedMenu} />} // Pass selectedMenu to HomeComponent
                    />
                    {/* Route for Address Book Component */}
                    <Route
                      exact
                      path="home/addressBook001a"
                      element={<MasterInfoComponent />} // Address Book component
                    />
                    {/* Route for Users Component */}
                    <Route
                      exact
                      path="home/addressBook001b"
                      element={<UsersComponent />} // Address Book component
                    />
                    {/* Route for Inventory Items Component */}
                    <Route
                      exact
                      path="home/invItems001a"
                      element={<ItemsMasterComponent />} // Inventory Items component
                    />
                    {/* Route for Menu Pages Component */}
                    <Route
                      exact
                      path="home/pages001"
                      element={<MenuPagesComponent />} // Menu Pages component
                    />
                    {/* Route for User Menu Component */}
                    <Route
                      exact
                      path="home/userMenu001"
                      element={<UserMenuComponent />} // User Menu component
                    />{" "}
                    {/* Route for User Menu Component */}
                    <Route
                      exact
                      path="home/adminFeatures001"
                      element={<FeaturesSetupComponent />} // Features Setup component
                    />
                  </Routes>
                </Box>
              </main>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );

  return content; // Render the content
};

export default MainLayout;
