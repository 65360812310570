import { Box, Grid } from "@mui/material";
import React from "react";
import BasicTextFields from "../../../../../../Common/TextField";

const DisableUserTemplate = ({ type }) => {
  return (
    <Box sx={{ fontWeight: "bold" }}>
      Are you sure you want to {type === "Active" ? "disable" : "enable"} this
      user?
    </Box>
  );
};

export default DisableUserTemplate;
