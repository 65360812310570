import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import SingleHierarchy from "../../../../Common/DataGrid/singleGrid"; // Import the custom data grid component
import CutomizedEditTemplate from "./CustomizedEditTemplate"; // Import the edit template component
import CutomizedAddTemplate from "./CustomizedAddTemplate"; // Import the add template component
import { setNotificationSnackBar } from "../../../../../Services/Redux/Reducers/authSliceReducer"; // Redux action for showing notifications

const UserMenuBody = ({ onSearch }) => {
  // Get socket and data from Redux store
  const socket = useSelector((state) => state.auth.socket);
  const userMenuData = useSelector(
    (state) => state?.userFeatures?.userMenuData
  )?.menus;
  const dispatch = useDispatch();

  // Local state to keep track of updates to changes
  const [updateChanges, setUpdateChanges] = useState([]);

  // Column configuration for the DataGrid
  const ColumnDirective = [
    {
      field: "menuId",
      headerText: "Menu ID",
      width: "100",
      isPrimaryKey: true,
      isIdentity: true,
      allowEditing: false,
    },
    { field: "menuDesc", headerText: "Description", width: "100" },
    { field: "userNbr", headerText: "Users Count", width: "100" },
  ];

  // Function to send data to the server via socket connection
  const sendData = (data, action) => {
    // Find the pages associated with the selected menuId
    const allPages = userMenuData?.filter(
      (item) => item?.menuId === data?.menuId
    )?.[0]?.pages;

    // Store changes made by the user
    const changes = updateChanges;
    let updatedPages = [];

    // Handle the update action
    if (action === "update") {
      updatedPages = [
        // Step 1: Process pages that already exist in `allPages`
        ...allPages.map((page) => {
          const existsInChanges = changes.some(
            (change) => change.pageId === page.pageId // Check if page exists in changes
          );
          return {
            ...page,
            action: existsInChanges ? "none" : "remove", // Mark unchanged pages as 'none' and removed ones as 'remove'
          };
        }),
        // Step 2: Add new changes that are not in `allPages`
        ...changes
          .filter(
            (change) => !allPages.some((page) => page.pageId === change.pageId) // Only add changes not present in allPages
          )
          .map((change) => ({ ...change, action: "add" })), // Mark new pages as 'add'
      ];
    }

    // Construct the data object to be sent
    const sendedData =
      (action === "add" && {
        action: "add",
        desc: data?.menuDesc ? data?.menuDesc : "",
      }) ||
      (action === "update" && {
        action: "edit",
        menuId: data?.menuId,
        desc: data?.menuDesc ? data?.menuDesc : "",
        menuPages: updatedPages?.map((obj) => {
          return {
            action: obj?.action,
            pageId: obj?.pageId,
          };
        }),
      }) ||
      (action === "delete" && {
        action: "delete",
        menuId: data?.menuId,
      });

    // Send the data using socket event
    socket.emit(
      "automation:admin:web:usr:v1", // Socket event name
      {
        request: "userMenu",
        userMenu: {
          type: "menuUpdate", // Specifying the type of action (menuUpdate)
          menuUpdate: sendedData,
        },
      },
      (response) => {
        // Dispatch notification after receiving the response from the server
        dispatch(
          setNotificationSnackBar({
            open: true, // Open the notification snackbar
            message: response?.message?.text, // Show the response message
            type: response?.message?.messageType, // Notification type (success, error, etc.)
          })
        );
        onSearch(); // Trigger the search callback
      }
    );
  };

  // Function to handle changes in the DataGrid (add, update, delete)
  const onChanges = (args) => {
    if (args.requestType === "delete") {
      sendData(args?.promise?.[0], "delete"); // Handle delete action
    } else if (args.action === "edit") {
      const updatedData = { ...args.data }; // Clone the data for editing
      delete updatedData.sections; // Exclude read-only field
      sendData(updatedData, "update"); // Handle update action
    } else if (args.action === "add") {
      sendData(args.data, "add"); // Handle add action
    }
  };

  // Conditional dialog template based on whether it's add or edit
  const dialogTemplate = (props, grid) => {
    const a = [props, grid];

    if (!props?.isAdd) {
      return (
        <CutomizedEditTemplate
          {...a}
          setUpdateChanges={setUpdateChanges}
          updateChanges={updateChanges}
        />
      );
    } else {
      return <CutomizedAddTemplate {...a} />; // Return add template for new entries
    }
  };

  return (
    <Grid>
      <SingleHierarchy
        columns={ColumnDirective}
        data={JSON.parse(JSON.stringify(userMenuData || []))} // Make sure data is properly parsed and passed
        primaryKey={"menuId"}
        selection={"Single"}
        onChanges={onChanges} // Attach onChanges handler
        readOnly={false} // Allow editing
        template={(props, grid) => dialogTemplate(props, grid)} // Custom template for the dialog
        style={"usermenu"} // Custom styling class
      />
    </Grid>
  );
};

export default UserMenuBody;
