import React from "react"; // Import React library for component creation
import JsonArrays from "../../../JSON"; // Import JSON data arrays (presumably predefined options like type, targets, platforms)
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns"; // Import Syncfusion DropDownListComponent

// CommonTab component renders various input fields and dropdowns for configuring page properties
const CommonTab = ({ state, onChange }) => {
  return (
    <div id="tab0" className="tab">
      {" "}
      {/* Main container for the "Common" tab */}
      {/* Row for displaying the Page ID */}
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "16px" }}
          >
            {/* Disabled input for Page ID, the value is populated from the state */}
            <input
              id="pageId"
              name="pageId"
              type="text"
              disabled={true} // Disabled input field
              value={state.pageId || ""} // Display the current pageId from state, or an empty string if undefined
              onChange={onChange} // onChange handler to update state
            />
            <span className="e-float-line" /> {/* Floating label effect */}
            <label className="e-float-text e-label-top">Page ID</label>{" "}
            {/* Label for Page ID */}
          </div>
        </div>
      </div>
      {/* Row for selecting Page Type using DropDownList */}
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "10px" }}
          >
            {/* DropDownList for selecting Page Type */}
            <DropDownListComponent
              id="pageType"
              name="pageType"
              value={state.pageType} // Current value of pageType from state
              dataSource={JsonArrays.type} // Dropdown options come from JsonArrays.type
              fields={{ text: "title", value: "title" }} // Display 'title' in the dropdown
              placeholder="Type" // Placeholder text when no value is selected
              popupHeight="300px" // Set the popup height for the dropdown
              floatLabelType="Always" // Ensure the label always floats
              onChange={onChange} // onChange handler to update state
            />
          </div>
        </div>
      </div>
      {/* Row for selecting Page Target using DropDownList */}
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "10px" }}
          >
            {/* DropDownList for selecting Page Target */}
            <DropDownListComponent
              id="pageTarget"
              name="pageTarget"
              value={state.pageTarget} // Current value of pageTarget from state
              dataSource={JsonArrays.targets} // Dropdown options come from JsonArrays.targets
              fields={{ text: "title", value: "title" }} // Display 'title' in the dropdown
              placeholder="Target" // Placeholder text when no value is selected
              popupHeight="300px" // Set the popup height for the dropdown
              floatLabelType="Always" // Ensure the label always floats
              onChange={onChange} // onChange handler to update state
            />
          </div>
        </div>
      </div>
      {/* Row for selecting Platform using DropDownList */}
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{ width: "100%", marginBottom: "0px" }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ marginTop: "10px" }}
          >
            {/* DropDownList for selecting Platform */}
            <DropDownListComponent
              id="platform"
              name="platform"
              value={state.platform} // Current value of platform from state
              dataSource={JsonArrays.platforms} // Dropdown options come from JsonArrays.platforms
              fields={{ text: "title", value: "title" }} // Display 'title' in the dropdown
              placeholder="Platform" // Placeholder text when no value is selected
              popupHeight="300px" // Set the popup height for the dropdown
              floatLabelType="Always" // Ensure the label always floats
              onChange={onChange} // onChange handler to update state
            />
          </div>
        </div>
      </div>
      {/* Row for entering Page Category */}
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            {/* Input field for Page Category */}
            <input
              id="pageCategory"
              name="pageCategory"
              type="text"
              value={state.pageCategory || ""} // Display the current category from state, or an empty string
              onChange={onChange} // onChange handler to update state
            />
            <span className="e-float-line" /> {/* Floating label effect */}
            <label className="e-float-text e-label-top">Category</label>{" "}
            {/* Label for Category */}
          </div>
        </div>
      </div>
      {/* Row for entering Page Icon */}
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            {/* Input field for Page Icon */}
            <input
              id="pageIcon"
              name="pageIcon"
              type="text"
              value={state.pageIcon || ""} // Display the current pageIcon from state, or an empty string
              onChange={onChange} // onChange handler to update state
            />
            <span className="e-float-line" /> {/* Floating label effect */}
            <label className="e-float-text e-label-top">Image</label>{" "}
            {/* Label for Image */}
          </div>
        </div>
      </div>
      {/* Row for entering Page Route */}
      <div className="form-row">
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            {/* Input field for Page Route */}
            <input
              id="pageRoute"
              name="pageRoute"
              type="text"
              value={state.pageRoute || ""} // Display the current pageRoute from state, or an empty string
              onChange={onChange} // onChange handler to update state
            />
            <span className="e-float-line" /> {/* Floating label effect */}
            <label className="e-float-text e-label-top">Route</label>{" "}
            {/* Label for Route */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonTab; // Export the CommonTab component
