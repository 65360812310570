// Importing required libraries and components
import { Routes, Route, Navigate, useLocation } from "react-router-dom"; // For routing
import Login from "./components/Pages/Login/Login"; // Login page component
import RequireAuth from "./Services/RTKQuery/auth/RequireAuth"; // HOC to check for authentication
import { MissingRoute } from "./Services/MissingRoutes"; // Custom 404 route
import { useDispatch, useSelector } from "react-redux"; // Redux hooks for state management
import ForgetPasswordComponent from "./components/Pages/ForgetPassword"; // Forgot password page
import SocketIO from "./Services/SocketIO"; // Socket IO service
import { useEffect } from "react"; // For side-effects
import {
  setNotificationSnackBar,
  setSocket, // Redux action to set notification snackbar
} from "./Services/Redux/Reducers/authSliceReducer"; // Redux slice actions
import MasterInfoComponent from "./components/Pages/AddressBook/MasterInfo"; // Master info page
import HomeComponent from "./components/Pages/Home"; // Home page component
import Cookies from "universal-cookie"; // Cookie management library
import { Box } from "@mui/material"; // Material UI Box component for layout
import CustomizedSnackbars from "./components/Common/NotificationAlert"; // Custom notification alert
import ItemsMasterComponent from "./components/Pages/Inventory/ItemsMaster"; // Inventory items page
import MenuPagesComponent from "./components/Pages/UserFeatures/MenuPages"; // Menu pages component
import UserMenuComponent from "./components/Pages/UserFeatures/UserMenu"; // User menu component
import UsersComponent from "./components/Pages/AddressBook/Users";
import FeaturesSetupComponent from "./components/Pages/Projects/FeaturesSetup";

function App() {
  // Accessing state from Redux store
  const user = useSelector((state) => state.auth.user); // Current authenticated user
  const userAll = useSelector((state) => state.auth.userAll); // Complete user info
  const dispatch = useDispatch(); // Dispatch hook to trigger Redux actions
  const socket = useSelector((state) => state.auth.socket); // Socket connection for admin
  const cookies = new Cookies(); // Cookies instance for managing cookies
  const location = useLocation(); // Location hook for getting current path
  const notificationSnackBar = useSelector(
    (state) => state.auth.notificationSnackBar // Notification snack bar state
  );

  // Effect to store the last visited route in a cookie
  useEffect(() => {
    if (location?.pathname !== "/" && location?.pathname !== undefined) {
      cookies.set("_location", location?.pathname, {
        path: "/",
        expires: new Date(Date.now() + 2592000), // Set cookie expiration (30 days)
      });
    } else {
      cookies.set("_location", "/main", {
        path: "/",
        expires: new Date(Date.now() + 2592000), // Set default route for root
      });
    }
  }, [location?.pathname]); // Run this effect when location changes

  // Effect to manage the admin socket connection
  useEffect(() => {
    if (!socket && user) {
      const socket2 = SocketIO(
        userAll?.token,
        process.env.REACT_APP_API_SOCKETIOMAIN_PATH,
        false
      ).connect(); // Create a socket connection

      if (socket2 !== socket) {
        dispatch(setSocket(socket2)); // Update socket state in Redux

        socket2.on("connect", () => {
          console.log(`Socket connected`); // Log on successful connection
          console.log(socket2); // Log the socket object
        });

        socket2.on("disconnect", () => {
          console.log(`Socket disconnected`); // Log on disconnection
        });
      }
    }
  }, [socket]); // Runs when socket changes

  // Function to handle closing of the snackbar
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      return; // Ignore clickaway to close
    }
    dispatch(
      setNotificationSnackBar({
        open: false,
        message: "",
        type: "",
      })
    ); // Close the snackbar by resetting state
  };

  // Route configuration (pages with child routes)
  const pages = [
    {
      path: "/",
      element: !user ? <Login /> : <Navigate to={"main/home"} />, // Show login or redirect
      childs: [],
    },
    {
      path: "/forgetPassword",
      element: <ForgetPasswordComponent />, // Forgot password route
      childs: [],
    },
    {
      path: "*",
      element: <MissingRoute />, // Handle undefined routes (404 page)
      childs: [],
    },
    {
      path: "/main/*",
      element: user ? <RequireAuth /> : <Navigate to={"/"} />, // Protected route for authenticated users
      childs: [
        {
          path: "home/*",
          element: user ? <HomeComponent /> : <Navigate to={"/"} />, // Home route
          childs: [
            {
              path: "addressBook001a",
              element: user ? <MasterInfoComponent /> : <Navigate to={"/"} />, // Address book route
              childs: [],
            },
            {
              path: "addressBook001b",
              element: user ? <UsersComponent /> : <Navigate to={"/"} />, // Address book route
              childs: [],
            },
            {
              path: "invItems001a",
              element: user ? <ItemsMasterComponent /> : <Navigate to={"/"} />, // Inventory route
              childs: [],
            },
            {
              path: "pages001",
              element: user ? <MenuPagesComponent /> : <Navigate to={"/"} />, // Menu pages route
              childs: [],
            },
            {
              path: "userMenu001",
              element: user ? <UserMenuComponent /> : <Navigate to={"/"} />, // User menu route
              childs: [],
            },
            {
              path: "adminFeatures001",
              element: user ? (
                <FeaturesSetupComponent />
              ) : (
                <Navigate to={"/"} />
              ), // Features setup route
              childs: [],
            },
          ],
        },
      ],
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      {/* Define Routes */}
      <Routes>
        {pages?.map((page, i) => {
          return (
            <Route key={i} path={page?.path} element={page?.element}>
              {page?.childs?.map((child1, i) => {
                return (
                  <Route key={i} path={child1?.path} element={child1?.element}>
                    {child1?.childs?.map((child2, i) => {
                      return (
                        <Route
                          key={i}
                          path={child2?.path}
                          element={child2?.element}
                          action={child2?.path === cookies.get("_location")}
                        />
                      );
                    })}
                  </Route>
                );
              })}
            </Route>
          );
        })}
      </Routes>

      {/* Show notification snackbar if necessary */}
      {notificationSnackBar?.open && (
        <CustomizedSnackbars
          open={notificationSnackBar?.open}
          handleClose={handleCloseSnackBar}
          severity={notificationSnackBar?.type}
          message={notificationSnackBar?.message}
        />
      )}
    </Box>
  );
}

export default App;
