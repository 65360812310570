import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAddressNbUserSelected } from "../../../../../../Services/Redux/Reducers/addressBookReducer";

const CutomizedTemplate = (props) => {
  // Initialize the state with the passed data from props
  const [state, setState] = useState({ ...props[0] });
  const dispatch = useDispatch();
  const openAddressNbr = props?.openAddressNbr;
  const isAdd = props?.isAdd;

  const [addressNb, setAddressNb] = useState(
    props?.addressNbRowSelected.addressNb || ""
  );

  useEffect(() => {
    if (state?.addressNb) {
      dispatch(setAddressNbUserSelected(state?.addressNb));
    }
  }, [state?.addressNb]);

  const addressNbUserSelected = useSelector(
    (state) => state?.addressBook?.addressNbUserSelected // Accessing masterInfoData from the Redux store
  );

  // Handle change in the input fields and update the state
  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value, // Update the respective field in state
    }));
  };

  const languages = [
    {
      title: "English", // Display label
      value: "EN", // Internal value for English language
    },
    {
      title: "French", // Display label
      value: "FR", // Internal value for French language
    },
  ];

  const types = [
    {
      title: "Customer", // Display label
      value: "C", // Internal value for English language
    },
    {
      title: "Employee", // Display label
      value: "E", // Internal value for French language
    },
  ];

  return (
    <div id="tab0" className="tab">
      {!isAdd && (
        <div className="form-row" style={{ width: "100%" }}>
          {/* Menu ID input field (disabled) */}
          <div
            className="form-group col-md-6"
            style={{ width: "100%", marginBottom: "0px" }}
          >
            <div
              className="e-float-input e-control-wrapper"
              style={{ marginTop: "16px" }}
            >
              <input
                id="userId"
                name="userId"
                type="text"
                disabled={true} // Disable editing for Menu ID
                value={state.userId || ""} // Set the value from state
                onChange={onChange} // Handle input change
              />
              <span className="e-float-line" />
              <label className="e-float-text e-label-top">User ID</label>
            </div>
          </div>
        </div>
      )}
      <div className="form-row" style={{ width: "100%" }}>
        {/* Menu Description input field */}
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
            position: "relative",
          }}
        >
          <div
            className="e-float-input e-control-wrapper"
            style={{ position: "relative" }}
          >
            <input
              id="addressNb"
              name="addressNb"
              type="text"
              value={
                (addressNbUserSelected ? addressNbUserSelected : addressNb) ||
                ""
              } // Set the value from state
              onChange={onChange} // Handle input change
              style={{ paddingRight: "30px" }} // Add padding to avoid overlap
              disabled
            />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Address Nb.</label>

            {/* Search Icon */}
            <i
              className="fa fa-search"
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
                cursor: "pointer",
                color: "#666",
              }}
              onClick={openAddressNbr}
            ></i>
          </div>
        </div>
      </div>
      <div className="form-row" style={{ width: "100%" }}>
        {/* Menu Description input field */}
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            <input
              id="userName"
              name="userName"
              type="text"
              value={state.userName || ""} // Set the value from state
              onChange={onChange} // Handle input change
            />
            <span className="e-float-line" />
            <label className="e-float-text e-label-top">Username</label>
          </div>
        </div>
      </div>
      <div className="form-row" style={{ width: "100%" }}>
        {/* Menu Description input field */}
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "-15px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            <DropDownListComponent
              id="userDefaultLanguage"
              name="userDefaultLanguage"
              value={state.userDefaultLanguage}
              dataSource={languages} // Dropdown data source
              fields={{ text: "title", value: "title" }} // Fields for dropdown options
              placeholder="Default Language"
              popupHeight="300px"
              floatLabelType="Always"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="form-row" style={{ width: "100%" }}>
        {/* Menu Description input field */}
        <div
          className="form-group col-md-6"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "-15px",
          }}
        >
          <div className="e-float-input e-control-wrapper">
            <DropDownListComponent
              id="userType"
              name="userType"
              value={state.userType}
              dataSource={types} // Dropdown data source
              fields={{ text: "title", value: "title" }} // Fields for dropdown options
              placeholder="Type"
              popupHeight="300px"
              floatLabelType="Always"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CutomizedTemplate;
