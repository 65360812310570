import { Box, Grid } from "@mui/material"; // Importing Box and Grid components from MUI for layout
import React from "react"; // Importing React
import { useDispatch, useSelector } from "react-redux"; // Importing Redux hooks for state management
import BasicButtons from "../../../../Common/Button";
import BasicTextFields from "../../../../Common/TextField";
import { setFeaturesSetupFilter } from "../../../../../Services/Redux/Reducers/projectsSetupReducer";

const FeaturesSetupFilterReturn = ({ onSearch }) => {
  // Selecting the master info filter state from the Redux store
  const featuresSetupFilter = useSelector(
    (state) => state?.projectsSetup?.featuresSetupFilter
  );
  const dispatch = useDispatch(); // Initializing Redux dispatch

  // // Function to handle input changes and update the filter in the Redux store
  const handleChange = (e, item) => {
    dispatch(
      setFeaturesSetupFilter({
        ...featuresSetupFilter, // Retain previous filter values
        [item]: e?.target?.value, // Update the specific filter item with the new value
      })
    );
  };

  return (
    <Box>
      <Grid container>
        {/* Search Text input field */}
        <Grid sx={{ width: "10%" }}>
          <BasicTextFields
            value={featuresSetupFilter?.searchTxt} // Setting the value from the filter state
            id="searchTxt" // Assigning a unique ID
            onChange={(e) => handleChange(e, "searchTxt")} // Handling change for address number
            label="Search Text" // Label for the input field
          />
        </Grid>
        <Grid sx={{ width: "3%" }}></Grid> {/* Spacer Grid */}
        {/* Search button */}
        <Grid sx={{ width: "8%", display: "flex" }}>
          <BasicButtons text={"Search"} onClick={onSearch} />{" "}
          {/* Button to trigger the search */}
        </Grid>
      </Grid>
    </Box>
  );
};

export default FeaturesSetupFilterReturn; // Exporting the component as default
