// Import necessary components from MUI and other libraries
import { Box, CircularProgress, Grid } from "@mui/material"; // Material-UI components
import React from "react"; // React library
import { useSelector } from "react-redux"; // Redux hook for accessing state
import SingleHierarchy from "../../../../../Common/DataGrid/singleGrid"; // Custom grid component

// CompanyIDPopUp component for displaying a list of companies in a grid format
const AddressNbPopUp = ({ setAddressNbRowSelected, addressNbLoading }) => {
  // Access company list from the Redux store
  const usersAddressNbData = useSelector(
    (state) => state?.addressBook?.usersAddressNbData
  );

  // Column configuration for displaying company data
  const ColumnDirective = [
    {
      field: "addressNb",
      headerText: "Number",
      width: "100",
      isPrimaryKey: true,
    },
    { field: "title", headerText: "Title", width: "100" },
    { field: "fullName", headerText: "Full Name", width: "100" },
    { field: "address1", headerText: "Address 1", width: "100" },
    { field: "address2", headerText: "Address 2", width: "100" },
    { field: "addressType", headerText: "Type", width: "100" },
    { field: "companyId", headerText: "Company ID", width: "100" },
  ];

  return (
    <Box sx={{ marginTop: "3%" }}>
      {/* Render the grid only if companyList data exists */}
      {!addressNbLoading && (
        <Grid container>
          {/* SingleHierarchy component is used to render the company data */}
          <SingleHierarchy
            columns={ColumnDirective} // Pass column configuration
            data={JSON.parse(JSON.stringify(usersAddressNbData))} // Clone the data to avoid mutations
            primaryKey={"addressNb"} // Set 'UVVALUE' as the primary key for the grid
            selection={"Single"} // Allow single selection in the grid
            contextMenu={false} // Disable the built-in context menu of the grid
            readOnly={true} // Make the grid read-only
            setRowSelected={setAddressNbRowSelected} // Callback to handle row selection
          />
        </Grid>
      )}
      {addressNbLoading && (
        <div
          style={{
            width: "100%", // Full width
            display: "flex", // Flexbox for centering
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          <CircularProgress
            sx={{
              color: "#1976d2", // Spinner color
              width: "100px !important", // Setting width
              height: "100px !important", // Setting height
            }}
          />
        </div>
      )}
    </Box>
  );
};

export default AddressNbPopUp;
